@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

$border: 1px solid #dcd9e5;

.values {
  font-size: $small;
  padding: $baseline_half;
  min-width: 400px;

  p {
    font-size: $small;
  }

  > div {
    margin-bottom: $baseline_half;
  }
}

.subheader {
  color: $gray500;
  margin-bottom: 0;
}

.issues {
  display: flex;
  align-items: flex-start;
  padding-bottom: $baseline_sm;
  border-bottom: $border;

  p {
    margin: 0;
    padding-right: $baseline_half;
    min-width: 120px;
  }

  &:last-of-type {
    border-bottom: none;
  }
}

.emails {
  display: flex;
  flex-wrap: wrap;

  p {
    padding-right: $baseline_sm;
    margin: 0;
  }
}

.asidePanel {
  header {
    min-width: 400px;
  }

  transition: all 666ms 100ms ease-in-out;
  width: 0 !important;
  flex-basis: 0 !important;

  &.withAside {
    width: 35% !important;
    flex-basis: 35% !important;
  }
}
