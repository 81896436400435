@import '../../../../../scss/base/colors';
@import '../../../../../scss/base/variables';

.group {
  cursor: pointer;
}

.periodRect {
  stroke: $gray250;
  fill: transparent;

  &:hover,
  &.selected {
    fill: $primary600;

    &.incidentSelected {
      fill: $light500;
    }
  }

  &.flagged,
  &.flagged.drawer,
  &.flagged:hover,
  &.flagged.drawer:hover {
    fill: $warning;
    stroke: $warning;
  }
}

.expander {
  fill: transparent;
}
