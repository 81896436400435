@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';
@import '../../../../scss/base/mixins';

.form {
  padding: $baseline;
  width: $adv-filter-width;

  i:global(.icon-check) {
    color: $success;
    text-transform: none !important;
  }

  i:global(.icon-close) {
    color: $danger;
    text-transform: none !important;
  }

  :global(.rw-multiselect) {
    margin-bottom: $baseline_half;
  }

  :global(.rw-widget-input) {
    padding: $baseline_sm $baseline_half $baseline_sm 0;

    :global(.rw-multiselect-taglist) {
      width: auto;
    }

    @include placeholder {
      color: $gray300 !important;
    }

    input {
      font-size: $base-font-size !important;
    }
  }

  .triageWrapper {
    margin-bottom: $baseline_half;
    font-size: $base-font-size * 0.75;

    i {
      margin: -2px 0 0 0;
      font-size: $h2-font-size;
    }

    span {
      padding-left: 0;
    }
  }

  .multiselectLabel {
    font-size: $base-font-size * 0.75;
  }

  .header {
    margin-bottom: $baseline_half;

    &.topMargin {
      margin-top: $baseline;
    }
  }

  .actionLinkContainer {
    text-align: right;
    font-size: $base-font-size * 0.75;

    .actionLinkIcon {
      margin: -2px $baseline_sm 0 0;
      font-size: $h2-font-size;
    }
  }

  .accountStatusContainer {
    margin-bottom: $baseline_half;

    .lockContainer {
      display: flex;
      justify-content: space-between;

      .lockText {
        color: $danger;
        font-size: $base-font-size * 0.75;
      }
    }
  }

  .accountStatusDropdownItemLabel {
    margin-right: $baseline;
  }

  .accountStatusDropdownItemIcon {
    margin: -2px $baseline_sm 0 0;
    font-size: $h2-font-size;
  }

  .saveButton {
    margin-top: $baseline;
  }
}

.licenseLabel {
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: $baseline $baseline 0 $baseline;
  width: $adv-filter-width;
  color: $black400;
  font-size: $small;
  white-space: pre-line;
  text-align: left;
  &.licenseLimitReached {
    color: $danger;
  }
}

.failure {
  color: $danger;
}
