@import '../../../scss/base/variables';
@import '../../../scss/base/colors';
@import '../../../scss/base/mixins';

.dropdownWrapper {
  padding: $baseline_half;
  p {
    font-size: 16px;
    font-weight: 400;
  }
  :global(.rw-dropdown-list) {
    border-radius: 5px;
    border: none;
    background: $white300;
  }

  :global(.rw-popup) {
    border-radius: 5px;
  }

  :global(.rw-list) {
    border-radius: 5px;
  }

  :global(.rw-list-option) {
    max-height: 40px;
    margin: 10px !important;
    &:not(:global(.rw-state-disabled)) {
      &:hover {
        border-radius: 5px !important;
        i {
          color: white !important;
        }
      }
    }
  }

  :global(.rw-state-selected) {
    border-radius: 5px !important;
    i {
      color: white !important;
    }
  }

  :global(.rw-state-disabled) {
    background: none !important;
    opacity: 0.5 !important;
  }
}

.valueInput {
}

.item {
  &:hover {
    background-color: transparent;
  }
}

.icon {
  padding-right: 10px;

  i {
    text-transform: none !important;
    &:hover {
      color: white;
    }
  }
}
