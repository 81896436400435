.rw-popup {
  @include box-shadow(0 1px 3px rgba(0, 0, 0, 0.2));
  @include border-top-radius(0);
  @include border-bottom-radius($border-radius-sm);
  border: $popup-border 1px solid;
  background: $popup-bg;
  padding: 0px;
  overflow: auto;

  .rw-dropup > & {
    margin-bottom: 0;
    margin-top: 10px;
    @include border-top-radius($border-radius-sm);
    @include border-bottom-radius(0);
    @include box-shadow(0 0 6px rgba(0, 0, 0, 0.2));
  }
}

.rw-popup-container {
  position: absolute;
  top: 100%;
  margin-top: 0;
  z-index: 4;

  &.rw-dropup {
    top: auto;
    bottom: 100%;
  }

  &.rw-calendar-popup {
    right: auto;
    width: 18em; //15em; ?
  }
}
