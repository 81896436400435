@import '../../../scss/base/colors';

.container {
  position: relative;

  input {
    border: 1px solid $navy100;
    border-radius: 4px;
  }
}
