@import '../../../../../scss/base/variables';
@import '../../../../../scss/base/colors';

.container {
  width: 45px;
  height: 30px;
  font-size: $xSmall;
}

.empty {
  > :first-child {
    opacity: 0.5;
    transition: opacity 0.25s;
  }

  a {
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}

.icon {
  .person {
    position: absolute;
    font-size: 24px;
    padding-left: 7px;
    top: 28%;
    opacity: 0.5;
    z-index: 0;
  }

  .patient {
    padding-left: 9px;
    color: $teal500;
  }

  .user {
    color: $secondary1000;
  }

  .swap {
    position: absolute;
    bottom: 28%;
    font-size: $base-font-size * 0.9;
    z-index: 1;
    border: 1px solid white;
    border-radius: 50%;
    background: white;
  }
}
