table {
  width: 100%;
}

table thead {
  border-bottom: 1px solid $white300;
  background: $white200;
}

table thead tr th,
table tbody tr td,
table tr td {
  font-size: 90%;
  line-height: $baseline * 0.75;
  text-align: left;
}

table thead tr th {
  padding: 8px 10px 9px;
  font-weight: bold;
  color: #222;
}

table thead tr th:first-child {
  border-left: none;
}

table thead tr th:last-child {
  border-right: none;
}

table thead tr th,
table tbody tr td {
  color: #333;
  padding: 9px 10px;
  vertical-align: middle;
  border: none;
}

table th {
  font-size: 70%;
  font-weight: bold;
  text-transform: uppercase;
}

table td,
table th {
  padding: 9px 10px;
  text-align: left;
}

// -------------------------------------
//   Table Styles
// -------------------------------------

table {
  tbody {
    tr {
      &.selected,
      &.flagged {
        border-left-width: 6px;
        border-left-style: solid;
      }

      &.selected {
        background-color: $white200;
        border-left-color: $warning;

        &:hover {
          background-color: scale-color($white200, $lightness: 50%);
        }
      }

      &.flagged {
        background-color: $white;
        border-left-color: $primary;
        font-style: italic;
        i {
          color: $primary !important;
        }
      }

      &.hidden-row {
        display: none;
      }

      td {
      }
    }
  }

  &.rows-flagged {
    margin-bottom: $baseline * 2 !important;
  }
}
