@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.informationSection {
  height: 100vh;
}

@media print {
  // makes sure content is not cut off vertically
  html {
    overflow-y: visible !important;
  }
}
