.mainPanel {
  flex: 1 1 65%;
  display: flex;
  position: relative;
  flex-direction: column;
  height: calc(100vh - 3.90625rem);
  clear: both;
  overflow: auto;
}

.withAside {
  flex: 1 1 65%;
  animation: flexMainRight 0.3s 0s both;
}
