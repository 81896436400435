@import '../../../scss/base/colors';
@import '../../../scss/base/variables';

$gray-border: 1px solid $white500;
$primary-border: 1px solid $primary;

.section {
  display: flex;
  padding: 18px;
  transition: background-color 250ms linear;
  background: white;
  border-top: $gray-border;
  border-right: $gray-border;
  border-left: $gray-border;

  &.selected {
    border-right: $primary-border;
    border-left: $primary-border;
  }

  > * {
    flex: 1;
    max-width: 100%;
  }

  > .expandCollapseButton {
    flex: 0 1 auto;
  }

  .staticSections & {
    &:first-of-type {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
  }

  .staticSections.collapsed & {
    &:last-of-type {
      border-bottom: $gray-border;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    &.selected {
      border-bottom: 1px solid $primary;
    }
  }

  .animatedSections & {
    &:last-of-type {
      border-bottom: $gray-border;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    &.selected {
      &:last-of-type {
        border-bottom: $primary-border;
      }
    }
  }
}

.warning {
  background-color: $danger750;
}

.staticSections.selected {
  .headerSection {
    background: $primary;
    border-color: $primary;

    span {
      color: white;
      font-weight: 500;
    }

    p {
      color: white;
    }
  }
}

@media print {
  .expandCollapseButton {
    display: none;
  }
}
