@import '../../../scss/base/variables';

.printableFilters {
  width: $adv-filter-width;

  .header {
    font-size: 24px;
    margin-bottom: $baseline;
  }
}
