@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.form {
  padding: $baseline;

  h4 {
    margin: 0 0 $baseline_half;
  }

  :global(.expando) {
    display: flex;
    margin-top: $baseline_half;
    
    > div:first-child {
      margin-right: $baseline_sm;
    }
  }

  :global(.input-container) {
    background: white;
  }

  :global(.expando.date-range) {
    > div {
      &:last-child {
        :global(.rw-popup.rw-calendar.rw-widget-container.rw-widget) {
          left: -5.6rem;
        }
      }
    }
  }

  > div {
    margin-bottom: $baseline_half;
  }
}