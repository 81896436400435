@import '../../../../scss/base/colors';
@import '../../../../scss/base/variables';

.layout {
  height: 64px;
  position: relative;
}

.container {
  display: flex;
  height: 100%;
  align-items: center;
  background: $white;
  box-shadow: 0 3px 3px $white400;

  .personName {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }
}

.iconWrapper {
  position: relative;
  padding: $baseline_half;

  &.patient {
    color: $secondary1000;
  }

  &.user {
    color: $teal500;
  }
}

.spacer {
  flex: 1;
}
