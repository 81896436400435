@import '../../../../scss/base/variables';

.main {
  display: flex;
  flex-direction: column;
  padding: $baseline;
  height: 100%;

  :global(ul.rw-list) {
    overflow-y: scroll;
  }
}

.label {
  font-size: $h3-font-size;
}

.spacer {
  flex: 1;
}

.button {
  align-self: flex-end;
  min-width: 135px;
}
