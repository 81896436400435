$title-height: $baseline * 1.75;

.action-group {
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 300px;
  padding: 0;
  list-style: none;
  border-left: 1px solid $navy100;
  overflow: auto;

  > li {
    max-height: $title-height;
    margin-bottom: 0;
    transition: max-height 250ms;
    border: 0;
    background: $white;
    overflow: hidden;

    &.expando-active {
      position: relative;
      flex: 1;
      max-height: 100vh;

      > div {
        display: flex;
        position: absolute;
        top: $title-height;
        bottom: 0;
        width: 100%;
      }
    }

    &:last-child {
      border-bottom: 1px solid $white400;
    }

    &:first-child > .action-group__action {
      border-top: none;
    }

    > .action-group__action {
      flex-basis: content;
      flex-shrink: 0;
      min-height: $title-height;
      border-top: 1px solid $white400;
      background: $white200;
      color: $secondary;

      .text-subtle {
        margin-left: $baseline_half;
      }

      &:hover,
      &:active,
      &:focus {
        background: $white150;
      }
    }

    .expando {
      flex: 1 1 auto;
      max-height: calc(100% - #{$title-height} + 5px);
      transition: none;
      border-top: 1px solid $white400;
      overflow: auto;

      > div {
        width: 100%;
      }
    }

    .expando-hidden {
      flex: 1;
      max-height: 0;
      transition: max-height 250ms;
    }
  }

  &.action-group--dark-bg {
    > li section {
      border-top: 1px solid $white450;
    }

    .expando {
      border-top: 1px solid $white450;
    }
  }
}
