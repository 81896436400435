@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.container {
  position: relative;
  display: flex;
  width: 100%;
  height: calc(100vh - #{$subnav_height * 2});

  aside {
    width: $adv-filter-width;
  }

  .body {
    width: calc(100% - #{$adv-filter-width});
    overflow: hidden;
    padding-top: 50px;
    position: relative;

    header {
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}
