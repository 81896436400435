.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.pandyWrapper {
  position: relative;
  height: 40vh;
}

.errorWrapper {
  white-space: pre-wrap;
  width: 50%;
  margin: 0 auto;
  align-items: center;
  text-align: center;
}

.errorDetails {
  overflow: scroll;
  height: 40vh;
  margin: 0 auto;
}
