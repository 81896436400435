@import '../../../scss/base/variables';
@import '../../../scss/base/zindex';
@import '../../../scss/base/colors';
@import '../../../scss/react-widgets/mixins';
@import '../../../scss/base/mixins';

.dropdown {
  z-index: index($z_layout, widget-dropdown);
  max-height: 145px;
  overflow: auto;
  overflow-x: hidden;
  @include box-shadow(0 0 12px 0 $gray900);
}

.item {
  margin-bottom: 0;
  font-size: $small;
}

.textareaAutosize {
  textarea {
    flex: 1;
    padding: $input-default-padding;
    border: none;
    border-radius: $border-radius * 2;
    background: $white300;
    font-size: $base-font-size;
    transition: none;
  }

  @include placeholder {
    @include placeholder-visible;
    color: $gray500;
    opacity: 1;
  }
}
