// *************************************
//
//   Variables
//   -> Fonts, Variables, Etc
//
// *************************************

// -------------------------------------
//   @Colors can be found in _colors.scss
// -------------------------------------

// -------------------------------------
//   Widths
// -------------------------------------

$min-width: 83rem;
$dropdown-min-width: 9rem;

// -------------------------------------
//   Typography
//   Uses a 4:5 Major Third scale with an 18 to 16px shift from desktop to mobile
// -------------------------------------

$primaryFont: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans,
  Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;

$base-font-size: 1rem;
$baseline: 1.5625rem;
$baseline_half: $baseline * 0.5;
$baseline_sm: $baseline * 0.25;
$baseline_lg: $baseline * 1.25;
$small: 0.9rem;
$xSmall: 0.8rem;
$xxSmall: 0.2rem;

$h1-xl-font-size: 4rem;
$h1-xl-line: $baseline;
$h1-xl-lines: 3;
$h1-xl-line-height: $h1-xl-line * $h1-xl-lines; // baseline * # of lines
$h1-xl-margin-top: 1 * $h1-xl-line;
$h1-xl-margin-bottom: 1 * $h1-xl-line;

$h1-font-size: 1.4375rem; //2.1875rem;
$h1-line: $baseline;
$h1-lines: 2;
$h1-line-height: $h1-line * $h1-lines;
$h1-margin-top: 0 * $h1-line;
$h1-margin-bottom: 1 * $h1-line;

$h2-font-size: 1.125rem; // 1.4375rem;
$h2-line: $baseline;
$h2-lines: 1;
$h2-line-height: $h2-line * $h2-lines;
$h2-margin-top: 0 * $h2-line;
$h2-margin-bottom: 1 * $h2-line;

$h3-font-size: 1rem; //1.125rem;
$h3-line: $baseline;
$h3-lines: 1;
$h3-line-height: $h3-line * $h3-lines;
$h3-margin-top: 0 * $h3-line;
$h3-margin-bottom: 1 * $h3-line;

$h4-font-size: 0.85rem;
$h4-line: $baseline;
$h4-lines: 1;
$h4-line-height: $h4-line * $h4-lines;
$h4-margin-top: 0 * $h4-line;
$h4-margin-bottom: 0.5 * $h4-line;

$h5-font-size: 0.7rem;
$h5-line: calc($baseline / 2);
$h5-lines: 1;
$h5-line-height: $h5-line * $h5-lines;
$h5-margin-top: calc($h5-line / 3);
$h5-margin-bottom: calc($h5-line / 3);
$h5-text-transform: capitalize;

$border-radius: 3px;
$border-radius-md: 6px;

// -------------------------------------
//   Forms
// -------------------------------------

// Default
$input-default-padding-v: $baseline * 0.775; // ~57px tall
$input-default-padding-h: $baseline_half;
$input-default-padding: $input-default-padding-v $input-default-padding-h;

// small forms
$input-small-form-padding: $baseline * 0.3;
$input-small-padding: $input-default-padding-h;

// inline labels
$input-inline-label-padding: $baseline * 1.1 $input-default-padding-h $baseline *
  0.45;

// form addons
$input-group-addon-padding: $baseline * 0.6 $baseline_half;
$input-group-addon-small-padding: $baseline * 0.4;

// in multiselects
$input-multiselect-padding: 0 0 0 $input-default-padding-h;

// -------------------------------------
//   Buttons
// -------------------------------------

$btn_trans_timing: ease;
$btn_bg_default: white;
$btn_bg_outline: transparent;
$btn_border_style: 1px solid white;

// -------------------------------------
//   Navs
// -------------------------------------

$nav-height: $baseline * 3;
$subnav-height: $baseline * 2.5;
$actionnav-height: $baseline * 2;
$adv-filter-width: $baseline * 15;
$aside-panel-width: $baseline * 18;

// -------------------------------------
//   Lists
// -------------------------------------

$contained-list-height: $baseline * 16;

// -------------------------------------
//   Data Tables
// -------------------------------------

$data-table-cell-padding: 12px 14px;
$data-table-cell-highlight-width: 6px;

// -------------------------------------
//   Charts
// -------------------------------------

$activity-chart-row-height: 50px;

// -------------------------------------
//   etc
// -------------------------------------

$entity-icon-wrapper: 3rem;
