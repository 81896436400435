.events_chart_wrapper {
  height: 100%;
  overflow: auto;

  &.user-only {
    max-height: $activity-chart-row-height;
    overflow: hidden;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
  }
}

.selectedLogs {
  display: flex;
  position: absolute;
  bottom: 40px;
  align-items: center;
  justify-content: flex-end; //space-between;
  width: 100%;
  padding: $baseline * 0.25 $baseline_half;
  transition: bottom 200ms ease-in-out;
  background-color: $white200;
  box-shadow: 0 -4px 4px $white400;

  p {
    margin-right: $baseline;
    margin-bottom: 0;
  }

  &.is--hidden {
    bottom: -20px;
  }
}

.log_controls {
  position: absolute;
  top: $baseline_half;
  right: $baseline_half;
  z-index: index($z_createCase, button);

  span {
    margin-left: $baseline_sm;

    i {
      color: $secondary;
    }
  }

  .icon-call_merge {
    transform: rotate(90deg);
  }

  .icon-call_split {
    transform: rotate(-90deg);
  }
}

.create_case {
  display: inline-block;
  transition: all 100ms ease-in;
  z-index: index($z_createCase, button);
}

.eventLogs {
  .case {
    position: absolute;
    top: 18px;
    width: 14px;
    height: 14px;
    left: 3px;
  }

  .encounter {
    display: block;
    position: absolute;
    top: 20px;
    left: 6px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $secondary;
  }

  .event-icon .material-icons {
    color: $secondary;
    font-size: $base-font-size;
    display: block;
    position: absolute;
    left: 0px;
    top: 17px;
  }
}
