@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';

.label {
  font-size: $h4-font-size;
  margin-bottom: $baseline_sm;
  margin-top: $baseline_half;
}

.assessmentPrintMsg {
  font-size: $xSmall;
  font-weight: 400;
  color: $gray400;
}

.dropdownWrapper {
  max-width: 50%;

  :global(.rw-dropdown-list) {
    margin-bottom: $baseline_sm;
  }
}
