@import '../../../../scss/base/variables';

.wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: $baseline;
}

@media print {
  .wrapper {
    padding-top: $baseline;
  }
}
