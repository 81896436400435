@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';

@media print {
  .container {
    padding: 0 !important;

    :global(.rw-state-disabled) {
      border: none;
    }
    :global(.rw-input) {
      padding: 0;
    }
  }
}

.container {
  padding: $baseline_half $baseline_half 0 $baseline_half;

  :global(.rw-list-option) {
    padding-left: $baseline_half !important;
  }

  :global(.rw-state-disabled) {
    background: white !important;
    cursor: default;

    :global(.rw-widget-input) {
      div {
        cursor: default;
      }
    }

    :global(.rw-select) {
      display: none;
    }
  }
}

.valueInput {
  overflow: auto;
  text-overflow: ellipsis;
}
