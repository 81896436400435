.gateway {
  display: none;

  .gateway__message {
    display: none;
    max-width: 100%;

    a {
      padding: 0 $baseline_half;

      i {
        width: $baseline_half;
        margin-right: calc($baseline_half / 2);
        font-size: $base-font-size;
      }
    }
  }
}
