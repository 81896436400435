@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.container {
  padding: $baseline;

  .table {
    border-collapse: collapse;

    tr,
    thead {
      background: transparent;
      border-bottom: 1px solid $white450;
    }

    tr {
      &:last-child {
        border-bottom: none;
      }
    }

    th {
      color: $gray200;
      font-size: $small;
    }

    td {
      padding-top: $baseline_half;
      padding-bottom: $baseline_half;
      color: $primary;

      &:nth-of-type(2) {
        max-width: $baseline * 2;
      }
    }
  }
}
