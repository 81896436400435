@import '../../../scss/base/variables';
@import '../../../scss/base/colors';
@import '../../../scss/base/zindex';

@media screen {
  .stickeyWrapper {
    left: 0;
    height: auto;
    color: $secondary;
    background: $white;
    position: relative; // the duplicate positions is IE support since it does not recognize sticky
    position: sticky;
    top: 0;
    margin: 0;
    z-index: index($z_layout, subnav);
  }

  .padded {
    padding: $baseline * 0.75 $baseline;
  }

  .caseTitle {
    margin-bottom: $baseline_half;
    margin-bottom: 0.78125rem;
    line-height: 1rem;
  }

  .titleCard {
    display: flex;
    position: relative;
    align-items: center;
    padding: $baseline_sm 0 0 0;
  }

  .folderIcon {
    top: calc($baseline_sm / 2) * -1;
    display: inline-block;
    position: relative;
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-right: none;
    border-radius: 100%;
    text-align: center;
    background: $teal150;

    > i {
      height: 20px;
      margin-top: 0.39062rem;
      color: $primary;
      font-size: 1.5rem;
    }
  }

  .information {
    width: 100%;
    display: inline-block;
    padding-left: 0.78125rem;
    padding-right: 2.4rem;

    > h3 {
      margin-bottom: $baseline_half;
    }
  }

  .number {
    height: $baseline_half;
    margin-bottom: calc($baseline_sm/4);
    font-size: calc($base-font-size/2);
    > h5 {
      font-weight: 400;
      opacity: 1;
    }
  }

  .icon {
    list-style-type: none;
    padding-left: $baseline_sm;
  }
}

@media print {
  .icon,
  .folderIcon {
    display: none;
  }
}
