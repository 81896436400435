/* Noramlize.css */

.rw-btn,
.rw-input {
  color: inherit;
  font: inherit;
  margin: 0;
}

button.rw-input {
  overflow: visible;
}

button.rw-input,
select.rw-input {
  text-transform: none;
}

button.rw-input,
html input[type='button'].rw-input,
input[type='reset'].rw-input,
input[type='submit'].rw-input {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled].rw-input,
html input[disabled].rw-input {
  cursor: not-allowed;
}

button.rw-input::-moz-focus-inner,
input.rw-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* -------------- */
