// date inputs
.home {
  display: flex;

  div {
    display: flex;

    .split-two {
      display: flex;
      position: relative;
      align-items: center;
      width: calc(50% - #{$baseline_sm});
      margin: $baseline_half 0;
      color: $white;

      &.start-date {
        margin-right: $baseline_half;

        input,
        input[disabled] {
          padding-left: calc(#{$input-default-padding-h} * 3 + 2rem);
        }

        + label {
          left: $input-default-padding-h;
          color: $white !important;
        }
      }

      input,
      input[disabled],
      textarea {
        padding: $baseline * 0.44 $baseline_sm;
        padding-left: $baseline * 2.5;
        border-color: $dark300;
        background: transparent;
        font-size: $xSmall;

        + label {
          top: 50%;
          left: calc(#{$baseline} + #{$baseline_half} * 0.6);
          margin-top: -0.95em;
          color: $white !important;
          font-size: $xSmall;
          opacity: 1 !important;
        }
      }
    }
  }
}

ul {
  .split-two {
    @extend .split-two;

    width: 49.5%;
    float: left;

    &.start-date {
      margin-right: 1%;
    }

    &.end-date {
      .rw-popup-container.rw-calendar-popup {
        left: auto;
      }
    }

    &.end-date {
      .rw-popup-container.rw-calendar-popup {
        left: auto;
      }
    }

    li {
      width: 100%;
    }
  }
}
