// -------------------------------------
//   Other = stuff that doesn't really fit in components or base
// -------------------------------------

.__react_component_tooltip.show {
  opacity: 1 !important;
}

@media screen {
  .print-only {
    display: none;
  }
}

@media print {
  em,
  i {
    // Fix for a Chrome bug that renders garbage in print media when there are italics present.
    // https://bugs.chromium.org/p/chromium/issues/detail?id=1018581
    font-style: normal;
  }

  iframe {
    // IE11 displays weird boxes in print when iframes are present.
    display: none;
  }
}
