@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.compositeSectionWrapper {
  padding: 0 $baseline_half;

  .header {
    display: flex;
    color: $dark;
    h3 {
      margin-bottom: 5px;
    }
    .filterButton {
      padding: unset;
      background: unset;
      margin-left: $baseline_sm;
      opacity: 0.6;
      .filterButtonIcon {
        min-width: 18px;
        min-height: 25px;
        font-size: $h2-font-size;
        color: $primary600;
        font-family: 'Material Icons';
      }
      &.searchVisible {
        opacity: 1;
      }
    }
  }
}

.count {
  padding-left: 8px;
  opacity: 0.5;
}
