@import '../../../scss/base/colors';
@import '../../../scss/base/variables';

.chartAndTableWrapper {
  margin: $baseline;

  > div {
    margin-bottom: $baseline;
  }
}

@media print {
  // Sets a container width for the charts and tables - otherwise the charts overflow off the page to the right.
  .chartAndTableWrapper {
    display: block;
    margin: 0;
    padding: 0;
    width: 87%;
  }
}
