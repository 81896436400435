@import '../../../scss/base/colors';
@import '../../../scss/base/variables';

.note {
  margin: $baseline_half $baseline;
  padding: $baseline-sm $baseline_half;

  .noteHeader {
    span {
      margin-left: $baseline_half;
    }

    strong {
      display: inline-block;
      margin: 0 $baseline_sm 0 0;
    }

    .actions {
      display: flex;
      float: right;
    }

    p {
      margin-bottom: 0;
      font-size: $base-font-size * 0.9;
      opacity: 0.6;
    }
  }

  &.confirmDelete {
    transition: background 300ms linear;
    border: none;
    border-radius: $border-radius + 2;
    background: $danger750;
    opacity: 1;
  }
}
