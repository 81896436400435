.eventLogDetails {
  position: absolute;
  top: 49px;
  left: 315px;
  width: calc(100% - 315px);
  height: calc(100% - 35px);
  margin-right: 8px;
  transform: translateY(100%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  border-top: 1px solid $white400;
  background: $white;
  color: $black400;
  font-size: 0.9rem;
  opacity: 0;
  z-index: index($z_aldt, details);

  > span {
    position: absolute;
    top: $baseline_half;
    right: $baseline_half;
    width: 22px;

    i {
      font-size: 19px;
      vertical-align: top;
    }
  }

  ul.list-unstyled {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: $baseline_half $baseline 0 $baseline;
    list-style: none;
    overflow: auto;

    li {
      padding-right: $baseline;

      h4 {
        margin-bottom: 0;
      }

      .source {
        @include ellipsis-source();
      }
    }

    &.list-nested {
      height: auto;
      padding: $baseline-half 0 0 0;
      border-top: 1px solid $white400;
      overflow: hidden;

      .list-nested {
        padding-left: $baseline-half;
      }
    }
  }

  &.openDrawer {
    transform: translateY(0%);
    opacity: 1;

    .icon-close {
      cursor: pointer;
    }

    .icon-report_problem {
      font-size: 1.2rem;
    }
  }
}

.public_fixedDataTable_header {
  .table-header-sortable {
    .material-icons {
      position: relative;
      top: -1px;
      left: 0.5em;
      font-size: 1.1rem;
    }
  }
}
