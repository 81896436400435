@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';

.wrapper {
  padding: $baseline;
}

.checkable {
  display: flex;
  width: 100%;
  padding-top: 0;

  &:nth-of-type(1) {
    padding-top: $baseline;
  }

  > div {
    flex-grow: 1;
    margin-top: 0;
  }

  > div:first-child {
    flex-grow: 0;
    margin-top: $baseline * 0.75;
    margin-right: $baseline_half;
  }
}

.pandy {
  background: $white;
}
