@import '../../../../../scss/base/variables';
@import '../../../../../scss/base/colors';

.wrapper {
  height: 100%;

  div {
    padding: 0;
  }

  .content {
    padding: 0 14px;
    line-height: $baseline * 0.75;

    &.hidePadding {
      padding: 0;
    }

    div {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .lineTwo {
      height: auto;
      font-size: $xSmall;
      color: $gray400;
    }
  }
}
