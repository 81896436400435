@import '../../../scss/base/colors';
@import '../../../scss/base/variables';

$border: 1px solid $white400;
$wrapperBorder: 1px solid #dcd9e5;

.wrapperContainer {
  border-radius: 5px;
  border: $wrapperBorder;
}

.actions {
  display: flex;
  align-items: center;
  margin-right: $baseline_sm;

  :global(.rw-dropdown-list) {
    width: 170px;
    height: 35px;
    margin-right: $baseline_half;
    border-radius: $border-radius;
  }
}

.header {
  display: flex;
  background-color: $white;
  align-items: center;
  border-bottom: $border;
  padding: 0 $baseline_half;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .title {
    display: flex;
    align-items: center;
    h1,
    p {
      font-size: $small;
      margin: 0;
      line-height: 3.125rem;
    }
    p {
      padding-left: $baseline_sm;
    }
  }

  .spacer {
    flex-grow: 1;
  }
}

.chartWrapper {
  display: flex;
  flex-direction: column;
  background-color: $white;
}

.chart {
  height: 320px;
  margin: 20px 10px 20px 10px;
  padding-bottom: 0;
  position: relative;
}

.legends {
  text-align: center;
  background: $white;
  padding: $baseline_sm;

  .legend {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 $baseline_half $baseline_half;
  }

  .circle {
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    border-radius: 50%;
    margin-right: $baseline_sm;
  }

  .text {
    font-size: $xSmall;
    margin: 0;
    line-height: 1.2;
  }
}

.tableWrapper {
  padding: 0 $baseline;
  background-color: $white;
  border-top: $border;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.table {
  border-collapse: collapse;
  font-size: $xSmall;

  td {
    padding: $baseline_half 0;
  }

  thead th {
    padding: $baseline_half 0;
    background-color: $white;
  }

  tbody tr {
    border-bottom: $border;

    &:last-of-type {
      border-bottom: none;
    }
  }
}

.pandyWrapper {
  position: relative;
  background: $white;
}

.chartCasesBy {
  width: 210px !important;
}

@media print {
  // Hides the actions bar which contains icons and per-chart actions.
  .actions {
    display: none;
  }

  // Charts have a hardcoded height on them, so even though Pandy doesn't display on print naturally, the empty space for the
  // chart is still rendered. Since we need this hardcoded height for the charts to render properly, this class can be conditionally
  // applied in JS if we know we will be showing, and will collapse the chart space.
  .hidePandyOnPrint {
    width: 0px !important;
    height: 0px !important;
    display: none !important;
  }

  .chartWrapper {
    break-inside: avoid;
  }
}

:export {
  white450: $white450;
  white500: $white500;
  // this multiplier is taken from _tables.scss
  tdFontSize: $xSmall * 0.9;
  primaryFont: $primaryFont;
}

.tooltipOverrides :global(.tippy-content) {
  text-align: left !important;
}

.tooltipTitle {
  margin-top: $baseline_sm;
  font-weight: bold;
  text-align: center;
}

.tooltipRow {
  display: flex;
}

.tooltipLabel {
  flex-grow: 1;
  margin-right: $baseline_sm;
}
