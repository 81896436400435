@import '../../../scss/base/variables';
@import '../../../scss/base/colors';
@import '../../../scss/effects/_shadows';

.expirationLi {
  width: 50%;
  margin-bottom: $baseline_sm;
}

.expirationText {
  font-size: smaller;
  color: $gray400;
  margin-bottom: $baseline_half + $baseline_sm;
  margin-top: -$baseline_sm;
}

.smallMargin {
  .input-container {
    margin-bottom: 0;
  }
}

.infoCard {
  padding: $baseline;
  background: $white;
  margin-bottom: $baseline;
  @include boxShadow($white450);
}

.vipData {
  display: flex;
  justify-content: flex-start;
  margin-top: $baseline;
  background: $white;
  padding: $baseline $baseline $baseline_half $baseline;
  border-bottom: 1px solid $white500;
}
