.rw-calendar-popup {
  right: auto;
  min-width: 0;
  width: 18em;
  font-size: 0.9em;
}

.rw-calendar {
  border-radius: $border-radius;
  background-color: $calendar-bg;
  border: $widget-border 1px solid;
  overflow: hidden;

  &.rw-popup {
    border-color: $popup-border;
    left: 1px;
  }
}

tr {
  background-color: $white;
}

.rw-calendar-header {
  border-bottom: 1px solid $input-border;

  .rw-calendar-btn-view {
    &:hover {
      color: #321f7d;
    }
  }
}

.rw-datetime-picker.rw-widget {
  background-color: transparent;
  border: none;

  .rw-input {
    background: transparent;
  }

  .rw-select {
    button {
      color: white;
    }
  }
}

.rw-widget-picker {
  input {
    height: 100%;
  }
}

.rw-i.rw-i-calendar {
  color: $primary;
}

.rw-input {
  background-color: white;
}

.rw-calendar-now {
  font-weight: bold;
}

.rw-calendar-btn-left,
.rw-calendar-btn-right {
  width: 12.5%;
}

.rw-calendar-btn-view {
  width: 75%;
}

.rw-calendar-footer {
  border-top: 1px solid $input-border;

  &:hover {
    color: #321f7d;
  }

  button:hover {
    color: #321f7d;
  }
}

.rw-calendar-grid {
  outline: none;
  height: (calc(200 / 14) * 1em);
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  background-color: $calendar-bg;

  th {
    text-transform: capitalize;
  }
}

.rw-head-cell {
  text-align: $calendar-cell-align;
  border-bottom: 1px solid $input-border;
  padding: $calendar-cell-padding;
}

.rw-cell {
  color: $calendar-cell-color;
  border-radius: $calendar-cell-border-radius;
  cursor: pointer;
  line-height: normal;
  text-align: center;
  border: 1px solid transparent;
  padding: $calendar-cell-padding;

  &:hover {
    border: $calendar-cell-border-hover 1px solid;
  }

  &.rw-state-focus {
    background-color: $calendar-cell-bg-focus;
    border: $calendar-cell-border-focus;
  }

  &.rw-state-selected {
    background-color: $calendar-cell-bg-select;
    border: $calendar-cell-border-select;
    color: $calendar-cell-color-select;
    border-radius: 4px;
  }

  &.rw-state-disabled {
    color: $btn-color;
    cursor: not-allowed;

    &:hover {
      background: none;
      border-color: transparent;
    }
  }

  .rw-calendar-month & {
    text-align: $calendar-cell-align;
  }
}

.rw-cell-off-range {
  color: lighten($btn-color, 40%);
}

.rw-calendar-transition-group {
  position: relative;
}

.rw-calendar-transition {
  transition: transform 300ms;
  overflow: hidden;
}

.rw-calendar-transition-top {
  transform: translateY(-100%);
}

.rw-calendar-transition-bottom {
  transform: translateY(100%);
}

.rw-calendar-transition-right {
  transform: translateX(-100%);
}

.rw-calendar-transition-left {
  transform: translateX(100%);
}

.rw-calendar-transition-entering,
.rw-calendar-transition-entered {
  &.rw-calendar-transition-top,
  &.rw-calendar-transition-bottom {
    transform: translateY(0);
  }
  &.rw-calendar-transition-right,
  &.rw-calendar-transition-left {
    transform: translateX(0);
  }
}

.rw-calendar-transition-exiting {
  &.rw-calendar-transition-top {
    transform: translateY(100%);
  }
  &.rw-calendar-transition-bottom {
    transform: translateY(-100%);
  }
  &.rw-calendar-transition-right {
    transform: translateX(100%);
  }
  &.rw-calendar-transition-left {
    transform: translateX(-100%);
  }

  .rw-btn {
    vertical-align: center;
  }
}

.rw-i.rw-i-calendar {
  font-size: 1.125rem;
  transform: none;
  color: $navy300 !important;
}

.rw-calendar-grid {
  thead {
    tr {
      th {
        padding: 9px 5px;
        text-align: center;
      }
    }
  }
  .rw-calendar-body > tr {
    background-color: $white;
    font-size: 14px;

    td:hover {
      background: $lighter-gray;
      border: none;
      border-radius: 4px;
    }
  }
}
