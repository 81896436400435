.container {
  height: 100%;

  .userPatient {
    display: flex;
    height: 100%;

    @media print {
      display: block;
    }
  }
}
