@import '../../../../scss/base/colors';
@import '../../../../scss/base/variables';

.drawerIncidents {
  height: calc(100% - 25px - #{$baseline});
  min-height: 0;
  padding: $baseline_half $baseline 0 $baseline;
  overflow-y: scroll;

  h5 {
    margin-bottom: $baseline_half;

    .iconInfo {
      float: none;
      margin-left: 0;
      opacity: 1;
    }
  }

  hr {
    position: relative;
    border-top: 1px solid $white500;
    overflow: visible;

    &:before {
      position: absolute;
      top: -$baseline_half;
      left: calc(50% - 50px - #{$baseline} * 2); // approximately centered
      padding: 0 $baseline;
      background-color: $white300;
      font-size: $xSmall;
      font-weight: 600;
    }
  }
}

.chartStart {
  &:before {
    content: 'Chart window begins';
  }
}

.chartEnd {
  &:before {
    content: 'Chart window ends';
  }
}
