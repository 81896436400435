// -------------------------------------
//   Dropdown menus
// -------------------------------------

@mixin border-top-radius($radius) {
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
}

@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}

$popup-zindex: index($z_layout, widget-dropdown);
$popup-box-shadow-offset: 6px !default;
$popup-zindex-focused: $popup-zindex + 1 !default;
$border-radius-sm: 3px !default;
$popup-box-shadow-offset: 6px !default;
$popup-box-shadow: 0 ($popup-box-shadow-offset - 1px) $popup-box-shadow-offset
  rgba(0, 0, 0, 0.2) !default;
$popup-box-shadow-up: 0 -2px $popup-box-shadow-offset rgba(0, 0, 0, 0.2) !default;
$widget-border: #ccc !default;
$popup-border: $widget-border !default;
$widget-bg: #fff !default;
$popup-bg: $widget-bg !default;

.rw-popup-container {
  position: absolute;
  z-index: $popup-zindex;
  top: 100%;
  left: -$popup-box-shadow-offset;
  right: -$popup-box-shadow-offset;

  &.rw-dropup {
    top: auto;
    bottom: 100%;
  }

  .rw-state-focus & {
    z-index: $popup-zindex-focused;
  }
}

.rw-popup-transition {
  // the offsets allows for drop shadow to not be clipped by the container
  width: 100%;
  margin-bottom: $popup-box-shadow-offset;
  padding: 0 $popup-box-shadow-offset - 1;

  .rw-dropup > & {
    margin-bottom: 0;
    margin-top: $popup-box-shadow-offset;
  }
}

.rw-popup {
  @include border-top-radius(0);
  @include border-bottom-radius($border-radius-sm);

  box-shadow: $popup-box-shadow;
  border: $popup-border 1px solid;
  background: $popup-bg;

  .rw-dropup & {
    @include border-bottom-radius(0);
    @include border-top-radius($border-radius-sm);

    box-shadow: $popup-box-shadow-up;
  }
}

.rw-popup-transition {
  transition: transform 200ms;
}

.rw-popup-transition-entering {
  overflow: hidden;

  & .rw-popup-transition {
    transform: translateY(0);
    transition-timing-function: ease-out;
  }
}

.rw-popup-transition-exiting {
  & .rw-popup-transition {
    transition-timing-function: ease-in;
  }
}

.rw-popup-transition-exiting,
.rw-popup-transition-exited {
  overflow: hidden;

  & .rw-popup-transition {
    transform: translateY(-100%);
  }

  &.rw-dropup .rw-popup-transition {
    transform: translateY(100%);
  }
}

.rw-popup-transition-exited {
  display: none;
}

.rw-list-option {
  height: auto !important;
  white-space: normal !important;
}

// -------------------------------------
//   Inverted dropdown menu styles
// -------------------------------------

.dropdown--inverse {
  &.rw-widget,
  &.rw-widget.rw-state-focus {
    background: rgba($teal, 0);
    border-color: rgba($white, 0.2);

    .rw-input,
    .rw-i {
      color: $white;
    }

    &:hover {
      background: rgba($teal, 0.5);
      border-color: rgba($white, 0.5);
    }
  }
}

// -------------------------------------
//   Link-style dropdown menus
// -------------------------------------

.rw-dropdown-list {
  &,
  .rw-input {
    font-size: $xSmall;
  }

  &.dropdown--link {
    display: inline-block;
    position: relative;
    top: 2px;
    width: auto;
    border: 0 none;
    background-color: transparent;
    margin: {
      top: -5px;
      bottom: -5px;
    }

    &:hover {
      background: none;
      border: 0 none;
      text-decoration: underline;
    }

    &,
    > .rw-input,
    .rw-i {
      transition: all 500ms ease-in-out;
    }

    > .rw-input,
    .rw-btn {
      line-height: $baseline;
    }

    > .rw-input {
      height: 100%;
      padding-left: 0;
      font-size: $xSmall;
    }

    .rw-btn {
      padding-top: 5px;
    }

    .rw-popup-container {
      min-width: $dropdown-min-width;
    }

    .rw-list-option {
      font-size: $xSmall;
    }

    &.dropdown--inverse {
      border: 1px solid transparent;

      &:hover {
        background-color: transparent;
        border-color: transparent;
      }

      .rw-input,
      .rw-i {
        color: $white;
      }

      .rw-input {
        text-decoration: underline;
        padding-left: 0;
      }
    }
  }
}
