@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.doubleLayout {
  display: flex;
  width: 100%;
}

.patientBox,
.employeeBox {
  flex: 1;
  height: calc(100vh - #{$subnav-height * 2});
  border: 1px solid $white500;
}

.column {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  background-color: $white;
}

.doubleColumn {
  display: flex;
  flex-direction: row;
  .column {
    border-right: 1px solid $white500;
    border-collapse: collapse;
    &:nth-child(2) {
      border-right: none;
      background-color: $white200;
    }
  }
}

.dataWarningText {
  color: $dark;
  font-size: $xSmall;
  margin: 0;
  padding: $baseline_half;
  line-height: 1;
}

.dataWarningBanner {
  background: $white400;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* this prevents extra whitespace at the bottom of the case view */
.column > div > div {
  height: auto;
}

@media print {
  .detailsNone {
    visibility: hidden;
    display: none;
  }
}
