@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

@media screen {
  .printTitle {
    display: none;
  }
}

@media print {
  .printTitle {
    display: block;
    margin: 45px 0 0 0;
    padding: 0 0 calc($baseline_half/2) 0;
    border-bottom: 1px solid $white450;
    color: $primary;
  }
}
