@import '../../../../scss/base/variables';

.labelGroup {
  &:nth-of-type(n + 2) {
    margin-top: $baseline_half;
  }
}

.labelLine:nth-of-type(n + 2) {
  margin-top: $baseline_sm;
}
