@import '../../../scss/base/variables';
@import '../../../scss/base/colors';
@import '../../../scss/base/zindex';

.searchContainer {
  position: relative;
  height: $subnav-height;

  .searchInput {
    top: $subnav-height * 0.2;
    height: $subnav-height * 0.6;
    padding: 0 $subnav-height * 0.7 0 1rem;
    border: none;
    background-color: $white300;
    border-radius: 30px;
    font-family: $primaryFont;
    font-size: $small;

    &::placeholder {
      color: $primary700;
      opacity: 0.5;
    }
  }

  .searchButton {
    position: absolute;
    top: $subnav-height * 0.2;
    right: 0;
    width: $subnav-height * 0.6;
    height: $subnav-height * 0.6;
    padding: 0;
    background: none;
    pointer-events: auto;
    z-index: index($z_forms, inputs);
    span {
      min-width: 24px;
      min-height: 24px;
      font-size: $h1-font-size;
      font-family: 'Material Icons';
      color: $primary600;
    }
  }
}
