.titleCard {
  display: flex;
  position: relative;
  align-items: flex-start;
  padding: $baseline 0 $baseline_sm $baseline;
  background: $white;

  &.case {
    display: flex;
    align-items: center;
    padding: $baseline_sm 0 0 0;
  }

  .titleCard--id {
    display: inline-block;
    position: relative;
    top: calc($baseline_sm / 2) * -1;
    width: 42px;
    height: 42px;
    border-right: none !important;
    border-radius: 100%;
    text-align: center;

    i {
      height: 20px;
      margin-top: $baseline * 0.25;
      color: $primary;
      font-size: $base-font-size * 1.7;
    }

    &.patient {
      background: $teal150;
    }

    &.employee {
      background: $teal150;
    }

    > .abs--top_right {
      top: -13px;
      right: -3px;

      > span {
        min-width: $base-font-size;
        height: unset;
        margin: 0;
        font-size: calc($base-font-size / 2);
        font-weight: normal;
        line-height: $base-font-size;
      }
    }
  }

  li {
    list-style-type: none;
  }

  .personInformation,
  .caseInformation {
    display: inline-block;
    padding-left: $baseline_half;

    h3 {
      line-height: 1rem;
    }
  }

  .personInformation {
    max-width: 80%;
    padding-bottom: $baseline_half;
  }

  .caseInformation {
    width: 90%;
  }

  .source {
    position: absolute;
    top: $baseline_half;
    right: $baseline_half;
    @include ellipsis-source();
  }

  h2,
  h5 {
    margin: 0;
    font-weight: normal;
  }

  h2 {
    position: relative;
    top: calc($baseline_sm / 2) * -1;

    a {
      color: $secondary;
    }
  }

  h5 {
    font-size: smaller;
  }
}

@media print {
  ul.list-padded {
    padding-left: 0;
  }
  .source {
    position: static !important;
    display: block;
  }
  .personInformation,
  .titleCard {
    position: static;
    padding: 0 !important;
  } //aligns the EMR user name
}
