// -------------------------------------
//   Checkbox styles
// -------------------------------------

@mixin change-checkbox-icon-styles {
  color: $navy300 !important;
  font-size: $base-font-size * 1.25;
  margin-left: 2px;
  margin-right: -2px;
}

.checkbox-base {
  cursor: pointer;
  span {
    padding-left: 4px;
  }
  .disabled + span {
    cursor: not-allowed;
  }
  i {
    width: 24px;
    transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    font-size: $base-font-size * 1.5;
    margin-top: -3px;
    line-height: inherit;
    // Fallback color
    color: $primary;

    &.primary {
      color: $warning;
    }

    &.secondary {
      color: $primary;
    }

    &.primaryLight {
      color: $light200;
    }

    &.icon-check_box_outline_blank {
      @include change-checkbox-icon-styles;
      &.primaryLight {
        color: $light200 !important;
        opacity: 0.75;
        &:hover {
          opacity: 1;
        }
      }
      &:hover {
        opacity: 0.5;
      }
    }

    &.icon-indeterminate_check_box {
      @include change-checkbox-icon-styles;
    }

    &.disabled {
      @include change-checkbox-icon-styles;
      &.primaryLight {
        color: $light200 !important;
        opacity: 0.5;
      }
      cursor: not-allowed;
    }
  }
}
