@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';

.attribute {
  font-size: $xSmall;
  padding-bottom: $baseline_sm;
  padding-top: $baseline_sm;
  margin-bottom: 0;
  border-bottom: 1px solid $white400;
  color: $primary;

  i {
    position: relative;
    top: -1px;
    font-size: $base-font-size;
    margin-right: $baseline-half;
  }

  @media print {
    color: inherit;
  }
}

.empty {
  > :first-child {
    opacity: 0.5;
    transition: opacity 0.25s;
  }

  a {
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}
