@import '../../../scss/base/colors';
@import '../../../scss/base/variables';

.toggleButton {
  margin-right: $baseline_half;
  color: $primary;
  cursor: pointer;
}

.toggleLink {
  color: $primary;
  cursor: pointer;

  &:hover {
    color: $primary500;
  }
}
