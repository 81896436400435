// *************************************
//
//   Project Name
//   -> Manifest
//
// *************************************

// -------------------------------------
//   Vendor
// -------------------------------------
@import '../../node_modules/fixed-data-table-2/dist/fixed-data-table';
@import '../../node_modules/react-toastify/dist/ReactToastify.min';
@import '../../node_modules/flexboxgrid/css/flexboxgrid.css';
@import '../../node_modules/@webscopeio/react-textarea-autocomplete/style.css';
@import '../../node_modules/tippy.js/dist/tippy.css';

// -------------------------------------
//   Base - styles on base elements: html, body, a, ul, li, etc.
// -------------------------------------
@import 'scss/base/index';

// -------------------------------------
//   Effects - shadows et al
// -------------------------------------
@import 'scss/effects/index';

// -------------------------------------
//   Layout - views for layouts, should not include components
// -------------------------------------
@import 'scss/layout/index';

// -------------------------------------
//   Components - the goal is for as much code to exist in here as possible, making it flexible and reusable.
// -------------------------------------
@import 'scss/components/index';

// -------------------------------------
//   Other - the code that doesn't fit in base, components, etc
// -------------------------------------
@import 'scss/other/index';

// -------------------------------------
//   Libs - external tools
// -------------------------------------
@import 'scss/lib/index';

// -------------------------------------
//   React-Widgets
// -------------------------------------
@import 'scss/react-widgets/react-widgets.scss';
