@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.excludable {
  display: flex;
  margin-bottom: 0.78125rem;
  position: relative;

  & ul {
    margin-bottom: 0;
  }

  > div {
    display: inline-flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  > div:last-child {
    width: 92%;
    margin-left: 0.39062rem;
  }

  i {
    font-size: 1.2rem;
    padding-top: $baseline_sm;
  }

  :global(.input-group-date-picker) {
    background: unset;
    margin-top: $baseline_half;
    margin-bottom: 0;

    input {
      margin-bottom: 0;
    }
  }
}

.disabled {
  color: $gray;
  cursor: not-allowed;
}

.pointer {
  cursor: pointer;
}

.whiteDatePickerBackground :global(.rw-datetime-picker) {
  background: $white;
}
