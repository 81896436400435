@import '../../../scss/base/variables';

.assessments {
  height: calc(100% - 25px - #{$baseline});
  overflow-y: scroll;
}

.activity_view__content {
  display: flex;
  flex: 1;
  width: 100%;
  height: calc(100vh - #{$subnav-height * 2});
  min-height: 30rem;
}
