html,
body {
  font-family: $primaryFont !important;
  font-size: $base-font-size;
  line-height: $baseline;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

// -------------------------------------
//   Headings
// -------------------------------------

h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
}

h1 {
  font-size: $h1-font-size;
  line-height: $h1-line-height;
  margin-top: $h1-margin-top;
  margin-bottom: $h1-margin-bottom;
}

h2 {
  font-size: $h2-font-size;
  line-height: $h2-line-height;
  margin-top: $h2-margin-top;
  margin-bottom: $h2-margin-bottom;
}

h3 {
  font-size: $h3-font-size;
  line-height: $h3-line-height;
  margin-top: $h3-margin-top;
  margin-bottom: $h3-margin-bottom;
  white-space: normal;
}

h4 {
  font-size: $h4-font-size;
  line-height: $h4-line-height;
  margin-top: $h4-margin-top;
  margin-bottom: $h4-margin-bottom;
}

h5 {
  font-size: $h5-font-size;
  line-height: $h5-line-height;
  margin-top: $h5-margin-top;
  margin-bottom: $h5-margin-bottom;
  text-transform: $h5-text-transform;
  opacity: 0.6;
}

h1,
h2,
h3,
h4 {
  small {
    display: inline-block;
    font-size: 100% !important;
    font-weight: normal;
    font-variant: small-caps;
    opacity: 0.8;

    &::first-letter {
      text-transform: lowercase;
    }
  }
}

// -------------------------------------
//   Paragraphs & content
// -------------------------------------

p {
  font-size: $base-font-size;

  img {
    margin: 0;
    padding: 0;
  }
}

p,
ul,
ol,
pre,
blockquote {
  margin-top: 0rem;
  margin-bottom: $baseline * 0.5;
}

strong {
  font-weight: 600;
}

.small,
small {
  font-size: 0.9em;
}

.x-small {
  font-size: $xSmall;
}

img {
  margin-bottom: $baseline;
}

pre,
code {
  font-size: 90%;
}

.hr,
hr {
  box-sizing: content-box !important;
  border: 0;
  border-top: 1px solid $white400;
  height: 0;
  margin: $baseline 0;
}

address {
  font-style: normal;
}

abbr[data-original-title],
abbr[title] {
  cursor: help;
  border-bottom: 1px dotted $gray;
}

// -------------------------------------
//   Text Styles
// -------------------------------------

.text-success {
  color: $success !important;
}

.text-warning {
  color: $warning;
}

.text-danger {
  color: $danger500;
}

.text-subtle {
  opacity: 0.6;
}

// -------------------------------------
//   Material UI Icons
// -------------------------------------

.material-icons {
  display: inline-block;
  font-family: 'Material Icons';
  font-size: $base-font-size * 1.5; /* Preferred icon size */
  font-style: normal;
  font-weight: normal;
  height: auto;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  vertical-align: middle;
  width: auto;
  word-wrap: normal;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  &.md-18 {
    font-size: 18px;
  }

  &.md-24 {
    font-size: 24px;
  }

  &.md-36 {
    font-size: 36px;
  }

  &.md-48 {
    font-size: 48px;
  }
}

// -------------------------------------
// Help text
// -------------------------------------
.help-text {
  color: $gray500;
  font-size: $small;
}

// -------------------------------------
//   Sanitation
// -------------------------------------

ul ul,
ol ol,
ul ol,
ol ul {
  margin-top: 0;
  margin-bottom: 0;
}

b,
strong,
em,
small,
code {
  line-height: $baseline;
}

sup,
sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4rem;
}

sub {
  top: 0.4rem;
}

.normal-weight {
  font-weight: normal !important;
}

// -------------------------------------
//   Print, prot-a
// -------------------------------------

@media print {
  .prot-a {
    color: $black !important;
  }
}
