@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.tableContainer {
  display: flex;
  .table {
    background: white;
    border-bottom-right-radius: $border-radius-md;
    border-bottom-left-radius: $border-radius-md;
    font-size: $xSmall;

    :global(.public_fixedDataTable_header .public_fixedDataTableCell_main) {
      background-color: $white450;
    }

    :global(.public_fixedDataTableRow_odd),
    :global(.public_fixedDataTableRow_odd:hover) {
      background-color: $white;
      cursor: default;
    }

    :global(.public_fixedDataTableRow_even),
    :global(.public_fixedDataTableRow_even:hover) {
      background-color: $white200;
      cursor: default;
    }
  }

  :global(.public_fixedDataTableCell_cellContent) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .doubleLine {
    line-height: normal;

    div {
      padding-right: 0;
    }

    :global(.public_fixedDataTableCell_cellContent) {
      padding-right: 25px;
    }
  }
}
