@import '../../../scss/base/variables';
@import '../../../scss/base/colors';
@import '../../../scss/effects/_shadows';

.expirationLi {
  width: 50%;

  > div {
    margin-bottom: $baseline_sm;
  }
}

.expirationText {
  font-size: smaller;
  color: $gray400;
  margin-bottom: $baseline_half + $baseline_sm;
}

.smallMargin {
  .input-container {
    margin-bottom: 0;
  }
}

.infoCard {
  padding: $baseline;
  background: $white;
  margin: $baseline 0;
  @include boxShadow($white450);
}
