@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.radioButtons {
  margin: $baseline_half 0;
  padding-left: 0;

  input[type='radio'] + label {
    font-size: $base-font-size;
    display: inline-block;
    color: $gray700;
  }

  input[type='radio'] + label:after {
    font-size: 22px;
    line-height: 26px;
    color: $primary;
  }

  input[type='radio']:hover + label:after {
    color: $primary;
  }
}
