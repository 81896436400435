$module: 'cases';
$avd-filter-width: $baseline * 13;

.#{$module} {
  @extend %page-layout;

  .card_holder {
    margin: 0;
    padding-top: $baseline * 3;
    transition: padding 666ms 100ms;
  }

  .expando-visible {
    .card_holder {
      padding-top: $baseline * 3.5;
    }
  }

  .loader-parent.loader-pandy {
    height: calc(100vh - #{$subnav-height * 2});
  }

  #routeHandler > div .view-content {
    padding-bottom: 0;
  }

  .advanced-filter-panel {
    display: none;
  }
}
