$module: 'settings';

.#{$module} {
  @extend %page-layout;

  .header {
    @extend %group;
  }
}

.toggle {
  .disabled {
    cursor: not-allowed;
  }
}

@media screen {
  .settings_view {
    .subnav {
      position: absolute;
      top: 0;
      width: 100%;
      width: inherit;
      overflow: visible;
      z-index: index($z_layout, subnav);
      animation: showSysAlert 666ms 100ms both;
    }

    #routeHandler > div & .view-content__body {
      width: calc(100% - #{$baseline} * 2);
      max-width: 100%;
      margin: $baseline;
      padding: 0;
      border-radius: $border-radius * 2;
      background: $white;
      box-shadow: 0 0 24px 0 $gray900;

      .settings__header {
        display: flex;
        flex-wrap: wrap;
        padding: $baseline_half $baseline;
        border-bottom: 1px solid $white400;

        h1 {
          margin: 0;
          line-height: $baseline * 1.1;
        }

        .settings__header-group-title {
          margin-top: -$baseline_sm;
        }

        .settings__header-icons {
          margin-left: auto;

          > div {
            display: inline-block;
          }
        }

        .settings__header-dropdown {
          width: 200px;
          margin-left: $baseline;
        }

        div {
          padding-top: $baseline_half;
          span {
            margin-right: $baseline_sm;
          }
        }
      }

      .registry__table.settings__table {
        .settings__table-cell {
          &.inactive {
            background-color: $white300 !important;

            input {
              opacity: 0.5;
            }
          }
        }
        small {
          margin-top: $baseline * 0.5;
        }
      }
    }

    .createCases {
      &.inactive {
        color: rgba(255, 156, 64, 0.5) !important;
      }
    }

    //
    // Customer Administered Accounts Table
    //
    .#{$module}__customer-administered-accounts-list-view {
      .registry {
        .view-content__body {
          box-shadow: none !important;
        }
        .no-gutter {
          display: none;
        }
      }

      .public_fixedDataTableRow_main {
        background-color: $white !important;
      }
      .public_fixedDataTableRow_even:hover,
      .public_fixedDataTableRow_odd:hover {
        background-color: $white400 !important;
        cursor: pointer;
      }

      .fixedDataTableLayout_header {
        .registry__table-header {
          height: 60px;

          .secondaryLabel {
            font-size: $base-font-size * 0.7;
            font-weight: normal;
            margin-top: -10px;
          }
        }
      }
    }

    //
    // Chart View: Charts
    //
    .#{$module}__charts {
      padding: $baseline * 0.8 $baseline;

      > header {
        margin-bottom: $baseline_half;
        border-bottom: 1px solid $white400;

        h2 {
          display: inline-block;
          margin-right: $baseline;
          vertical-align: middle;
        }
      }

      h3 {
        display: inline-block;
        margin-top: 5px;
        margin-right: $baseline_half;
      }

      > section {
        display: flex;

        svg {
          // Fixes an IE bug that causes horizontal scroll due to the settings charts.
          overflow: hidden;
        }

        section {
          width: 75%;

          header {
            width: 100%;
          }
        }

        aside {
          width: 25%;
          padding-left: $baseline;
        }
      }

      .#{$module}__chart__container {
        height: 340px;

        &.settings__chart__container-empty {
          text-align: center;

          .VictoryContainer {
            display: none;
          }
        }

        &.settings__chart__container-empty:before {
          display: block;
          padding-top: 100px;
          content: 'No cases found in the time range for the selected category';
        }
      }

      .#{$module}__chart__download,
      .#{$module}__chart__print {
        float: right;
        margin: {
          top: 5px;
          right: $baseline_half;
        }
      }

      .#{$module}__chart__range,
      .#{$module}__chart__interval {
        display: inline-block;
        width: 240px;
      }

      .#{$module}__chart__category {
        display: inline-block;
        width: 290px;
      }

      .dropdownComponent {
        display: flex;
        align-items: flex-start;
        float: right;
        vertical-align: middle;

        .rw-dropdown-list {
          display: inline-block;
          width: 15rem;
        }

        .expando.date-range {
          display: inline-block;
          position: relative;
          width: 18.75rem;
          top: -0.6rem;
          line-height: 0;

          .input-container {
            display: inline-block;
            position: relative;
            width: 49%;
            padding-left: $baseline_half;
          }

          .end-date {
            .rw-popup.rw-calendar.rw-widget-container.rw-widget {
              left: -6rem;
            }
          }

          label {
            top: $baseline * 0.75;
            left: $baseline;
          }
        }
      }
    }

    //
    // List View: Data Table
    //

    .#{$module}__table {
      small {
        margin: 0 $baseline $baseline;
      }

      .#{$module}__table-header {
        .material-icons {
          position: relative;
          top: -1px;
          left: 0.5em;
          font-size: 1.1rem;
        }
      }

      input[type='number'] {
        max-width: $baseline * 5;
        margin-top: -2px;
        padding: calc($baseline_half / 2);
        line-height: calc($baseline / 2);
      }

      .no-wrap {
        overflow: hidden;

        div {
          display: block;
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .public_fixedDataTable_main {
        background: transparent;
        overflow: visible;

        .fixedDataTableLayout_rowsContainer {
          overflow: visible;

          .fixedDataTableRowLayout_rowWrapper {
            z-index: 0 !important; // fixed-data-table sets the z-index manually which will chop off assignment dropdowns if not overriden
          }
        }
      }
    }

    .public_fixedDataTable_header,
    .public_fixedDataTable_header .public_fixedDataTableCell_main {
      background-color: $white450;

      .#{$module}__table-header {
        width: 100%;
      }

      .#{$module}__table-header--active {
        background-color: $white500;
      }
    }

    .public_fixedDataTableRow_even,
    .public_fixedDataTableRow_even:hover {
      background-color: rgba($white250, 0.3);
      cursor: default;
    }

    .public_fixedDataTableRow_odd,
    .public_fixedDataTableRow_odd:hover {
      background-color: $white;
      cursor: default;
    }

    .disabled {
      .public_fixedDataTable_main,
      .public_fixedDataTableRow_even,
      .public_fixedDataTableRow_even:hover,
      .public_fixedDataTableRow_odd,
      .public_fixedDataTableRow_odd:hover {
        background-color: $white300;
      }

      .public_fixedDataTableCell_wrap1.selected span i {
        color: transparentize($warning, 0.5);
      }

      .public_fixedDataTableCell_wrap1 em,
      .public_fixedDataTableCell_wrap1 input {
        opacity: 0.5;
      }
    }

    .set .material-icons {
      font-size: 1.4rem;
      text-anchor: middle;
    }

    // wrapped in settings__pager to avoid manipulating other settings tables
    .settings__pager {
      .public_fixedDataTable_bodyRow {
        .fixedDataTableCellGroupLayout_cellGroup {
          overflow: visible;
        }

        .public_fixedDataTableCell_main {
          .public_fixedDataTableCell_cellContent {
            padding-top: 18px;
            padding-bottom: 18px;
          }
        }
      }
    }

    .public_fixedDataTable_bodyRow {
      .public_fixedDataTableCell_main.tall {
        .public_fixedDataTableCell_cellContent {
          padding-top: 12px;
          padding-bottom: 12px;
        }
      }
    }

    .public_fixedDataTableCell_main:first-child {
      .public_fixedDataTableCell_cellContent {
        padding-left: $baseline;
      }
    }

    .public_fixedDataTableCell_main:last-child {
      .public_fixedDataTableCell_cellContent {
        padding-right: $baseline;
      }
    }
  }

  // =============================================================================
  // Overrides
  // =============================================================================

  //
  // Full-width layout adjustments
  //
  .#{$module}__pager,
  .templateSettings__view,
  .generalSettings__view {
    width: 100%;
  }

  .templateSettings__view,
  .generalSettings__view,
  .account__view {
    display: flex;
    height: calc(100vh - #{$subnav-height * 2});
    margin-top: $subnav-height;

    section {
      width: 70%;
      background: $white250;
      overflow-x: hidden;

      .wrapper {
        position: relative;
        width: auto;
        margin: $baseline $baseline;
        padding-bottom: $baseline_sm;
        border-radius: $border-radius * 1.5;
        background: $white;

        .card_header {
          padding: $baseline_sm 0 $baseline_sm $baseline * 0.8;
          border-bottom: 1px solid $white400;

          h1 {
            margin: 0;
            color: $gray700;
            font-size: 1.2rem;
          }
        }

        &#settings__wrapper-Site_Banner {
          overflow: visible;
        }

        .content {
          .settingsTemplate {
            margin: $baseline * 1.2;
            padding: $baseline_half;
            border: 1px solid $white400;
            border-radius: $border-radius * 4;
          }

          .banner_header {
            display: flex;
            justify-content: space-between;
            padding-bottom: $baseline_sm;
            .dropdown {
              width: 8rem;
            }
          }

          .date {
            text-align: right;
          }

          &.other {
            .input-container {
              display: flex;
              input {
                flex: 1;
              }
            }
            .saml-fields {
              margin-bottom: $baseline_half;
            }
          }

          .tip {
            color: $warning;
          }

          h2 {
            color: $gray700;
            font-size: $base-font-size;
          }
          h5 {
            padding-top: $baseline_sm;
            text-align: right;
          }
        }
      }
    }

    aside {
      width: 30%;
      height: 100%;
      padding: $baseline_half 0 $baseline_half $baseline;
      background-color: $white350;
      overflow-x: auto;

      h1 {
        margin-bottom: $baseline_sm;
        color: $gray700;
      }

      ul {
        list-style-type: none;
        padding-left: 0;
        color: $primary;

        h2 {
          margin-bottom: $baseline_half;
          cursor: pointer;
        }
      }
    }
  }

  .templateSettings__view {
    section {
      .subnav.actionbar {
        position: relative;
        top: 0;

        li {
          position: absolute;
          right: 0;
          background: $white;
          cursor: pointer;

          i {
            color: $primary;
            margin-right: 1rem;
          }
        }
      }

      .wrapper {
        textarea {
          font-size: $small; //overrides component's original style
        }

        .content {
          .settingsTemplate {
            padding: $baseline_half 0 0 0;
            border: 1px solid $white400;
            border-radius: $border-radius * 4;

            .template {
              &.confirm-delete {
                background: $danger750;
                border-radius: $border-radius * 4;
                opacity: 1;
                transition: background 300ms linear;
                border: none;

                input {
                  background: $white;
                }
              }
            }

            .template {
              &.confirm-delete {
                background: $danger750;
                border-radius: $border-radius * 4;
                opacity: 1;
                transition: background 300ms linear;
                border: none;

                input {
                  background: $white;
                }

                .template__header {
                  border-bottom: 0.07rem solid $white;
                }
              }
            }

            &.create {
              &.closed {
                padding: $baseline_half $baseline * 0.8 $baseline_half $baseline *
                  0.8;
              }

              > i {
                cursor: pointer;
              }

              hr {
                margin: $baseline_half * 3 0 $baseline_half 0;
              }
            }

            .template__header {
              ul {
                padding-right: $baseline;
                list-style-type: none;
                text-align: right;
                padding: $baseline_half $baseline * 0.8 $baseline_half $baseline *
                  0.8;

                .template-actionTitle {
                  float: left;
                  font-size: $base-font-size;
                  font-weight: 600;
                }

                &.empty {
                  padding: $baseline;
                }

                li {
                  display: inline;
                  margin: 0 0 0 $baseline_half;
                  color: $primary;
                  font-size: $base-font-size;
                  cursor: pointer;

                  &.muted {
                    pointer-events: none;
                    cursor: not-allowed;
                  }
                }
              }
            }

            .template__content {
              padding: $baseline * 0.8;

              input {
                width: 100%;
                height: $baseline * 2;
                text-transform: none;
              }

              .text-danger {
                display: inline-block;
                margin-bottom: $baseline_sm;
              }

              input {
                font-size: $base-font-size * 0.9;
                &::placeholder {
                  color: $gray500;
                }
              }

              textarea {
                margin-top: $baseline_half;
              }
            }

            .noteTemplate,
            .emailTemplate {
              position: relative;
              top: -4rem;
              margin-bottom: -4rem;
              z-index: 1;
            }

            hr {
              margin: $baseline_half 0;
            }

            .settingsTemplate__header {
              .settingsTemplate__header-title {
                display: inline;
                position: relative;
                padding-left: $baseline * 0.8;
              }

              .settingsTemplate__header-modify {
                padding-right: $baseline;
                float: right;
                color: $primary;
                cursor: pointer;
              }
            }

            .settingsTemplate__content {
              font-size: $base-font-size * 0.9;

              .borderBox {
                margin: $baseline * 0.8;

                > div {
                  margin-top: $baseline_half;
                  padding: $baseline_half;
                  border: 1px solid $white400;
                  border-radius: $border-radius * 2;
                }

                .template-lastEdit {
                  padding-top: $baseline_half;
                  font-size: $base-font-size * 0.9;
                  opacity: 0.6;
                }
              }

              .settingsTemplate__content-empty {
                font-style: italic;
                opacity: 0.4;
              }
            }
          }

          h2 {
            display: inline;
          }

          i {
            float: right;
            color: $primary;
          }
        }
      }
    }

    aside {
      ul {
        p {
          display: inline-block;
          cursor: pointer;

          &.inactive {
            opacity: 0.6;
          }
        }

        span {
          display: block;
          padding-left: $baseline;

          &.editing {
            padding-left: $baseline_sm * 0.2;

            p {
              margin-left: $baseline_sm * 0.73;
            }
          }

          p {
            display: inline-block;
            padding-left: 0;
            cursor: pointer;

            &.inactive {
              opacity: 0.6;
            }
          }
        }

        .icon-edit,
        .icon-add_circle {
          margin-bottom: 0.2rem;
          font-size: 1.2rem;
        }

        .icon-edit {
          cursor: default;
        }
      }
    }
  }

  .generalSettings__view {
    color: $gray700;

    .active {
      font-weight: 900;
    }

    .card_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      h1 {
        display: inline-block;
      }

      span {
        margin-right: $baseline;
        float: right;
      }
    }

    .content {
      padding: $baseline * 0.8;

      .error {
        color: $danger;
        > input {
          border: 1px $danger solid;
        }
      }

      .options-header {
        margin: 1rem auto;
        font-size: $baseline * 1.25;
        font-weight: normal;
      }

      h2 {
        margin-bottom: $baseline_sm;
      }

      .toggle {
        color: $gray700;
        > i {
          margin-right: $baseline_half;
          margin-bottom: $baseline_sm * 0.25;
          color: $primary;
          font-size: $base-font-size * 1.15;
        }
      }

      .duration-input {
        width: 15%;
        min-width: $baseline * 7;
      }
    }
  }
}

// print
@media print {
  html,
  body {
    height: auto;
  }

  .dont-print {
    display: none;
  }

  section {
    > section {
      header {
        h3 {
          display: none;
        }
      }
    }
  }

  .#{$module} {
    aside {
      width: 45%;
      padding-left: $baseline;
    }
  }

  .templateSettings__view {
    .print-title {
      img {
        width: 15%;
      }
    }

    h1 {
      padding-top: $baseline * 2;
    }

    .settingsTemplate {
      h2 {
        margin-bottom: 0;
      }
    }
  }

  .settings {
    min-width: auto !important;

    .settings__charts {
      .float-right {
        float: none !important;
      }

      .rw-widget {
        display: none;
      }
    }

    .settings__chart__container {
      height: 400px;
      padding-top: 40px;

      &.settings__chart__container-empty {
        text-align: center;

        .VictoryContainer {
          display: none;
        }
      }

      &.settings__chart__container-empty:before {
        display: block;
        padding-top: 100px;
        content: 'No cases found in the time range for the selected category';
      }
    }

    .navbar,
    .subnav,
    i,
    button,
    .format_bar {
      display: none !important;
    }

    // =============================================================================
    // Overrides
    // =============================================================================

    //
    // Full-width layout adjustments
    //
    .#{$module}__pager,
    .templateSettings__view {
      width: 100%;
    }

    #routeHandler > div .view-content {
      padding: 0;
    }

    #routeHandler > div .view-content__body {
      .settings__header > h1 {
        margin-bottom: 0;
      }

      .settings__charts {
        header {
          h2 {
            display: none;
          }
        }

        .expando.date-range {
          label {
            display: none;
          }
        }
      }
    }

    //
    // List View: Data Table
    //
    .settings__table {
      display: none;
    }
  }
}
