$module: 'bulk-case-actions';

.#{$module} {
  @extend %page-layout;

  .navbar__icon-btn {
    a {
      padding: 0;

      &:hover {
        background-color: transparent;
        color: $primary500;
      }
    }
  }

  .subnav {
    position: absolute;
    top: 0;
    width: 100%;
    overflow: visible;
  }

  .#{$module}__empty-actions {
    max-width: 100%;
  }

  // Layout overrides for the slide-in panel
  .registry--flex.with-aside .panel__aside {
    padding-left: 0;
    padding-right: 0;
    background-color: darken($white200, 6%);

    .subnav {
      background-color: darken($white200, 3%); // can't be transparent
    }

    .record__form,
    .form__actions {
      padding: 0 $baseline;
    }

    .record__form {
      padding-top: $baseline_half * 1.25;
      padding-bottom: $baseline * 1.25;

      ul,
      ul li:last-child {
        margin-bottom: 0;
      }
    }
  }

  .pane-grid {
    .pane-grid__row {
      &:first-child {
        padding-top: $baseline_half;
      }
    }
  }

  .bulk-case-actions__action {
    margin: $baseline_half $baseline;
  }

  .#{$module}__action {
    .rw-dropdown-list {
      margin-bottom: $baseline_half;
    }

    .item li {
      // override styles brought in from other locations
      margin-bottom: 0;
    }

    textarea {
      background: $white;
      width: 100%;
      margin: 0;
    }
  }

  .#{$module}__status {
    .material-icons {
      font-size: 1.2rem;
      padding: {
        right: 0;
        left: 0;
      }
    }
  }

  .help-text {
    text-align: center;
  }

  .button--progress {
    margin-bottom: $baseline_half;
  }
}
