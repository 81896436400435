@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.table {
  height: 100%;

  > div:first-child {
    height: 100%;
    overflow-y: auto;
  }

  header {
    height: 0;
  }

  :global(.public_fixedDataTableRow_odd:hover) {
    background-color: $white;
    cursor: default;
  }

  :global(.public_fixedDataTableRow_even:hover) {
    background-color: $white200;
    cursor: default;
  }
}

.processCell,
.nameCell {
  > i {
    margin-right: 10px;
    font-size: 18px;
  }
}

.processCell {
  width: 100%;
  display: flex;
  align-items: center;

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &.error {
    > i {
      color: $danger800;
    }
  }
}

.nameCell {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  > i {
    display: none;
  }

  &.showIcon {
    > i {
      display: inline-block;
    }
  }
}

.sourceCell {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.pathCell {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  div {
    padding-right: 0;
  }

  :global(.public_fixedDataTableCell_cellContent) {
    padding-right: 25px;
  }
}

.tooltip {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
