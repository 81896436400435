#modal--wrapper {
  position: relative;
  z-index: index($z_layout, modal);

  .modal {
    background-color: rgba($white300, 0.9);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
    width: 100%;
    visibility: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      background-color: $white;
      border-radius: 2px;
      box-shadow: 0px 4px 2px -2px $white450;
      max-width: 38rem;
      padding: $baseline;
      position: relative;
      transition: all 0.5s;
      width: 50%;

      @media screen and (max-width: 1023px) {
        width: 80%;
      }
    }

    .modal--close {
      color: $dark;
      position: absolute;
      right: $baseline;
      top: $baseline;

      i {
        font-size: 18px;
      }

      &:hover {
        color: $primary;
      }
    }

    &.show {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
      transition: all 0.2s ease-in-out;

      > div {
        transition: all 0.8s;
      }
    }
  }
}
