@import '../../../../../scss/base/colors';

.svgWrapper {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  border-bottom: 1px solid $white450;
  cursor: pointer;
}

.selected {
  background: $white150;
}
