@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';

.exclusionFreeForm {
  :global(.rw-multiselect) {
    input {
      text-transform: none;
    }
  }
  div {
    > :last-child {
      width: 100%;
    }
    label {
      margin-left: calc($baseline/2) + 1.6;
      left: 0;
    }
  }
  :global(.rw-popup-container) {
    display: none !important;
  }
  :global(.rw-widget-input) {
    :global(.rw-select) {
      display: none !important;
    }
    :global(.rw-multiselect-tag) {
      span {
        text-transform: none;
      }
    }
  }
}
