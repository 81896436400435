@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';

.wrapper {
  padding: $baseline;
}

@media print {
  .wrapper {
    padding: 0;
    color: $black;
  }

  .hideForPrint {
    display: none;
  }

  .noteContainer {
    display: block;
  }
}
