@import './variables.scss';

.#{$rw-css-prefix} {
  display: inline-block;
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: normal;
  line-height: auto;
  font-size: 21px;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  right: auto;
  top: auto;
  color: darkgray;
}

.#{$rw-css-prefix}-caret-down:before {
  content: '\e5c5';
}
.#{$rw-css-prefix}-caret-up:before {
  content: '\e800';
}
.#{$rw-css-prefix}-caret-left:before {
  content: '\e801';
}
.#{$rw-css-prefix}-caret-right:before {
  content: '\e802';
}
.#{$rw-css-prefix}-chevron-left:before {
  content: '\e5cb';
}
.#{$rw-css-prefix}-chevron-right:before {
  content: '\e5cc';
}

.#{$rw-css-prefix}-clock-o:before {
  content: '\e805';
}
.#{$rw-css-prefix}-calendar:before {
  content: '\e916';
}
.#{$rw-css-prefix}-search:before {
  content: '\e806';
}
