@import '../../../scss/base/colors';
@import '../../../scss/base/variables';

.personCard {
  .selectButton:not(.selected) {
    display: none;
  }

  &:hover {
    .selectButton {
      display: block;
    }
  }

  ul {
    position: relative;
  }

  .selectField {
    position: absolute;
    right: $baseline;
  }
}

.selectButton {
  margin-top: $baseline_sm;

  i {
    position: static;
    color: $primary;

    &::after {
      content: 'check';
    }
  }

  &:hover {
    background: $primary;
    color: $white;
    transition: color 0.2s, background-color 0.2s;

    i {
      color: $white;

      &::after {
        content: 'close';
      }
    }
  }

  &.selected {
    padding: calc($baseline_sm / 2);
  }
}
