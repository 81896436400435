@import '../../../scss/base/variables';
@import '../../../scss/base/colors';
@import '../../../scss/base/mixins';

.multilineInputContainer {
  display: flex;
  flex-direction: column;
  padding: $baseline_sm $baseline_half;
  background: $white;

  &:not(.labeled) {
    padding: $baseline_half;
  }

  &.bordered {
    border: 1px solid $white500;
  }

  &.borderRadius {
    border-radius: $border-radius * 2;
  }

  &.warn {
    border-color: $danger;
    .label {
      color: $danger;
    }
    @include placeholder {
      color: $danger;
    }
  }

  &.readOnly {
    background: $white200;

    .label {
      color: $gray500;
    }
  }

  &.darkBackground {
    background: $white300;

    textarea {
      background: $white300;
    }
  }

  @include placeholder {
    color: $gray300;
  }
}

.label {
  opacity: 0;
  margin-top: -10px;
  position: static;
  transition: opacity 250ms ease-in-out, margin 250ms ease-in-out;
}

.showLabel {
  .label {
    opacity: 1;
    margin-top: 0;
  }

  @include placeholder {
    opacity: 0;
  }
}

.textarea {
  height: auto;
  padding: 0;
  border: none !important; // because of over-eager global styles.
  font-size: $base-font-size;
}
