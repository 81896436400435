dl {
  margin: 0;

  dt,
  dd {
    display: block;
  }

  dt {
    font-weight: bold;
    &:first-letter {
      text-transform: capitalize;
    }
  }

  dd {
    margin: 0 0 $baseline * 0.5 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
