@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

$gray-border: 1px solid $white500;

.incidentBox {
  margin-bottom: $baseline;
  border: $gray-border;
  border-radius: 5px;
  background-color: $white;
  overflow: hidden;

  > :not(:last-child):not(.notes) {
    border-bottom: $gray-border;
  }
}

.selected {
  border: 1px solid $primary;
}

.actionsWrapper {
  display: flex;
  flex-shrink: 0;
}

.idText,
.idValue {
  font-size: $xSmall;
  color: $gray400;
}

.idValue {
  font-weight: 700;
}

.idContainer {
  line-height: 1;
}

.header {
  margin: 0;

  .row {
    margin-bottom: 0;
    padding: $baseline * 0.75 $baseline;
  }

  .medication {
    margin-bottom: 0;
    white-space: break-spaces;
    line-height: 1.3;
  }

  .incCaseInfo {
    display: inline;
    white-space: nowrap;
    font-size: 15px;

    .caseLink {
      margin-right: $baseline_half;

      i {
        margin-top: -3px;
        margin-right: $baseline_sm;
      }
    }
  }

  .newIcon {
    color: $secondary1000;
    margin-left: $xxSmall;
    font-size: $baseline_lg;
    padding-bottom: $baseline_sm * 0.25;
  }

  .medicationRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  * {
    line-height: $baseline_half + $baseline_sm;
  }

  .incidentCount {
    min-width: 5rem;
  }
}

.reason {
  background: $white200;
  padding: $baseline_half $baseline;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span.tooltip {
    display: inline;
    color: $primary;
    font-size: $small;
    font-weight: bold;
    cursor: default;
  }

  h3 {
    display: inline;
    cursor: pointer;
    padding-right: $baseline_sm;
  }
}

.resolution {
  min-width: $baseline * 8;
  border-radius: $border-radius-md;

  strong {
    padding-right: $baseline_sm;
  }
}

.tablewrapper {
  padding: 0 $baseline_half 0 $baseline_half;
}

.table {
  font-size: calc($base-font-size/1.25);
  border-collapse: collapse;

  .orderHeader {
    min-width: $baseline * 3;
  }

  .dateColumn {
    width: 25%;
  }

  th {
    font-size: smaller;
  }

  td {
    border-top: $gray-border;
  }
}

// Note styles
.noteContainer {
  background: $white250;
  overflow: auto;
  border-bottom: $gray-border;

  &.noteContainerHeightZero {
    border: none;
  }
}

.notes,
.note {
  font-size: $xSmall;
}

.viewNote,
.addNote {
  font-size: $small;
}

.notesHeading,
.addNote {
  margin-right: $xSmall;
  display: flex;
  align-items: center;
  color: $primary;
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  color: $gray300;
}
.notesBodyEditing {
  border-bottom: solid 1px $white450;
  margin-top: -$baseline_half;
  textarea {
    font-size: $xSmall;
  }
}

.note {
  padding: $baseline_half 0;
  margin: $baseline;
  background: $white;
}

.confirmDelete {
  color: $danger;
  transition: ease-in-out 200ms;
}

.noteHeading {
  color: $gray400;
  display: flex;
  align-items: center;
  padding: $baseline_sm $baseline_half 0 $baseline_half;

  > :first-child {
    flex: 1;
    display: flex;
    align-items: center;

    > * {
      margin-right: $baseline_sm;
    }
  }
}

.noteActions {
  > span {
    display: inline-block;
    margin-left: $baseline_half;
    cursor: pointer;
  }
}

.noteBody {
  padding: 0 $baseline_half;
}

.noteDelete {
  font-size: inherit;
}

@media print {
  .incidentBox {
    page-break-inside: avoid;
  }

  .note.editing,
  .noteActions,
  .actionsWrapper {
    display: none;
  }
  .secondaryContent {
    color: $gray500;
  } //color for patient MRN
  .printTable > td {
    color: black;
  }
}
