.incident-summary-container {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  margin-top: 15px;

  .incident-summary-block {
    display: flex;
    flex-direction: column;

    .incident-summary-block-label {
      margin: 0;
      color: #8881a0;
      font-size: 0.8rem;
      line-height: initial;
    }

    .incident-summary-block-content {
      margin: 0;
      font-size: 0.9rem;
      line-height: initial;
    }
  }
}
