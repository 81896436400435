.label {
  border-radius: 5px;
  color: $white;
  display: inline-block;
  font-size: $xSmall;
  line-height: 1.5;
  margin: 2px 0;
  padding: 0 $baseline_sm;
  text-align: center;

  white-space: nowrap;
  vertical-align: middle;

  @media print {
    color: $black;
    box-shadow: none;
  }

  &.label-primary {
    background: $primary;
  }

  &.label-warning {
    color: $black;
    background: $warning300;
  }

  &.suspicion-label-danger {
    background: $danger750;
    color: $danger;
  }

  &.suspicion-label-warning {
    background: $warning100;
    color: $warning;
  }

  &.label-danger {
    background: $danger500;
  }

  &.label-secondary {
    background: $secondary;
  }

  &.label-success {
    background: $success500;
  }

  &.label-inverse {
    background: $white;
    color: $primary;
  }

  &.badge {
    border-radius: 18px;
    font-size: 0.8rem;
    height: 18px;
    line-height: 20px;
    margin: 0;
    min-width: 20px;
    padding: 0 5px;
    text-align: center;
  }

  > i {
    font-size: 14px;
    line-height: 12px;
  }

  &.label-icon {
    &.label-primary,
    &.label-secondary,
    &.label-success,
    &.label-warning,
    &.label-danger,
    &.label-inverse,
    &.label-patient,
    &.label-user {
      background: $white200;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      color: $dark;
      padding-right: $baseline * 1.5;
      position: relative;

      > i {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        color: $white;
        font-size: 13px;
        height: 19px;
        line-height: 19px;
        position: absolute;
        right: -0.5px;
        top: 0;
        padding: 0 $baseline_sm;
      }

      &.label-primary {
        > i {
          background: $primary;
        }
      }

      &.label-warning {
        > i {
          background: $warning;
        }
      }

      &.label-danger {
        > i {
          background: $danger;
        }
      }

      &.suspicion-label-danger {
        background: $danger750;
        color: $danger;
      }

      &.suspicion-label-warning {
        background: $warning100;
        color: $warning;
      }

      &.label-secondary {
        > i {
          background: $secondary;
        }
      }

      &.label-success {
        > i {
          background: $success;
        }
      }

      &.label-inverse {
        > i {
          background: $white400;
          color: $dark;
        }
      }

      &.label-user {
        > i {
          background: $teal500;
        }
      }

      &.label-patient {
        > i {
          background: $secondary;
        }
      }
    }
  }
}
