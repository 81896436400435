@import '../../../../scss/base/variables';

.metadataCount {
  display: flex;
  align-items: center;
  padding: 0 $baseline_sm;
}

.multilineTooltipRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;
}

.accessAuthTooltipRightCol {
  margin-left: $baseline * 2;
}

.multilineTooltipRow:not(:last-child) {
  margin-bottom: $baseline_sm;
}

.multilineTooltipText {
  margin-bottom: 0;
}
