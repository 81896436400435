.sysAlert {
  height: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: index($z_layout, sysalert);
  color: $white;

  > div {
    transform: translateY(-100%);
  }

  span {
    color: $white;
    text-decoration: underline;
    cursor: pointer;
  }

  &.active {
    animation: showSysAlertParent 333ms ease-in-out 0s both;
    overflow: visible;
    height: auto;

    > div {
      animation: showSysAlert 333ms ease-in-out 0s both;
    }
  }

  &.inactive {
    animation: hideSysAlertParent 333ms ease-in-out 0s;
    > div {
      //height: 0;
      animation: hideSysAlert 333ms ease-in-out 0s both;
    }
  }

  &.neutral {
    &,
    a {
      color: $dark550;
    }

    > div {
      background-color: $white500;
    }
  }

  &.secondary {
    background-color: $secondary;
  }

  &.success > div {
    background-color: $success;
  }

  &.warning > div {
    background-color: $warning;
  }

  &.danger > div {
    background-color: $danger;
  }

  &.multiPerson > div {
    background-color: $primary;
    a {
      color: $white;
      text-decoration: underline;
    }
  }

  &.lock {
    background-color: $warning;
  }

  &.flag {
    background-color: $teal500;
    a {
      color: $white;
      text-decoration: underline;
    }
  }

  &.relative {
    position: relative;
    z-index: 0;
  }

  > div {
    height: 100%;
    padding: $baseline_half $baseline_half;
    width: 100%;
  }

  p {
    margin-bottom: 0;
    margin-top: 2px;

    i {
      float: left;
      font-size: 24px;
      margin-right: $baseline_half;
    }
  }

  .controls {
    float: none;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    padding: $baseline_half;

    &:hover {
      background: transparent;
      color: $white;
    }
  }
}
