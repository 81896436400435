// -------------------------------------
//   Animations
// -------------------------------------

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes scale {
  0% {
    transform: scale(1.67);
  }
  5% {
    transform: scale(0.67);
  }
  50% {
    transform: scale(2);
  }
  60% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.67);
  }
}

@keyframes scaledown {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
}

@keyframes highlight {
  0% {
    background-color: rgba($white350, 1);
    color: $gray !important;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
  50% {
    background-color: rgba($secondary, 1);
    color: $white !important;
    box-shadow: inset 0px 5px 5px rgba(0, 0, 0, 0.1);
  }
  100% {
    background-color: rgba($white350, 1);
    color: $gray !important;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
}

@keyframes showColTwo {
  0%,
  10% {
    transform: translateX(0px);
    opacity: 0;
  }
  100% {
    transform: translateX(240px);
    opacity: 1;
  }
}

@keyframes flexMainRight {
  0%,
  10% {
    flex: 0 0 100%;
  }
  100% {
    flex: 0 0 65%;
  }
}

@keyframes flexMainLeft {
  0%,
  10% {
    flex: 0 0 65%;
  }
  100% {
    flex: 0 0 100%;
  }
}

@keyframes frameInLeft {
  0%,
  10% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes frameOutLeft {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes frameInRight {
  0%,
  10% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes frameOutRight {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes showNavbarTop {
  0%,
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes showNavbarLeft {
  0%,
  10% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes showSubMeta {
  0%,
  10% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(240px);
    opacity: 1;
  }
}

@keyframes showCreateCase {
  0%,
  10% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes hideCreateCase {
  0%,
  10% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes closeDrawer {
  0%,
  10% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes openDrawer {
  0%,
  10% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes pulsate {
  0% {
    fill: rgba($secondary, 1);
  }
  50% {
    fill: rgba($warning, 1);
    stroke: white;
  }
  100% {
    fill: rgba($secondary, 1);
  }
}

@keyframes pulsateGrow {
  0% {
    r: 6;
  }
  100% {
    r: 8;
  }
}

@keyframes faderIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dim {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes pandyBubble {
  0% {
    opacity: 0;
    transform: translateY(20%) scale(0.5);
  }
  100% {
    opacity: 1;
    transform: translateY(0%) scale(1);
  }
}

@keyframes showSysAlertParent {
  0%,
  10% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: auto;
  }
}

@keyframes hideSysAlertParent {
  0%,
  10% {
    opacity: 1;
    height: auto;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}

@keyframes showSysAlert {
  0%,
  10% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes hideSysAlert {
  0%,
  10% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes showBannerParent {
  0%,
  10% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: $baseline * 2.5;
  }
}

@keyframes hideBannerParent {
  0%,
  10% {
    opacity: 1;
    height: $baseline * 2.5;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}

@keyframes showBanner {
  0%,
  10% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes hideBanner {
  0%,
  10% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes expando {
  0%,
  10% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

@keyframes expandoHidden {
  0%,
  10% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
