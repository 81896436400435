// -------------------------------------
//
//   Tags
//   ----
//
//   Styles specific to the display of person tags. Mostly this will be overrides
//   of other components.
//
// -------------------------------------

$module: tags;

.#{$module} {
  @extend %page-layout;

  .navbar__icon-btn {
    a {
      padding: 0;

      &:hover {
        background-color: transparent;
        color: $primary500;
      }
    }
  }

  #routeHandler > div .view-content.registry {
    height: calc(100vh - #{$subnav-height} - #{$actionnav-height});
    padding-bottom: 0;
  }

  #routeHandler > div .view-content__body {
    padding-bottom: 0;
  }

  .registry--flex .panel__main {
    padding: 0;
  }

  .subnav {
    top: 0;
    width: 100%;
    overflow: visible;

    span.icon {
      margin-left: $baseline_half;
      color: $primary;
      cursor: pointer;
      :hover {
        color: $primary500;
      }
    }
  }

  .tags__empty-actions {
    max-width: 100%;
  }

  .secondary {
    flex-basis: initial;
    flex-wrap: inherit;
  }

  // Layout overrides for the slide-in panel
  .registry--flex.with-aside {
    flex-flow: row;

    .panel__main {
      animation: flexMainRight 0.3s 0s both;
    }
  }

  .pane-grid {
    .pane-grid__row {
      &:first-child {
        padding-top: $baseline_half;
      }
    }
  }
}

.tags--view {
  flex: 1;
}
