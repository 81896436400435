@import '../../../scss/base/variables';

.printableSectionHeader {
  margin: $baseline 0 0 0;
  font-weight: bold;
  font-size: 18px;

  .icon {
    margin: -2px $baseline_half 0 0;
    font-size: $h2-font-size;
  }
}
