.user-patient-section-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $baseline_half $baseline;

  .user-patient-section {
    :nth-child(3) {
      min-width: 15%;
    }

    .user-patient-label {
      margin-bottom: 0;
      color: $gray500;
      display: inline;
    }

    .user-patient-additional-label {
      font-size: $xSmall;
      color: $gray500;
      display: inline;
    }

    .user-patient-values {
      margin-bottom: 0;
      font-weight: normal;
    }
  }

  .material-icons {
    width: 24px;
    height: 24px;

    &.icon-arrow_forward {
      color: rgba(0, 0, 0, 0.1);
    }
  }
}
