@import '../../../../scss/base/variables';

.tablePanel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: $subnav-height;
}

.tableArea {
  padding: $baseline;
}

.rotate {
  width: 160px;
  transform: rotate(-90deg) translate(50px, -132px);
  transform-origin: 100%;
  cursor: pointer;

  &.lock {
    padding-left: 0;
    &::before {
      content: '\e897';
      font-family: 'Material Icons';
      position: absolute;
      top: 24px;
      left: 10px;
    }
  }

  .roleNames {
    display: table-cell;
    vertical-align: middle;
    height: 50px;
    padding-left: 24px;
  }
}
