// -------------------------------------
//   Button to the core
// -------------------------------------

.button,
button {
  @include mouse-hover(0.2s, 0s, $btn_trans_timing);

  background: $primary;
  border: 1px solid transparent;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1.5625;
  padding: $baseline * 0.25 $baseline;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: none !important; // removes extra shadows from the smartunderline
  vertical-align: middle;
  white-space: nowrap;

  i {
    color: $gray200;
    position: absolute;
    right: $baseline_half;
    top: 8px;
  }

  &.extra_padding i {
    right: $baseline;
  }

  &:hover {
    @include mouse-hover(0.2s, 0s, $btn_trans_timing);

    opacity: 0.8;
    border-radius: $baseline * 0.125;
    color: white;
    cursor: pointer;
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }
}

input.button {
  &:focus:not(.button--inverse) {
    background: scale-color($primary, $lightness: -20%);
  }
}

// -------------------------------------
//   Styles
// -------------------------------------

.button--outlined {
  background: $white;
  border: 1px solid $primary;
  color: $primary;

  &:hover {
    background: $white200;
    opacity: 1;
    color: $primary;
    transform: none !important;
  }

  &[disabled]:hover {
    background: $btn_bg_outline;
    border: $btn_border_style;
    color: white;
  }

  &__hover-fill {
    &:hover {
      background: $primary;
      color: $white;

      &.button--only-icon:hover i {
        color: $white;
      }
    }
  }
}

.button--link {
  background: $white200;
  opacity: 1;
  border: 1px solid transparent;
  color: $primary;

  &:hover {
    background: $white250;
    color: $primary;
    opacity: 1;
    transform: none !important;
  }

  &[disabled]:hover {
    background: $btn_bg_outline;
    border: $btn_border_style;
    color: white;
  }
}

.button--inverse {
  color: $primary;
  background: white;
  &:hover {
    background: $primary;
    color: white;
  }
  &[disabled]:hover {
    color: $primary;
    background: white;
  }
}

.button--cta {
  color: $white;
  background: $pink500;
  &:active {
    background: scale-color($pink500, $lightness: -10%);
  }
  &:hover {
    background: scale-color($pink500, $lightness: -10%);
    opacity: 1;
  }
  &[disabled]:hover {
    background: $pink500;
    opacity: 0.5;
  }
}

.button--secondary {
  color: white;
  background: $secondary;
  &:active {
    background: scale-color($secondary, $lightness: -10%);
  }
  &[disabled]:hover {
    background: $secondary;
  }
}

.button--success {
  color: white;
  background: $success;
  &:active {
    background: scale-color($success, $lightness: -10%);
  }
  &[disabled]:hover {
    background: $success;
  }
}

.button--warning {
  color: white;
  background: $warning;
  &:active {
    background: scale-color($warning, $lightness: -13%);
  }
  &[disabled]:hover {
    background: $warning;
  }
}

.button--danger {
  color: white;
  background: $danger;
  &:active {
    background: scale-color($danger, $lightness: -12%);
  }
  &[disabled]:hover {
    background: $danger;
  }
}

.button--list {
  background: transparent;
  border: none;
  border-radius: 0px !important;
  color: $primary;
  display: block;
  padding: $baseline_half;
  text-align: left;
  width: 100%;

  &:hover {
    opacity: 1;
    background: scale-color($white150, $lightness: -2%);
    color: scale-color($primary, $lightness: -3%);
  }

  &:active,
  &.active {
    background: scale-color($white150, $lightness: -3%);
    color: $dark;
  }

  &[disabled]:hover {
    background: $white200;
  }

  &.extra_padding {
    padding: $baseline_half $baseline;
  }
}

// -------------------------------------
//   Modifiers
// -------------------------------------

.button--only-icon {
  padding: $baseline * 0.09;

  i {
    position: static;
    color: $white;
    right: auto;
    top: auto;
  }

  &.button--link,
  &.button--outlined,
  &.button--inverse,
  &.button--list {
    i {
      color: $primary;
    }
  }
}

.button--text {
  background: none;
}

// -------------------------------------
//   Sizes
// -------------------------------------

.button--mini {
  font-size: 0.7rem;
  letter-spacing: 0.5px;
  line-height: 2;
  padding: 0 $baseline * 0.45;
}

.button--big {
  padding: calc($baseline / 2) $baseline;
}

.button--block {
  display: block;
  width: 100%;
}

// -------------------------------------
// Indicators: Progress
// -------------------------------------
.button--progress {
  position: relative;
  outline: none;
  overflow: hidden;

  &.button--progress--loading[disabled] {
    background: transparent;
    opacity: 1;
  }

  .button--progress__progress {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    background: $pink500;
  }

  .button--progress__complete {
    font-weight: normal;
  }

  .button--progress__progress-inner {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    transition: width 0.3s, opacity 0.3s;
    background: mix($white200, $pink500, 43%);
  }

  .button--progress__content {
    position: relative;
    transition: transform 0.3s;
    z-index: 10;
  }
}

.button--block.button--progress {
  .button--progress__progress,
  .button--progress__content,
  .button--progress__progress-inner {
    display: block;
  }

  .button--progress__progress,
  .button--progress__content {
    width: 100%;
  }
}
