$module: 'bundle';
$page-height: calc(100vh - #{$subnav-height * 2});

.#{$module} {
  @extend %page-layout;

  #routeHandler > div,
  .routeHandler > div {
    .view-content {
      height: $page-height;
    }
  }

  .card_holder .card > ul:last-of-type li {
    width: 10%;

    &.card__user,
    &.card__patient {
      width: 17%;
    }

    &.card__action-date {
      width: 43%;
    }
  }

  .subnav {
    position: inherit;
    top: 0;
    width: 100%;
    overflow: visible;
  }

  .registry--flex {
    height: $page-height;
  }

  .registry--flex.with-aside .panel__main {
    height: auto;
  }

  .registry--flex.with-aside .panel__aside {
    position: unset;
    height: auto;
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    overflow: auto;

    section {
      display: flex;
      left: 0;
      flex: 1;
      flex-direction: column;
      margin: 0;
      padding: 0;
      border: 0;
      background: none;
      overflow: visible;

      section {
        display: flex;
        left: 0;
        flex: 1 0 auto;
        flex-direction: row;
        width: 100%;
        margin: 0;
        padding: 0;
        background: none;
        overflow: visible;

        &.chart {
          flex: 0 0 160px;
        }
      }

      &:last-of-type {
        vertical-align: baseline;
      }
    }
  }

  .form {
    width: 100%;

    .help-text {
      text-align: center;
    }
  }

  .chart__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: calc($baseline / 6) $baseline_half 0;

    .chart__donut-segment {
      cursor: auto;
    }
  }

  .chart__container > div,
  .#{$module}__chart-legend {
    float: left;
  }

  .chart__container > div {
    flex: 1 0 150px;
    height: $baseline * 6.1;
    overflow: hidden;
  }

  .#{$module}__chart-legend {
    flex: 1 0 65%;
    margin-top: $baseline * 0.75;

    .disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .#{$module}__chart-legend {
    padding: calc($baseline / 6) $baseline_half 0;
    color: $white;
    list-style-type: none;

    i {
      margin-right: $baseline_half;
    }

    .data__count {
      float: right;
    }
  }

  .#{$module}__actions {
    @extend .bulk-case-actions;

    padding: $baseline;
  }

  .#{$module}__registry-container {
    height: 100vh;
    width: 100%;
  }

  .action-group {
    justify-content: end;

    > li {
      border-top: 1px solid $white400;
    }

    .#{$module}-actions .#{$module}__actions {
      min-height: 200px;
    }

    &.action-group--dark-bg > li p {
      border-top: 0;
    }
  }

  .bulk-case-actions__status {
    .material-icons {
      font-size: 1.2rem;
      padding: {
        right: 0;
        left: 0;
      }
    }
  }
}
