@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';

.notificationText {
  margin-bottom: $baseline_half;
}

.indentedSection {
  max-width: 29rem;
  margin-top: $baseline_sm;
  list-style: none;
  line-height: $baseline_lg;

  > li {
    margin-bottom: $baseline_sm;
    margin-left: 10px;
  }

  .daysInput {
    width: 9rem;
  }
}
