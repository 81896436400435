// -------------------------------------
//   Headers
//   -------
//
//   Typically this will style <header> elements, but it can be any element with
//   the appropriate classname.
// -------------------------------------

.header {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  padding: $baseline * 0.75;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }

  > * {
    flex: 0 1 auto;
    margin-left: $baseline;

    &:first-child {
      margin-left: 0;
    }

    &.header__spacer {
      flex: 1 1 auto;
    }
  }

  h2 {
    text-transform: uppercase;
  }
}

.header--inverted {
  background-color: $dark800;
  color: $white;
}
