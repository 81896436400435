$module: 'case_view';

.#{$module} {
  @extend %page-layout;
}

@media screen {
  .#{$module} {
    .#{$module}__body {
      section {
        position: relative;
        flex: 0 0 60%;
        clear: both;
        background: $white;

        textarea {
          &.flat {
            padding: $baseline_half;
            border: none;
            border-radius: $border-radius * 2;
            background: $white300;
            font-size: $base-font-size;
          }
        }

        .actions-taken,
        > section {
          margin: {
            right: $baseline;
            left: $baseline;
          }
        }

        .case-event {
          // hide border for CaseEvents created after resolution time
          &:not(.case-event--after-resolution) {
            border-left: 2px solid mix($white, $white400, 25%);
          }

          > div {
            position: relative;
            padding: $baseline_half 0 $baseline_half $baseline;

            &:before {
              display: inline-block;
              position: absolute;
              left: -$baseline * 0.55;
              width: 26px;
              height: 26px;
            }
          }

          &.case-event--origin {
            border-left: none !important;

            > div {
              &:before {
                left: -12px;
                content: url(/assets/img/caseEventOrigin.svg);
              }
            }
          }

          &.case-event--pending {
            .case-event__header,
            .case-event__content {
              padding-top: 0;

              .template {
                margin-top: 0;
              }

              .select-files {
                margin-bottom: $baseline_half;
              }

              .case-event__permission-check {
                display: inline-block;
                padding: $baseline_sm * 0.5 0 $baseline 0;
              }
            }

            div.case-event__header {
              &.dropdown-header .rw-dropdown-list {
                /* remove important after new case refactor is completed */
                margin-bottom: 0 !important;
              }
              &:before {
                content: url(/assets/img/caseEventPending.svg);
              }
            }
          }

          &.case-event--not-violation > div {
            padding-top: 0;

            &:before {
              content: url(/assets/img/caseEventNotViolation.svg);
            }
          }

          &.case-event--violation > div {
            padding-top: 0;

            &:before {
              content: url(/assets/img/caseEventViolation.svg);
            }
          }

          &.case-event--analyticalAssessment > div,
          &.case-event--draftNotes > div,
          &.case-event--draftAssessment > div,
          &.case-event--default > div,
          &.case-event--attachment > div,
          &.case-event--email > div {
            // hide default svg for CaseEvents created after resolution time
            &:before:not(.case-event--after-resolution) {
              content: url(/assets/img/caseEventDefault.svg);
            }
          }

          &.case-event--attachment li {
            cursor: pointer;
          }

          &.confirm-delete {
            margin-left: -$baseline * 0.82;
            padding: 0 0 0 $baseline * 0.82;
            transition: background 300ms linear;
            border: none;
            border-radius: $border-radius + 2;
            background: $danger750;
            opacity: 1;

            > div {
              border-left: 2px solid transparent;

              &:before {
                content: url(/assets/img/caseEventDelete.svg);
              }
            }

            .case-event__file-listing li {
              margin-right: $baseline_half;
            }
          }

          form {
            margin-top: $baseline_sm;
          }

          .case-event__header {
            a,
            .prot-a {
              display: inline;
              margin-left: $baseline_half;
            }

            p {
              margin-bottom: 0;
              font-size: $base-font-size * 0.9;
              opacity: 0.6;
            }

            div.actions {
              display: inline;
              padding-right: $baseline_half;
              float: right;
            }
          }

          input,
          .input-container,
          .rw-dropdown-list {
            width: 250px;
          }

          .rw-dropdown-list {
            margin-bottom: $baseline_half;
            margin-right: $baseline_half;
          }

          input {
            height: calc(2.125rem + 2px);
          }

          .input-group-date-picker {
            display: inline-block;

            &.labeled-date-picker {
              input {
                height: 100%;
                padding: $baseline $baseline_half $baseline_half $baseline_half;
              }
            }

            .rw-i-calendar {
              line-height: initial;
            }
          }
        }

        .case-event__file-listing {
          margin-bottom: $baseline_half;
          padding: 0;

          li {
            padding: $baseline_sm $baseline_half;
            transition: margin 300ms linear;
            border-radius: $border-radius * 2;
            background: $white300;
            list-style: none;

            &:before {
              margin-right: $baseline_half;
              font-family: 'Material Icons';
              content: '\e226';
              vertical-align: bottom;
            }

            &.invalid {
              border: 1px solid red;
            }

            .prot-a {
              float: right;
            }
          }
        }

        .case-event--email {
          .case-event__header {
            padding-bottom: $baseline_half;
          }

          .case-event__content {
            padding: $baseline * 0.75;
            border: 1px solid $light300;
            border-radius: $border-radius * 4;

            .emailContent {
              padding-top: $baseline;
              color: rgba($dark, 0.8);
            }

            &.condensed {
              width: 100%;

              header {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }

              .emailContent {
                padding-top: 0;
                color: $dark550;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }

            i {
              float: right;
              color: $primary200;
            }
          }
        }

        section {
          position: relative;
          left: 0;

          .note__detail-edit {
            margin-top: 0;

            .note {
              display: inline-block;
              width: 80%;
            }
          }

          .template {
            margin-top: $baseline;
            border: 1px solid $white400;
            border-radius: $border-radius * 4;

            &.confirm-delete {
              border-radius: $border-radius * 4;
              transition: background 300ms linear;
              background: $danger750;
              opacity: 1;

              input {
                background: $white;
              }

              .template__header {
                border-color: $white;
              }
            }

            .template__header {
              min-height: $baseline * 2;
              border-bottom: 1px solid $white400;
              font-size: $base-font-size * 0.9;

              ul {
                padding: $baseline_half $baseline * 0.8;
                list-style-type: none;
                text-align: right;

                .template-actionTitle {
                  float: left;
                  font-size: $base-font-size;
                  font-weight: 600;
                }

                &.empty {
                  padding: $baseline;
                }

                li {
                  display: inline;
                  color: $primary;
                  cursor: pointer;
                  margin: 0 0 0 $baseline_half;

                  &.muted {
                    pointer-events: none;
                    cursor: not-allowed;
                  }
                }
              }
            }

            .template__content {
              padding: $baseline * 0.8;

              textarea {
                font-size: $small;
              } // overrides component's original style

              form {
                div {
                  flex-direction: row;

                  div {
                    border-radius: $border-radius * 2;
                  }
                }
              } // overrides component's original style

              input,
              .input-container {
                width: 100%;
                height: $baseline * 2;
                text-transform: none;
              }

              .input-container {
                margin-top: $baseline_half;
                margin-bottom: $baseline_half;

                &.flat {
                  background: $white300;
                  border-radius: $border-radius * 2;
                }

                &.multiselect__to {
                  width: inherit;
                  height: 100%;
                  min-height: $baseline * 2;
                  margin-top: $baseline-half;
                  padding-bottom: $baseline-sm;

                  .rw-multiselect {
                    border: none;
                    background: transparent;

                    .rw-widget-container {
                      ul,
                      input {
                        width: auto;
                        margin-top: 0;
                      }

                      div {
                        height: 100%;
                        padding: $baseline_half * 0.9 $baseline_sm $baseline_sm
                          $baseline_sm;

                        input {
                          height: $baseline;
                          margin-top: $baseline_sm;
                        }

                        ul.rw-multiselect-taglist {
                          padding-top: $baseline_sm;
                          float: none;

                          .rw-multiselect-tag > div {
                            padding-top: 0;
                            padding-left: 0;
                          }

                          .rw-multiselect-tag > span {
                            text-transform: none;
                          }
                        }
                      }

                      .rw-input-reset {
                        margin: 0;
                        padding: $baseline_sm 0 0 $baseline_sm;
                      }
                    }

                    .rw-popup-container {
                      display: none !important;
                    }

                    .rw-widget-input {
                      .rw-select {
                        display: none !important;
                      }
                    }
                  }
                }

                label {
                  line-height: initial;
                }
              }

              .rw-state-disabled {
                background: $gray200 !important;
                color: black !important;

                span {
                  max-width: 100%;
                  margin-left: 0;
                }

                div {
                  display: none;
                }
              }

              .template-lastEdit {
                margin-top: $baseline_half;
                font-size: $base-font-size * 0.9;
                opacity: 0.6;
              }

              input::placeholder {
                color: $gray500;
              }

              input {
                font-size: $base-font-size * 0.9;
              }
            }
          }

          .section-header {
            width: 100%;
            height: $baseline * 1.5;

            h3 {
              float: left;
            }
          }

          h3 {
            i {
              margin-left: $baseline_half * 0.25;
              color: $white500;
              font-size: 18px;

              &:hover {
                color: $primary150;
              }

              &.active {
                color: $primary;
              }
            }
          }

          &:last-child {
            border-bottom: 0;
          }
        }
      }

      .accesses__table {
        margin-top: 0;
        padding: 0;
        overflow: none;

        .public_fixedDataTable_bodyRow:hover,
        .fixedDataTableCellGroupLayout_cellGroup:hover {
          background: $white250 !important;
          cursor: default;
        }

        .fixedDataTableLayout_main {
          background: $white;
        }

        .fixedDataTableLayout_header {
          .registry__table-header {
            width: 100%;
          }

          .registry__table-header--active {
            background-color: $white500;
          }

          .material-icons {
            position: relative;
            top: -1px;
            left: 0.5em;
            font-size: 1.1rem;
          }
        }

        .highlight-UOA.public_fixedDataTableRow_main {
          position: static;
          border-left: $data-table-cell-highlight-width solid;
          border-left-color: $light200;
        }

        .no-wrap {
          overflow: hidden;

          div {
            display: block;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }

      aside {
        display: flex;
        flex: 0 0 40%;
        flex-direction: column;
        padding: 0;
        overflow-x: hidden;

        &.GRCCreate {
          pointer-events: none;
          opacity: 0.3;
        }

        section {
          display: flex;
          left: 0;
          flex: 1;
          flex-basis: auto;
          flex-direction: column;
          margin: 0;
          padding: 0;
          border: 0;
          background: none;

          .expando.authorizations {
            flex-wrap: wrap;
            align-content: start;
          }

          section {
            display: flex;
            left: 0;
            flex-direction: row;
            width: 100%;
            height: auto;
            min-height: 0 !important;
            margin: 0;
            padding: $baseline;
            border-bottom: 1px solid $white400;
            background: none;
            overflow: visible;

            .item {
              flex: 1 0 0;
              margin-left: $baseline;

              h5 {
                float: left;
              }

              > span {
                float: left;
                clear: both;
              }

              ul {
                clear: both;
                overflow: visible;

                li {
                  margin-bottom: 0;
                }
              }

              .header-link {
                margin: $h5-margin-top 0 $h5-margin-bottom;
                float: right;
                font-size: $h5-font-size;
                font-weight: bold;
                line-height: $h5-line-height;
                text-transform: $h5-text-transform;
              }

              &:first-child {
                margin-left: 0;
              }
            }

            &.authorizations,
            &.permissions {
              .item {
                flex: 0 0 100%;
                margin: 0;

                h5,
                .header-link {
                  width: 50%;
                  padding-left: $baseline_half;
                  float: left;

                  &:first-child {
                    padding-left: 0;
                  }
                }

                ul {
                  padding: 0;
                  clear: both;
                  list-style: none;

                  li {
                    display: flex;
                    flex-direction: row;
                    padding: ($baseline_half * 0.5) 0;
                    border-bottom: 1px solid $white400;

                    &:last-child {
                      padding-bottom: 0;
                      border-bottom: 0;
                    }

                    &.disabled {
                      font-style: italic;
                      opacity: 0.5;
                    }
                  }

                  a {
                    flex: 1 0 0;
                    margin-right: $baseline_half;
                  }

                  .status {
                    flex: 1 0 0;
                    margin-left: $baseline_half;

                    &.inactive {
                      color: $danger;
                      font-style: italic;
                    }
                  }
                }
              }
            }

            &.bundles .item ul {
              padding: 0;
              list-style: none;

              li {
                padding: ($baseline_half * 0.5) 0;
                border-bottom: 1px solid $white400;

                &:last-child {
                  padding-bottom: 0;
                  border-bottom: 0;
                }

                .float-right {
                  margin-left: $baseline * 2;
                }
              }
            }

            &.bundle-actions {
              display: block;

              form {
                padding: 0;
                padding-bottom: $baseline;

                > ul > li {
                  margin-bottom: $baseline_half;
                }
              }
            }
          }

          form {
            padding: $baseline;

            section {
              padding: 0;
              border: 0;
            }

            .expando {
              margin-top: $baseline;
            }

            .expando-hidden {
              margin: 0;
            }

            ul {
              margin-bottom: 0;
              float: none;
              border: 0;

              li {
                margin-bottom: 0;
                border: 0;
              }
            }

            textarea {
              margin-bottom: $baseline;
              background: $white;
              color: $dark;
            }

            .small {
              padding-top: $baseline;
            }
          }

          .small {
            display: block;
            margin-bottom: 0;
            padding: 0 $baseline 0 $baseline;
            color: $gray400;
          }
        }

        h4 {
          margin-bottom: $baseline_half * 0.5;
        }

        .log {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          ul {
            padding: 0;
            list-style: none;

            li {
              margin-bottom: $baseline * 0.75;
              clear: both;
              border: 1px solid $white450;
              background: $white150;
              overflow: auto;

              &:last-child {
                margin-bottom: 0;
              }

              ul {
                margin: 0;
                padding: 0;
                border: 0;
                background: none;

                li {
                  margin: 0;
                  border: 0;
                }
              }

              .log__item {
                border: 0;
              }

              .log__item-header {
                position: relative;
                padding: $baseline_half;
                font-size: $small;

                .prot-a {
                  position: absolute;
                  top: $baseline_half;
                  right: $baseline_half;
                  border: 0;
                  cursor: pointer;
                }

                > div:first-child {
                  overflow: hidden;
                  word-break: break-word;
                }
              }

              .log__item-header > .timestamp {
                color: $gray400;
                font-size: $xSmall;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .log__item-content {
                border-top: 1px solid $white450;
                background: $white300;

                p {
                  padding: $baseline_half;
                  font-size: $small;
                }

                p:last-child {
                  margin-bottom: 0;
                }
              }

              .log__item-footer {
                padding: $baseline_half;
                border-top: 1px solid $white450;
                font-size: $small;

                > div {
                  width: 30%;
                  padding-left: 0;
                  float: right;
                  color: $gray400;
                  text-align: right;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                a {
                  border: 0;
                }
              }
            }

            .log__add {
              border-top: 1px solid $white450;
              background: $white;

              form {
                padding: 0;
              }

              ul {
                border: 0;
              }

              textarea {
                margin-bottom: 0;
                transition: height 250ms, padding 250ms;
                border: 0;
                overflow-x: hidden;
              }

              .comment-footer {
                padding: $baseline_half;
                transition: height 250ms, padding 250ms;
                border: 0;
                border-top: 1px solid $white450;
                background: $white;

                .disabled {
                  cursor: not-allowed;
                  opacity: 0.5;
                }

                span {
                  padding-left: $baseline_half;
                  border: 0;
                }

                div {
                  float: right;
                }
              }

              .inactive {
                li {
                  textarea {
                    height: $baseline * 2.25;
                    overflow: hidden;
                  }
                }

                .comment-footer {
                  height: 0;
                  padding: 0;
                  border: 0;
                }
              }
            }
          }
        }

        .delete {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          height: 100%;
          border-bottom: 0;
          overflow: auto;

          ul {
            margin-bottom: $baseline;
          }
        }

        // skip the disappearing animation which would trigger when displaying a new case
        .sysAlert.inactive {
          opacity: 0;
          animation: none;
        }

        footer {
          position: absolute;
          bottom: 0;
          width: calc(100% - 50px);
        }
      }
    }
  }
}

.#{$module}__aside__temp_permission {
  margin-top: $baseline_half;

  section:first-child > .item:first-child {
    padding-top: $baseline_sm * 2.5;
  }
}

// wrap inputs to next line if the width is too thin
@media screen and (max-width: 1380px) {
  .#{$module} .#{$module}__body aside .#{$module}__aside__temp_permission {
    section {
      flex-wrap: wrap;

      .item {
        flex-basis: 100%;
        margin-left: 0;

        &:first-child {
          margin-bottom: $baseline_half;
        }
      }
    }
  }
}

@media print {
  .print-only p {
    position: relative;
    top: calc($baseline_half / 2) * -1;
    font-size: 14px;
  }

  a {
    color: black;

    &.print-only__hidden {
      display: none;
    }
  }

  .case_view {
    width: 860px;
    min-width: auto !important;
    background: none;
    color: $black;
  }

  // removes Pendo badge from Case print view
  ._pendo-badge {
    opacity: 0;
  }

  .titleCard {
    .titleCard--id,
    li {
      display: none !important;
    }
  }

  .caseInformation {
    padding: 0 !important;
  }

  h3 {
    font-size: 18px;
  }

  .email-minimized {
    display: none;
  }

  section section,
  section .print-only {
    margin-bottom: $baseline;
    clear: both;

    &:nth-child(5),
    &:nth-child(7),
    &:nth-child(9),
    &:nth-child(11) {
      width: 100%;
    }

    img {
      width: 120px;
      margin-bottom: 0;
    }

    h3 {
      margin-bottom: $baseline_half;

      a {
        display: none;
      }
    }

    h4 {
      margin-bottom: $baseline_half;
      text-transform: uppercase;
    }

    &:last-child {
      border-bottom: 0;
    }

    .case-event.case-event--pending {
      display: none;
      padding-top: 0;
    }

    .case-event-print {
      &:last-child {
        border: none;
      }
    }
  }

  .printOverview {
    width: 100%;
    padding-bottom: 0;
    overflow: auto;

    div {
      width: 25%;
      float: left;

      p:last-child {
        margin-bottom: 0;
      }

      &:last-child {
        width: 50%;
      }
    }

    dl {
      width: 25%;
      float: left;

      &:last-child {
        width: 50%;
      }

      dd {
        font-size: 15px;
      }
    }
  }

  hr {
    clear: both;
    border-top: 1px solid $gray;
  }

  .navbar,
  .subnav,
  .caseView__aside,
  .format_bar {
    display: none !important;
  }

  .info-card {
    display: none;
  }

  .info-card-print {
    margin: 0;
  }
}
