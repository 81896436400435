.padd-all {
  padding: $baseline;
}

.padd-top {
  padding-top: $baseline;
}

.padd-right {
  padding-right: $baseline;
}

.padd-bottom {
  padding-bottom: $baseline;
}

.padd-left {
  padding-left: $baseline;
}
