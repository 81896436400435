@import '../../../scss/base/colors';
@import '../../../scss/base/variables';

.button {
  max-width: fit-content;
  height: $baseline * 1.4;
  border-radius: $border-radius * 2;
  border: 1.5px solid $primary;
  background: $primary;
  box-sizing: content-box;
  color: white;
  cursor: pointer;
  margin-right: $baseline_half;

  .buttonInner {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 $baseline_half 0 $baseline_sm * 1.2;
    white-space: nowrap;

    p {
      background: inherit;
      padding-left: $baseline_sm;
      font-size: $base-font-size;
      margin: 0;
    }

    i {
      font-size: 17.5px;
      color: inherit;
    }

    transition: all ease-in-out 150ms;
  }
}

.rememberContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.rememberButton {
  display: flex;
  cursor: pointer;
  border-radius: $border-radius * 0.7;
  background: $primary;
  color: white;
  font-weight: bold;
  margin: $baseline_half;
  padding: $baseline_sm;
  justify-content: center;
  transition: all ease-in-out 150ms;
}

.border {
  display: flex;
  margin: 0 $baseline_half;
  border-top: 0.5px solid $light200;
  opacity: 0.5;
}

.dropdownRow {
  position: absolute;
  min-width: 300px;
  max-width: fit-content;
  display: flex;
  flex-direction: column;
  list-style: none;
  background: white;
  border-radius: $border-radius * 0.7;
  color: $black400;
  padding: 0;
  z-index: 5;

  :global(.rah-static) {
    overflow-y: auto;
    max-height: calc(100vh - #{$subnav-height * 2});
  }

  :global(.rah-static--height-auto) {
    box-shadow: 0px 2px 6px 1px $gray150;
    border-radius: 0 0 4px 4px;
  }

  .rememberFiltersOpen > div[aria-hidden='false'] {
    padding-bottom: 65px;
    max-height: calc(100vh - 65px - #{$subnav-height * 2});
  }

  li {
    display: flex;
    margin: 0;
    padding: $baseline_half;

    .thisGroupOnly {
      visibility: hidden;

      &:hover {
        background: none;
      }
    }

    &:hover {
      .thisGroupOnly {
        visibility: visible;
      }
    }
  }

  .spacer {
    flex: 1;
  }
}

.checkboxLabel {
  margin-left: $baseline_sm;
}

.checkboxWrapper {
  padding: $baseline_half;
  padding-right: 0;
}

.allSelected {
  margin-left: $baseline_sm;
  opacity: 0.7;
  font-weight: 700;
}

// prevents from global styles overrides
.wrapper {
  flex: 0 1 auto;

  ul li {
    flex-direction: row;
    height: inherit;
  }

  ul li a {
    height: inherit;
  }
}
