@import '../../../scss/base/variables';

.field {
  list-style: none;
  break-inside: avoid;

  > h5,
  > div,
  > em {
    margin: 0;
    line-height: normal;
  }

  > div,
  > p,
  > em {
    display: block;
    margin-top: 3px;
  }

  &.rightAlign {
    position: absolute;
    right: 0;
  }

  &.defaultWidth {
    width: 16%;
  }

  &.inherit {
    width: inherit;
    min-width: 16%;
  }

  &.wider1 {
    width: 20%;
  }

  &.wider2 {
    width: 25%;
  }

  &.wider3 {
    width: 46%;
  }

  &.auto {
    width: auto;
  }

  .spaceLeft {
    margin-left: $baseline_sm;
  }

  .values {
    display: flex;
  }

  p {
    margin-bottom: 0;
  }
}
