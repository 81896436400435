@import '../../../scss/base/variables';
@import '../../../scss/base/colors';
@import '../../../scss/base/mixins';
@import '../../../scss/base/zindex';

.container {
  position: relative;
  z-index: index($z_forms, inputs);
  width: 100%;
  border: none;
  background: $white;

  .input {
    width: 100%;
    padding: $input-default-padding;
    border: none;
    outline: 0;
    background: transparent;
    font-size: $base-font-size;
    transition: all 100ms ease-in-out;
  }

  .label {
    padding-top: 7px;
    padding-bottom: 0;
    margin-top: -10px;
    line-height: 1;
    opacity: 0;
    transition: margin-top 100ms ease-in-out;
  }

  @include placeholder {
    @include placeholder-visible;

    color: $gray300;
    opacity: 1;
  }

  &.fontXs {
    .input {
      font-size: $xSmall;
    }
  }

  &.fontSm {
    .input {
      font-size: $small;
    }
  }

  &.coloredFont {
    .input {
      color: $dark;
    }
  }

  &.paddingSm {
    .input {
      padding: $input-small-form-padding;
    }
  }

  &.paddingMd {
    .input {
      padding: $baseline_half;
    }
  }

  &.bordered {
    border: 1px solid $white500;
  }

  &.smBorderRadius {
    border-radius: $border-radius * 1.5;
  }

  &.mdBorderRadius {
    border-radius: $border-radius * 2;
  }

  &.darkBackground {
    background: $white300;

    @include placeholder {
      color: $gray500;
    }
  }

  &.disabled {
    border-color: $white500;
    background: $white300;
    cursor: not-allowed;
  }

  &.marginMd {
    margin-bottom: $baseline_half;
  }

  &.marginLg {
    margin-bottom: $baseline * 0.6;
  }

  &.readOnly {
    border: none;
    background: $white200;
    color: $gray500;
  }

  &.disabled,
  &.readOnly {
    .input {
      cursor: not-allowed;
    }

    .label {
      z-index: index($z_forms, label);
      color: $gray500;
      cursor: not-allowed;
    }
  }

  &.showLabel {
    @include placeholder {
      @include placeholder-visible(false);
    }

    .input {
      padding: $input-inline-label-padding;

      &.paddingMd {
        padding: $baseline * 1.1 $baseline_half $baseline_half $baseline_half;
      }
    }

    .label {
      margin-top: 0;
      opacity: 1;
    }
  }

  &.error {
    border: 1px solid $danger;

    .label {
      color: $danger;
    }

    @include placeholder {
      color: $danger;
    }
  }
}
