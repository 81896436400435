.unreconciled-drugs-container {
  padding-bottom: 18px;

  &:not(:last-child) {
    border-bottom: 1px solid $white500;
  }
}

@media print {
  .unaccounted-drugs-container {
    ul > li {
      color: $black;

      strong {
        color: $black;
      }
    }
  }
}
