@import '../../../../scss/base/colors';
@import '../../../../scss/base/variables';

.table {
  flex: 1;
}

.groupHeader {
  display: flex;
  align-items: center;
  height: 50px;
  margin-right: 0;
  margin-left: 0;
  background-color: $dark600;
  color: white;
}

.iconCheck {
  color: $warning;
}

.iconError {
  color: $danger;
}

.divider {
  position: absolute;
  top: 0;
  bottom: 3px;
  left: 50%;
  border-right: 1px dashed $gray200;
  z-index: 1;
}
