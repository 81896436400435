@import '../../../scss/base/colors';

@media print {
  .printHeaderContainer {
    background-color: $white; // White background for IE11
    height: 50px;

    .logo {
      width: 125px;
    }
  }
}
