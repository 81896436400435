@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.main {
  display: flex;
}

.auditLogWrapper {
  display: flex;
  position: relative;
  flex: 1 1;
  flex-flow: row wrap;
  overflow: hidden;
}

.content {
  overflow-y: auto;
  width: 100%;
  // Allow space for the whole screen except for the main nav and action navs
  height: calc(100vh - ((#{$subnav-height} * 2)));
}

.body {
  margin: $baseline;
  background: $white;
  height: 50%;
}

.exportMenuWrapper {
  background-color: $white;
  right: 2rem;
  position: absolute;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  width: 240px;

  ul {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    margin: 0;

    li {
      flex: 1;
      padding: $baseline_sm $baseline_half;
      font-size: $xSmall;
      margin: 0;

      span {
        display: inline-block;
        width: 100%;
      }

      &:hover {
        background: $secondary;
        color: $white;
        cursor: pointer;
      }
    }
  }
}
