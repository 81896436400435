.incident-headline-container {
  padding-top: 18px;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    border-top: 1px solid $white500;
  }

  .incident-label {
    padding: 0;
    text-align: left;
    line-height: initial;
    font-size: $base-font-size;
    color: $dark;
    font-weight: 500;
    margin: 0;
  }

  .incident-reason-container {
    margin-top: 18px;

    .content {
      margin: 0;
      font-size: $small;
      line-height: initial;
    }

    .reason-notation {
      display: flex;
      color: $gray500;
      font-size: $small;

      .reason__numbers {
        margin-right: 5px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
