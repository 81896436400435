html,
body {
  height: 100%;
  overflow-y: hidden;
}

body {
  background-color: $white300;
  color: $black400;
  min-width: calc(#{$min-width} - #{$baseline * 3});
  margin: 0;
}

a {
  color: $primary;
  outline: 0;
  outline: none;
  text-decoration: none;

  .link-bold {
    font-weight: 700;
    color: $secondary;
  }

  &:hover {
    color: $primary500;
    cursor: pointer; // React wraps everything in Spans which changes the cursor to Text

    i {
      cursor: pointer;
    }
  }

  &:focus {
    outline: 0;
  }
}
