@import '../../../../scss/base/colors';
@import '../../../../scss/base/variables';

.tablePanel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: $subnav-height;
  height: calc(100vh - #{$subnav_height * 2});
}

.tableArea {
  padding: $baseline;
  overflow: auto;

  i:global(.icon-check) {
    color: $success;
  }

  i:global(.icon-close) {
    color: $danger;
  }
}

.table {
  border-spacing: 0;

  th,
  td {
    padding: $data-table-cell-padding;
  }

  thead tr {
    background-color: $white450;
  }

  tbody tr {
    cursor: pointer;

    &:nth-of-type(2n) {
      background-color: $white200;
    }
    &:nth-of-type(2n + 1) {
      background-color: $white;
    }

    &:hover {
      background-color: $white400;
    }

    &.selectedRow {
      background-color: $secondary;

      td,
      i {
        color: $white;
      }
    }
  }
}
