@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';

.disabledLink {
  pointer-events: none;
  cursor: not-allowed;
}

.caseIntegrationSelection {
  padding: $baseline * 0.8;
  padding-bottom: 0;
}

.confirmDelete {
  background: $danger750 !important;
  border-radius: $border-radius * 4 !important;
  opacity: 1;
  transition: background 300ms linear;
  border: none;

  input {
    background: $white;
  }
}

.caseIntegrationTable {
  .caseIntegrationRow,
  .caseIntegrationData,
  .caseIntegrationHeader,
  .caseIntegrationBody {
    border-collapse: collapse;
    border: none;
  }
  .caseIntegrationBody,
  .caseIntegrationHeader {
    display: flex;
    flex-direction: column;
    .caseIntegrationRow {
      display: flex;
      flex-direction: row;
      flex-basis: $baseline + $baseline_sm;
      .caseIntegrationData,
      .caseIntegrationHead {
        font-size: 100%;
        display: flex;
        padding: 0;
        align-items: center;
        min-width: 305px;
        // align checkbox with other icons
        :global(.checkbox-base) span {
          padding: 0;
        }
      }
    }
    .offsetHeader {
      margin-left: $baseline + $baseline_sm;
    }
  }

  .bold {
    text-transform: none;
    font-weight: bold;
  }
}

.caseIntegrationIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  i {
    min-width: $baseline;
    margin-right: $baseline_sm;
    color: #9f9da7;
  }
  .alignText {
    margin-bottom: 0;
  }
}
