.reason-stats-container {
  display: flex;
  flex-direction: column;

  .reason-stats-title-and-filter {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .grouped-by-and-dropdown-container {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      .grouped-by {
        font-size: $small;
        margin-right: 15px;

        span {
          margin: 0 5px;
          vertical-align: middle;
        }

        .material-icons {
          &.icon-group,
          &.icon-help {
            display: inline;
            font-size: 18px;
          }

          &.icon-help {
            cursor: pointer;
          }
        }
      }

      .rw-widget {
        border-radius: 6px;
        background-color: #f9f9fb;
        margin-right: 0 !important;

        &.rw-state-disabled {
          padding: 0;
        }

        .peer-group {
          .rw-input {
            cursor: not-allowed;
            text-align: center;
            padding: 0;
            width: 250px;
          }
          .rw-select {
            display: none;
          }
        }
      }
    }
  }
  .reason-stats-table-label {
    font-size: $base-font-size;
  }

  .reason-stats-peer-group {
    font-size: $xSmall;
    color: $dark250;
    margin-bottom: 18px;
  }

  .reason-stats-table {
    display: flex;
    flex-direction: column;

    thead {
      display: flex;
      flex-direction: column;
      background-color: $white300;

      tr {
        display: flex;
        background-color: transparent;
        align-items: center;

        th {
          color: $dark;
          font-size: $xSmall;
          flex: 0 0 10%;

          &:first-child {
            flex: 0 0 60%;
          }

          &:nth-child(2),
          &:nth-child(3) {
            text-align: end;
          }

          &:last-child {
            flex: 0 0 20%;
            text-align: end;
          }
        }
      }
    }

    tbody {
      display: flex;
      flex-direction: column;

      tr {
        display: flex;
        align-items: center;

        &:nth-child(even) {
          background-color: #f9f9fb;
        }

        td {
          color: $dark;
          font-size: $xSmall;
          text-transform: none;
          font-weight: 400;
          flex: 0 0 10%;

          &:first-child {
            flex: 0 0 60%;
          }

          &:nth-child(2),
          &:nth-child(3) {
            text-align: end;
          }

          &:last-child {
            flex: 0 0 20%;
            text-align: end;
          }
        }
      }
    }
  }

  .reason-stats-table-button {
    background-color: transparent;
    color: $primary600;
    font-size: $xSmall;
    padding: 15px 25px 0 25px;
  }
}
