@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';

.columns {
  display: flex;
  flex: 1;
  min-height: 0;

  @media print {
    display: block;
  }
}
