.assessment-reasons-unaccounted {
  .assessment-reasons-unaccounted-total-label {
    font-size: $base-font-size;
    color: $dark;
    font-weight: 500;
    margin: 0;
  }

  ul {
    margin-top: 10px;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 0;
      font-size: $small;
    }
  }
}
