@import '../../../scss/base/colors';
@import '../../../scss/base/variables';

.header {
  display: flex;

  > i {
    display: flex;
    background: $white;
    align-items: center;
    padding-right: $baseline_half;
    cursor: pointer;
  }
}
