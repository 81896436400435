@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';
@import '../../../../scss/base/mixins';

.form {
  padding: $baseline;

  :global(.rw-multiselect) {
    margin-bottom: $baseline_half;
  }

  :global(.rw-widget-input) {
    padding: $baseline_sm $baseline_half $baseline_sm 0;

    :global(.rw-multiselect-taglist) {
      width: auto;
    }

    @include placeholder {
      color: $gray300 !important;
    }

    input {
      font-size: $base-font-size !important;
    }
  }

  .checkboxWrapper {
    margin-bottom: $baseline_half;
  }

  .dropdownWrapper {
    margin-bottom: $baseline_half;
  }

  .note {
    margin-top: $baseline_half;
    font-size: $xSmall;
    opacity: 0.75;
  }

  .helpText {
    padding-bottom: $baseline_half;
    font-size: $xSmall;
    opacity: 0.75;
  }
}

.failure {
  color: $danger;
}

.actions {
  padding: $baseline;

  hr {
    margin: -$baseline 0 $baseline;
    border-color: $gray;
  }

  > * {
    margin-bottom: $baseline_half;
  }
}
