// Component for managing the registry and record styles
// --------------------------------------------------------------

// --------------------------------------------------
// Registry styles
// --------------------------------------------------

$module: registry;

.#{$module} {
  // local variables
  $transition-duration: 100ms;
  $transition-easing: ease-in-out;
  $transition-timing: #{$transition-duration} #{$transition-easing};
  $transition-with-delay: #{$transition-timing} #{$transition-duration};

  .#{$module}__search .input-group {
    .input-addon {
      border-right: 0;
    }

    .input-container {
      input {
        padding: $baseline * 1.1 $baseline_half $baseline * 0.475;
      }
    }

    .input-group--addon {
      width: $baseline * 2;
      padding: $baseline * 0.6 $baseline_half;
      transition: width $transition-with-delay;
      white-space: nowrap;

      .help-text {
        margin-left: 5px;
        padding-top: 3px;
        transition: opacity $transition-timing;
        opacity: 0;
      }

      .icon-search {
        cursor: pointer;
      }

      &.input-group--addon__with-help-text {
        width: $baseline * 9;
        transition: width $transition-timing;

        .help-text {
          transition: opacity $transition-with-delay;
          opacity: 1;
        }
      }
    }
  }

  .#{$module}__search {
    margin: {
      top: $baseline;
      bottom: $baseline;
    }
  }

  .#{$module}__search-actions,
  .#{$module}__search-results {
    margin-bottom: $baseline;
  }

  .#{$module}__search-results {
    @include neg_margin(0.8);
  }

  .#{$module}__select-toggle {
    margin-top: $baseline * 0.25;
  }

  .#{$module}__search {
    margin: {
      top: $baseline;
      bottom: $baseline;
    }
  }

  .#{$module}__search-actions,
  .#{$module}__search-results {
    margin-bottom: $baseline;
  }

  .#{$module}__search-results {
    @include neg_margin(0.8);
  }

  .card_holder {
    &.margin_collapse {
      padding-top: 0;
    }
  }

  // =============================================================================
  // Overrides
  // =============================================================================

  //
  // List View: Data Table
  //

  .#{$module}__table {
    overflow: hidden;

    .#{$module}__table-header {
      .material-icons {
        position: relative;
        top: -1px;
        left: 0.5em;
        font-size: 1.1rem;
      }
    }

    .no-wrap {
      overflow: hidden;

      div {
        display: block;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .right-align {
      text-align: right;
    }
  }

  .public_fixedDataTableRow_main {
    background-color: $white;
  }

  .public_fixedDataTable_header,
  .public_fixedDataTable_header .public_fixedDataTableCell_main {
    background-color: $white450;

    .#{$module}__table-header {
      width: 100%;
    }

    .#{$module}__table-header--active {
      background-color: $white500;
    }
  }

  .public_fixedDataTableRow_even {
    background-color: $white200;
  }

  .pandy-container {
    position: relative;
    height: calc($contained-list-height / 1.5);
  }
}

// --------------------------------------------------
// Record styles
// --------------------------------------------------

.record__form {
  padding: 0;

  .row {
    li {
      width: 100%;
    }

    .split-two {
      display: flex;
      width: 49.5%;

      &:first-child {
        margin-right: 1%;
      }

      > div {
        width: 100%;
      }
    }

    .full-width {
      width: 100%;
    }
  }

  .input-fullwidth {
    width: 100%;

    li {
      width: auto;
    }
  }
}

.form__actions {
  width: 100%;

  .button {
    width: auto;
    margin-right: $baseline * 0.75;
  }
}

.record__cell--active {
  background-color: $secondary;
  color: $white;
}

.record__status {
  position: relative;
  top: -2px;
}

.registry--flex {
  display: flex;
  position: relative;
  flex: 1;
  flex-flow: row;
  width: 100%;
  height: calc(100vh - #{$subnav-height});
  overflow: hidden;

  .registry {
    padding: 0 $baseline;
  }

  .loader {
    z-index: index($z_layout, modal);
  }

  .panel__main {
    display: flex;
    position: relative;
    flex: 1 1 100%;
    flex-direction: column;
    height: calc(100vh - #{$subnav-height});
    padding: $baseline 0;
    clear: both;
    overflow: auto;
  }

  .panel__aside {
    .subnav {
      background: transparent;

      ul {
        padding-right: $baseline * 0.75;
      }

      h3 {
        padding-left: 0;
        color: $primary;
        font-size: $h4-font-size;
        font-weight: normal;
      }

      a {
        color: $gray200;

        i {
          font-size: 1.25rem;
        }
      }

      li:last-child {
        margin-right: 0;
      }
    }
  }

  &.with-aside {
    .panel__main {
      flex: 1 1 65%;
    }

    .panel__aside {
      display: flex;
      position: relative;
      flex: 1 1 35%;
      flex-direction: column;
      height: calc(100vh - #{$subnav-height});
      padding: calc(#{$subnav-height} + #{$baseline * 0.5}) $baseline $baseline *
        0.75;
      background-color: $white200;
      overflow: auto;
      overflow-x: hidden;

      &:before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100vh - #{$subnav-height});
        border-left: 1px solid $white400;
        content: ' ';
        z-index: index($z_layout, panelaside);
      }
    }
  }
}
