@import '../../../../scss/base/colors';
@import '../../../../scss/base/variables';

.customerAdministeredAccountsListView {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.mainPanel {
  flex: 1 1 65%;
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: $subnav-height;
  height: calc(100vh - #{$subnav_height * 2});
  clear: both;
  overflow: auto;
}

.withAside {
  flex: 1 1 65%;
  animation: flexMainRight 0.3s 0s both;
}
