@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.filterListWrapper {
  flex: 1 1 auto;
}

.filterBy {
  display: flex;
  > :not(:last-child) {
    margin-right: $baseline_half;
  }
  > :nth-child(2) {
    margin-left: calc($baseline_half/2) * -1;
  }
}

.groupBy {
  display: flex;
  > :not(:last-child) {
    margin-right: $baseline_half;
  }
}

.spacer {
  flex: 1;
}

.list {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  height: $subnav-height;
  margin-top: 0;
  padding: 0;
}

.listItem {
  flex: 0 0 auto;
  align-items: center;
  padding: 0 calc($baseline_half / 4) 0 0;
  list-style: none;
}

.rwDropdownOverrides {
  width: $dropdown-min-width * 1.5;
  border: none;
  border-radius: $border-radius;
  background-color: $primary;
  box-shadow: 0 0 4px 0 rgba($black, 0.27);
  color: $white;

  &:hover {
    background-color: $primary;
  }

  // targets .rw-i, the caret symbol
  span {
    button {
      span {
        font-size: $base-font-size * 1.5;
        right: $baseline_sm;
        color: $white;
      }
    }
  }
}

.rwInput {
  padding-right: $baseline_sm;
  color: $white;
  font-size: $base-font-size; //NOTE: $base-font-size to match /cases filters : $small to match Figma mock
}
