// Padding is applied to the inner elements of an Expando to prevent it from not hiding entirely

.expando {
  max-height: 20rem;
  opacity: 1;
  transition: max-height 250ms, opacity 100ms;

  &.expando-hidden {
    transition: max-height 250ms, opacity 100ms;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }

  > div {
    opacity: inherit;
  }
}
