.source {
  margin-top: -1px;
  margin-left: $baseline_half;
  float: right;
  font-size: $h5-font-size;
  font-weight: 600;
  line-height: $h5-line-height;
  opacity: 0.6;

  &.source-center {
    line-height: inherit;
    margin-top: 0;
  }

  i {
    margin-top: -2px;
    font-size: $small;
    vertical-align: middle;
  }
}

@media print {
  .source {
    float: none;
    margin-left: 0;
  }
}
