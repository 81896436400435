// -------------------------------------
//    Apply a natural box layout model to all elements
// -------------------------------------
*,
*:before,
*:after {
  box-sizing: border-box;
}

// -------------------------------------
//    Remove the blue outline from buttons
// -------------------------------------

* :focus {
  outline: none;
}

// -------------------------------------
//   Images
// -------------------------------------

img {
  vertical-align: middle;
  width: 100%;
  height: auto;
}

// -------------------------------------
//   Extra type tools
// -------------------------------------

.inverse__text {
  color: white !important;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.bold {
  font-weight: 600;
}

// -------------------------------------
//   Make invisible
// -------------------------------------

.hidden {
  display: none !important;
  height: 0;
  visibility: hidden;
}

// -------------------------------------
//   Clearfix
// -------------------------------------

%group {
  // use a placeholder selector so it can be included in other blocks without
  // the overhead of `@extend`ing selectors
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.group {
  @extend %group;
}

.clearfix {
  clear: both;
  display: block;
  height: 0;
}

// -------------------------------------
//   Floats
// -------------------------------------

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

// -------------------------------------
//   Debug
// -------------------------------------

.debug {
  background-color: pink !important;
}

// -------------------------------------
//   Ellipsis
// -------------------------------------

.ellipsis {
  overflow: hidden;
  padding-right: $baseline;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// -------------------------------------
//   Image Replacement
// -------------------------------------

.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    width: 0;
    height: 150%;
  }
}

// -------------------------------------
//   Selection
// -------------------------------------

::selection {
  background: $secondary;
  color: $white;
}

// -------------------------------------
//   Overflow containers
// -------------------------------------

.overflow_auto {
  flex: 1 1 0;
  overflow-y: auto;
  min-height: 0;
}

// -------------------------------------
//   Printing
// -------------------------------------

@media screen {
  .forPrint {
    display: none !important;
  }
}

@media print {
  .forScreen {
    display: none !important;
  }

  .forPrint {
    display: block;
  }

  .__react_component_tooltip {
    display: none !important;
  }

  .Toastify__toast {
    display: none !important;
  }
}

// -------------------------------------
//   Fixed Position Elements
// -------------------------------------

.fixed--lower_right {
  position: fixed !important;
  right: $baseline;
  bottom: $baseline;
  z-index: index(z_utils, element);
}

// -------------------------------------
//   Absolute Position Elements
// -------------------------------------

.abs--top_left {
  left: 0;
  position: absolute !important;
  top: 0;
  z-index: index($z_utils, element);
}

.abs--top_right {
  right: 0;
  position: absolute !important;
  top: 0;
  z-index: index($z_utils, element);
}

.abs--bottom_right {
  right: 0;
  position: absolute !important;
  bottom: 0;
  z-index: index($z_utils, element);
}

.abs--bottom_left {
  left: 0;
  position: absolute !important;
  bottom: 0;
  z-index: index($z_utils, element);
}

// -------------------------------------
//   Text Styles
// -------------------------------------

.text-muted {
  color: $gray400;
}

.text-muted_dark {
  color: rgba($white500, 0.5);
}

.text-danger {
  color: $danger !important;
}

.text-secondary {
  color: $secondary !important;
}

.muted {
  opacity: 0.5;
}

// -------------------------------------
//   No Margin
// -------------------------------------

.no-margin-bot {
  margin-bottom: 0 !important;
}

// -------------------------------------
//   Baseline Checker
// -------------------------------------

body.baseline::before {
  display: block;
  content: '';
  background-image: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.1) 50%);
  background-size: 1px 50px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: index($z_utils, baseline_grid);
}

// -------------------------------------
//   Grid stuff
// -------------------------------------

.container--no-gutter {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.row--no-gutter {
  margin-left: 0;
  margin-right: 0;
}

.col--no-gutter {
  padding-left: 0;
  padding-right: 0;
}

.no-gutter {
  margin-left: 0;
  margin-right: 0;

  [class*='col-'],
  [class^='col-'] {
    padding-left: 0;
    padding-right: 0;
  }
}

// -------------------------------------
//   Rotating Things
// -------------------------------------

.rotate90 {
  transform: rotate(90deg);
}

.rotate270 {
  transform: rotate(270deg);
}

// -------------------------------------
//   React-Router Link
// -------------------------------------

// Used to fully disable a react-router Link component
.disabled-link {
  pointer-events: none;
}

// -------------------------------------
//    Use this class to apply a like styles
// -------------------------------------

.prot-a {
  color: $primary;
  outline: 0;
  outline: none;
  text-decoration: none;

  .link-bold {
    font-weight: 700;
    color: $dark;
  }

  &:hover {
    color: $primary500;
    cursor: pointer; // React wraps everything in Spans which changes the cursor to Text

    i {
      cursor: pointer;
    }
  }

  &:focus {
    outline: 0;
  }
}
