//
// Styles for the contained list component.
//

.list-contained {
  $animation-timing: 250ms ease-in-out;
  $disabled-state: dim #{$animation-timing} both;

  background-color: lighten($gray250, 18%);
  height: $contained-list-height;
  overflow: auto;
  width: 100%;

  &:hover .card:not(:hover):not(.is-selected) {
    animation: $disabled-state;
  }

  .card_holder {
    margin: 0 auto;
    padding-top: $baseline;
    width: 97%; // not a big fan of this, but it works for now

    .card {
      margin: {
        left: 0;
        right: 0;
      }
      transition: $animation-timing;

      &.is-disabled {
        animation: $disabled-state;
      }
    }
  }
}
