@import '../../../scss/base/variables';

.wrapper {
  height: calc(100vh - #{$subnav-height * 2});
  width: 100%;
}

.flex {
  display: flex;
}
