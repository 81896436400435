@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.aside {
  position: relative;
  z-index: 8;
  background: $light300;

  &.right {
    width: $aside-panel-width;
    border-left: 1px solid $white350;

    &.open {
      animation: slideInRight 666ms 100ms both;
    }

    &.close {
      animation: slideOutRight 666ms 100ms both;
    }
  }

  &.left {
    width: $adv-filter-width;
    border-right: 1px solid $white350;

    &.open {
      animation: slideInLeft 666ms 100ms both;
    }

    &.close {
      animation: slideOutLeft 666ms 100ms both;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px $baseline 0 $baseline;
    height: $subnav-height;
    font-weight: bold;
    border-bottom: 1px solid $white450;
    text-transform: uppercase;

    i {
      cursor: pointer;
    }
  }

  .body {
    height: calc(100vh - #{$subnav_height * 2});
    overflow: auto;
  }

  &.actionNavHeight {
    .header {
      height: $actionnav-height;
    }

    .body {
      height: calc(100vh - #{$subnav_height + $actionnav-height});
    }
  }
}

@keyframes slideInRight {
  from {
    margin-right: (-$aside-panel-width);
  }
  to {
    margin-right: 0;
  }
}

@keyframes slideOutRight {
  from {
    margin-right: 0;
  }
  to {
    margin-right: (-$aside-panel-width);
  }
}

@keyframes slideInLeft {
  from {
    margin-left: (-$adv-filter-width);
  }
  to {
    margin-left: 0;
  }
}

@keyframes slideOutLeft {
  from {
    margin-left: 0;
  }
  to {
    margin-left: (-$adv-filter-width);
  }
}
