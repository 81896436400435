@import '../../../scss/base/variables';

.asidePanel {
  width: 35% !important;
  flex-basis: 35% !important;
}

.removeTag {
  margin-right: $baseline;
}
