@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';

.wrapper {
  overflow-y: auto;
  max-height: calc(100vh - 170px - #{$subnav-height * 2});
}

@media print {
  // removes scrollbar from print view
  .wrapper {
    overflow: visible;
  }
}

.employeeInformationGroup {
  margin: $baseline_half 0;
}
