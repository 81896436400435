@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.span {
  font-size: 11px;
  font-weight: bold;
  text-transform: capitalize;
  color: $primary;
  cursor: pointer;
}

.wrapper {
  margin-bottom: 24px;

  h5 {
    margin-top: 24px;
  }
}

.wrapper {
  margin-bottom: 1.5rem;
}
