@import '../../../scss/base/variables';

.container {
  display: flex;
  margin: $baseline 0;
  height: 100%;
}

.chart {
  .line {
    fill: none;
    stroke-width: 1.5px;
    stroke-linejoin: round;
    stroke-linecap: round;
    opacity: 0.25;

    &.selected {
      stroke-width: 3px;
      opacity: 1;
    }
  }

  :global(.domain) {
    fill: none;
    stroke-width: 1px;
    stroke: black;
  }

  .tick {
    line {
      stroke-width: 1px;
      stroke: black;
    }

    text {
      font-size: 0.8em;
    }
  }
}
