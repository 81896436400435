@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';

.header {
  padding: $baseline_sm $baseline_half;
  background-color: $white500;
  color: rgba(32, 20, 79, 0.5);
  margin: $baseline_half $baseline;

  i {
    position: relative;
    top: -2px;
    font-size: $baseline * 0.8;
    margin-right: $baseline_sm;
  }
}
