@import '../../../../scss/base/colors';
@import '../../../../scss/base/variables';

.moreBack {
  border-bottom: 1px solid $white400;
  background-color: $white250;

  span,
  a {
    padding: $baseline-half * 0.7;
    font-size: 0.9rem;
    font-weight: 500;

    .material-icons {
      font-size: 0.9rem;
    }
  }
}
