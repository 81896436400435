@import '../../../scss/base/variables';

.subnav > ul {
  padding: 7px $baseline_half;
}

.dropdown {
  width: 12rem;

  ul {
    padding: 0 !important; // We don't always expect to use dropdowns in this subnav.
  }
}
