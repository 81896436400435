.advanced-filters {
  .subnav {
    left: #{$adv-filter-width};
    width: calc(100% - #{$adv-filter-width});
    transition: left 666ms 100ms, width 666ms 100ms;
  }

  .with-adv-filters {
    .filterbar--adv_ctrls {
      animation: none;
    }
  }

  .education {
    height: $baseline * 6;
    margin: $baseline $baseline 0;
    border-bottom: 1px solid $white400;
    display: none;

    p {
      margin-bottom: $baseline;
      font-size: 0.9rem;

      .material-icons {
        color: $primary;
        font-size: 1rem;
      }
    }
  }

  .view-content {
    flex: 1 1 0;
    height: 100vh;
    padding: 0 $baseline !important;
    overflow-y: scroll;

    .card_holder {
      width: 100%;
      padding-top: $subnav-height * 2;
    }
  }

  .advanced-filter-panel {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    overflow-y: hidden;

    form {
      flex: 1;
      height: calc(100vh - #{$subnav_height * 3});
      padding: $baseline $baseline $baseline $baseline_sm;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &.showMsg {
      .education {
        display: block;
      }

      form {
        /* get exact height of form for scroll - subtract height of nav, header, footer, and msg */
        height: calc(100vh - #{$subnav_height * 3 + $baseline * 6 + 1.5625rem});
      }
    }

    h2 {
      margin-bottom: $baseline_half;
    }

    h4 {
      margin-left: $baseline;
      margin-bottom: calc($baseline_half / 2);

      i {
        margin-top: -2px;
        margin-right: $baseline_half;
        font-size: $h2-font-size;
      }
    }

    section {
      margin-bottom: $baseline;

      .caseDetailFilters > div,
      .timePeriodFilters > div,
      .userFilters > div {
        display: flex;
        margin-bottom: $baseline_half;

        > div {
          display: inline-flex;
          justify-content: flex-start;
          flex-direction: column;
        }

        > li,
        > .dropdownComponent {
          width: 92%;
          display: inline-block;
          margin-left: $baseline_sm;

          > .rw-dropdown-list {
            margin-bottom: 0;
          }

          .expando.date-range {
            margin-top: $baseline_half;
          }
        }

        &.noToggle {
          > div,
          > li {
            margin-left: $baseline;
          }
        }

        label {
          font-size: 0.8rem;
          font-weight: normal;
          color: $black400;
          z-index: 1;
          transition: none;

          strong {
            font-weight: bold;
          }
        }

        .owner,
        .userDepartment {
          @include margin-left(33%, 41%, 93%, 92%);
        }

        .createdBy,
        .userOrganization {
          @include margin-left(35%, 43%, 93%, 92%);
        }

        .category {
          @include margin-left(30%, 38%, 95%, 95%);
        }

        .actionsFilter {
          @include margin-left(43%, 40%, 94%, 94%);
        }

        .resolution {
          @include margin-left(31%, 38%, 93%, 92%);
        }

        .resolvedBy {
          @include margin-left(49%, 57%, 91%, 89%);
        }

        .bundle {
          @include margin-left(38%, 46%, 95%, 95%);
        }

        .userType {
          @include margin-left(18%, 26%, 95%, 95%);
        }

        .userTags {
          @include margin-left(17%, 25%, 95%, 95%);
        }
      }

      .timePeriodFilters > div,
      .caseDetailFilters > div {
        > div {
          justify-content: flex-start;
        }
      }
    }

    ul {
      padding: 0;
      list-style: none;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .actionsFilter {
      .rw-popup-container {
        ul {
          li.rw-list-optgroup:first-child {
            padding-top: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .rw-dropdown-list {
    clear: both;
  }

  .rw-datetime-picker.rw-widget .rw-input {
    padding: $input-inline-label-padding;
  }

  .rw-popup-container.rw-calendar-popup {
    right: -5px;
  }
}

.subnav .advanced-sort {
  display: block;
  margin-left: auto;
}

.advanced-sort__sort-list {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  height: $subnav-height;
  margin-top: 0;
  padding: 0;

  :last-child {
    margin-right: 0;
  }
}

.advanced-sort__sort-list-item {
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  margin-right: $baseline_half;
  padding: 0 calc($baseline_half / 4);
  list-style: none;

  .rw-widget {
    width: $dropdown-min-width * 1.35;
    border: 1.5px solid $primary;
    border-radius: $border-radius * 2;
    background-color: $primary;
    box-sizing: content-box;

    &:hover {
      background-color: $primary;
    }

    .rw-i {
      right: $baseline_sm;
      color: $white;
      font-size: $base-font-size * 1.5;
    }

    .rw-input {
      padding-right: $baseline_sm;
      color: $white;
      font-size: 1rem;
    }
  }
}

.rw-popup-container {
  border-radius: 0;

  .rw-list-option {
    background-color: $white;
  }
}

.rw-datetime-picker.rw-widget .rw-input {
  padding: $input-inline-label-padding;
}
