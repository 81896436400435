// *************************************
//
//   Extendable classes
//
// *************************************

// These are placeholder classes that can be used to group together common
// styles where a mixin doesn't necessarily make sense, but we also don't want
// to extend an existing class (which is bad practice).

// =============================================================================
// Layout
// =============================================================================

// NOTE: I'm not loving that this presumes a certain markup structure, but
// we're pretty consistent in our use of this markup throughout the app right
// now that it's fairly safe.
%page-layout {
  @media screen {
    #routeHandler > div,
    .routeHandler > div {
      display: flex;
      flex-direction: column;
      height: 100vh;
      overflow: hidden;

      main {
        display: flex;
        position: relative;
        flex: 1;
        flex-flow: row wrap;
        width: 100%;
        overflow: hidden;
      }

      .view-content {
        @include component-container;

        &.registry {
          padding-top: 0;
        }

        &.view-content-pandy {
          height: calc(100vh - #{$nav-height * 1.1});
          padding-bottom: 0;
        }
      }

      .view-content__body {
        width: 100%;
        margin: 0 auto;
        padding: $baseline;
      }

      .view-content__body + .view-content__body {
        margin-top: $baseline * -2.5; // close the gap between body-content sections
        padding-top: 0;
      }

      header .button {
        position: relative;
        top: $baseline_half * -0.35;
      }

      header a.icon,
      header .button {
        margin-left: $baseline_half;
      }
    }
  }
}

%fade-in {
  animation: 500ms ease-in faderIn forwards;
  opacity: 0;
}
