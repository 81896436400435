.search-results {
  margin-top: $subnav-height;

  .card,
  .card.patient,
  .card.employee {
    > ul:last-of-type {
      li {
        &.card__dob {
          width: 15%;
        }

        &.card__person-id,
        &.card__action-date {
          width: 25%;
        }
      }
    }
  }
}
