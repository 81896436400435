@import '../../../scss/base/variables';

@keyframes spinning {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.arrowSpinner {
  font-size: 18.5px;
  margin-left: $baseline_sm;
  animation-name: spinning;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
