@import '../../../scss/base/colors';
@import '../../../scss/base/variables';

$row-height: 48px;

.row {
  flex: 1;
  display: flex;
  align-items: center;
  height: $row-height;
  background: $white;

  &.clickable {
    cursor: pointer;
  }

  p {
    font-size: $small;
    margin: 0;
  }
}

.spacer {
  flex: 1;
}

.name {
  min-width: 0;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.countWrapper {
  display: flex;
  justify-content: space-between;

  span {
    padding: 0 $baseline_sm;
  }

  &:last-of-type {
    padding-right: $baseline_half;
  }

  p {
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.issues {
      color: $danger800;
    }

    &.grayed {
      color: $gray400;
    }
  }
}

.circle {
  width: calc($row-height/3);
  height: calc($row-height/3);
  margin: $baseline_half;
  border-radius: 50%;
}

.danger {
  background: $danger800;
}

.warning {
  background: $warning;
}

.success {
  background: $success;
}

.disabled {
  background: $gray400;
}

.table {
  // table style override just the width
  th,
  td {
    width: 50%; // update this if number of columns grow
  }
}
