@import '../../../../scss/base/colors';
@import '../../../../scss/base/variables';

.form {
  display: flex;
  padding: $baseline;
  flex-direction: column;
}

.formActions {
  padding: 0 $baseline;
  display: flex;

  > * {
    width: auto;
    margin-right: $baseline;
  }
}
