.loader-parent {
  .loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($white300, 0.97);
    opacity: 0;
    z-index: index($z_loader, loaderElement);
    animation: faderIn 300ms ease 1000ms both;

    .spinner {
      position: absolute;
      top: 40%;
      left: 50%;
      z-index: 20000000000;

      &:after {
        display: block;
        position: absolute;
        min-width: 300px;
        transform: translateX(-45%) translateY(15px);
        color: $gray400;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        content: 'Just a moment please';
      }
    }
  }

  &.longWait {
    .loader {
      .spinner {
        &:after {
          content: 'Taking too long? Try a shorter date range.';
        }
      }
    }
  }
}
