@import '../../../scss/base/colors';
@import '../../../scss/base/variables';

.table {
  border-spacing: 0;
  font-size: $small;

  th,
  td {
    padding: $data-table-cell-padding;
  }

  thead tr {
    background-color: $white450;
  }

  tbody tr {
    cursor: pointer;

    &:nth-of-type(2n) {
      background-color: $white200;
    }
    &:nth-of-type(2n + 1) {
      background-color: $white;
    }

    &:hover {
      background-color: $white400;
    }

    &.selectedRow {
      background-color: $secondary;

      td,
      i {
        color: $white;
      }
    }
  }
}

.highlightLastRow {
  font-weight: bolder;
}

.scrollable {
  overflow-y: auto;

  th {
    position: sticky;
    top: 0;
  }
}

@media print {
  // Removes scrollbars and dynamically-set max height from tables on print so that all data
  // is rendered.
  .scrollable {
    overflow-y: visible;
    max-height: inherit !important;
  }
}
