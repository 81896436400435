@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';
@import '../../../../scss/base/mixins';

.form {
  padding: $baseline;

  .checkboxWrapper {
    width: 100%;
    column-gap: $baseline_half;
    column-count: 2;
    margin: $baseline 0;

    i {
      margin-top: 0;
    }

    span {
      display: inline-flex;
      width: 100%;
      white-space: break-spaces;
      font-size: 9px;
      font-weight: bold;
    }
  }
}

.buttonDiv {
  input {
    margin: $baseline_sm 0;
  }
}
