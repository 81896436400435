@import '../../../scss/base/variables';

.spinnerWrapper {
  transform: scale(0.5);
  line-height: 0;
  animation: fashionablyLate 1s;
}

@keyframes fashionablyLate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
