@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.container {
  margin: $baseline_half $baseline_sm 0 $baseline_sm;
  padding-left: $baseline_half;
  border-left: 2px solid $white500;

  > li {
    padding-bottom: $baseline_half;

    &:last-child {
      padding-bottom: 0;
    }
  }

  & p {
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 0;
  }
}

.listItem {
  display: flex;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 0;

  em {
    font-weight: 400;
  }
}

.label {
  flex: 0 0 105px;
  opacity: 0.4;
}

.label,
.value,
strong,
em {
  line-height: 1rem;
}
