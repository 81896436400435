@import '../../../scss/base/colors';
@import '../../../scss/base/variables';

// Header styles
.header {
  margin: 0;
  font-size: $base-font-size;
  line-height: inherit;

  span {
    font-weight: lighter;
  }

  .newIcon {
    color: $secondary1000;
    margin-left: $xxSmall;
    font-size: $baseline_lg;
    padding-bottom: $baseline_sm * 0.25;
  }
}

.condensedHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;

  .iconInfo {
    margin: 0 $baseline_sm 0 0;
    font-size: $xSmall;

    i {
      font-size: $h2-font-size;
    }
  }
}

.desc {
  margin: 0;
  color: $gray500;
  font-size: $small;

  span {
    color: $gray300;
  }
}

.incidentHeadline {
  margin: 0;
  font-size: $small;
}

// privacy assessments
.privacyWrapper {
  width: 100%;
}

.reason {
  margin-bottom: $baseline;
  font-size: $small;
  line-height: normal;
}

.reasonHeadline {
  margin-bottom: $baseline;

  a {
    font-weight: 500;
    color: $primary;
    line-height: normal;
    margin-top: $baseline_sm;
  }

  a,
  p {
    display: flex;
    font-size: $small;
  }

  i {
    align-self: center;
    padding-left: $baseline_sm;
    font-size: $small;
  }
}

.reasonFooter {
  margin: 0;
  font-size: $small;

  &:first-child {
    padding-top: $baseline_half;
  }
}

// Statistics
.splitColumn {
  column-gap: $baseline * 2;
  column-count: 2;
}

.incidentWrapper {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: $baseline_half;
  font-size: $small;

  &.link {
    display: flex;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .content {
    margin: 0;
    line-height: initial;
  }

  // description
  .flex {
    color: $gray500;

    strong {
      font-weight: normal;
    }
  }

  span:nth-child(1) {
    margin-right: $baseline_sm;
  }

  // label - this overrides _label.scss
  span:nth-child(2) {
    margin-top: 0;
    margin-bottom: calc($baseline_sm/2);
  }
}

// shared
.label {
  margin: 0;
  color: $gray500;
  font-size: $xSmall;
  line-height: initial;
}

.content {
  margin: 0;
  font-size: $small;
  line-height: initial;
}

.flex {
  display: flex;

  &.flexCol {
    flex-direction: column;
  }
}

@media print {
  .newIcon {
    display: none;
  }
  .reasonHeadline > a {
    color: $black;
  }
}
