@import '../../../scss/base/colors';
@import '../../../scss/base/variables';
@import '../../../scss/base/zindex';
@import '../../../scss/base/utils';

.protA {
  @extend .prot-a;

  @media print {
    color: $black;
  }
}
