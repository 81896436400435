@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.container {
  display: flex;
  flex-direction: column;
  align-items: left;
  max-width: 60%;
  padding-top: $baseline * 3;
  margin: 0 auto;

  .header {
    margin-bottom: $baseline_half;
  }

  .dates {
    display: flex;

    > div {
      position: relative;
      flex: 1;
      background: white;

      &:first-child {
        margin-right: 10px;
      }

      &:last-child {
        margin-left: 10px;
      }
    }
  }

  .radioButtons {
    margin: $baseline_half 0;
    padding-left: 0;

    input + label {
      font-size: $base-font-size;
      display: inline-block;
    }

    input + label:after {
      font-size: 22px;
      line-height: 26px;
      color: $primary;
    }

    input:hover + label:after {
      color: $primary;
    }
  }

  .button {
    width: 200px;
    margin-top: $baseline_half;
  }
}
