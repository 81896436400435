@import '../../../../scss/base/variables';

.dropdownWrapper {
  max-width: 50%;

  :global(.rw-dropdown-list) {
    margin-bottom: $baseline_sm;
  }
}

.label {
  font-size: $h4-font-size;
  margin-bottom: $baseline_sm;
}
