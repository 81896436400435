$module: vips;

.#{$module} {
  @extend %page-layout;

  .subnav {
    span.icon {
      margin-left: $baseline_half;
      color: $primary;
      cursor: pointer;

      :hover {
        color: $primary500;
      }
    }
  }

  #routeHandler > div .view-content.registry {
    height: calc(100vh - #{$subnav-height * 2} - #{$actionnav-height});
    margin-top: calc(#{$subnav-height} + #{$actionnav-height});
    padding-bottom: 0;
    transition: margin-top 666ms 100ms, height 666ms 100ms;
  }

  #routeHandler > div .view-content__body {
    padding-bottom: 0;
  }
}

.vip--view {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .upload-table {
    .public_fixedDataTableRow_even,
    .public_fixedDataTableRow_even:hover {
      background-color: $white200;
      cursor: auto;
    }

    .public_fixedDataTableRow_odd,
    .public_fixedDataTableRow_odd:hover {
      background-color: $white;
      cursor: auto;
    }
  }
}
