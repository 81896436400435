@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.bar {
  display: flex;
  padding: $baseline_sm $baseline;
  background: $white200;
  border-top: 1px solid $navy100;
  border-bottom: 1px solid $navy100;
  overflow: hidden;

  .floatRight {
    display: flex;
    margin-left: auto;
  }
}

.item {
  margin-right: $baseline_half;
  padding: $xxSmall $baseline_half;
  border-radius: $border-radius-md;
  color: $primary;
  border: 1px solid $primary;
  font-size: $base-font-size;
  line-height: inherit;
  display: flex;
  align-items: center;
  height: 36px;

  &.active {
    background: $primary;
    color: $white;
    cursor: default;

    > .newIcon {
      color: $white;
      margin-left: $xxSmall;
      padding-bottom: $baseline_sm * 0.25;
      cursor: default;
    }
  }

  &.disabled {
    pointer-events: none;
    background: $gray400;
    color: $gray;
  }

  .count {
    margin-left: $baseline_sm;
    font-weight: bold;
  }
}

.icon {
  align-self: center;
  margin-right: $baseline_half;
  line-height: inherit;
}

.newIcon {
  color: $secondary1000;
  margin-left: $xxSmall;
  padding-bottom: $baseline_sm * 0.25;
  cursor: default;
}

@media print {
  .bar {
    display: none;
  }
}
