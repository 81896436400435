@import '../../../scss/base/colors';
@import '../../../scss/base/variables';

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $subnav-height;
  padding: 10px 28px 10px 30px;
  border-top: 1px solid $white450;
  z-index: 1;

  .button {
    width: auto;
  }

  .filterText {
    color: $primary;
    font-size: 15px;
    margin-left: -3px;
  }
}
