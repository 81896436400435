@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.bulkVipActions {
  height: 100vh !important;
  margin-top: 0 !important;
}

.contentWrapper {
  margin: 0 $baseline;
  font-size: 14px;

  > div {
    margin-bottom: $baseline_half;
  }
}

.bottomText {
  text-align: center;
  color: $gray400;
  font-size: 14px;
  margin-top: $baseline_half;
}
