$small-filter-input-width: 115px;

.activity_view__options {
  position: relative;
  height: 0;
  padding: 0 $baseline_half;
  background: $white400;
  overflow: hidden;
  z-index: index($z_layout, actionnav);

  > div {
    position: absolute;
    top: $baseline_half;
    right: $baseline_half;
    left: $baseline_half;
  }

  &.active {
    animation: showBannerParent 333ms ease-in-out 0s both;
    border-bottom: 1px solid $white500;
    overflow: visible;

    > div {
      animation: showBanner 333ms ease-in-out 0s both;
    }
  }

  &.inactive {
    animation: hideBannerParent 333ms ease-in-out 0s both;

    > div {
      animation: hideBanner 333ms ease-in-out 0s both;
    }
  }

  .selectMenu {
    .rw-dropdown-list {
      width: calc(234px - #{$baseline});
      float: left;
    }

    > .button {
      border: 1px $white500 solid;
      left: -1px;
      padding-bottom: calc(1px + #{$baseline * 0.25}); // pixel perfect
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      vertical-align: top;
    }
  }

  .button {
    padding: $baseline * 0.25;
    vertical-align: top;
    padding-bottom: 0.5rem;

    i {
      position: static;
      color: $white;
      font-size: 1.25rem;
    }
  }

  .activity_view__filters {
    display: inline-block;
    margin-left: $baseline_sm;

    input[type='text'],
    input[type='number'] {
      display: inline-block;
      background: $white;
    }

    .input-container {
      margin-bottom: 0;
      background: transparent;

      .rw-input {
        padding: 0 0 0 $baseline_sm;
      }
    }

    .activity_view__filters_double {
      display: inline-block;
      height: $baseline * 1.5;
      width: $small-filter-input-width;
    }

    .activity_view__filters_double:first-child {
      margin-right: $baseline_sm;
    }

    .activity_view__filters_single {
      display: inline-block;
      width: calc(#{$small-filter-input-width * 2} + #{$baseline_sm});

      &.filters_height {
        height: $baseline * 1.5;
      }
    }

    .button {
      margin-left: $baseline_sm;
    }
  }
}
