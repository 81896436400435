.modalTitle
  font-size: 1.4375rem
  font-weight: 600
  margin-top: 0
  margin-bottom: 1.2rem

.modalText
  font-size: 1rem
  margin-top: 0
  margin-bottom: 0.53rem
