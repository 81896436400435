@import '../../../scss/base/colors';

.icon {
  position: relative;
  width: 24px;

  .error {
    position: absolute !important;
    top: 14px;
    right: 0;
    width: 10px !important;
    height: 10px;
    border-radius: 50%;
    background: $danger800;
  }
}
