@import '../../../scss/base/colors';
@import '../../../scss/base/variables';

$td-font-size: $xSmall * 0.9;

.barChart {
  $leftOffset: 36px;

  position: relative;

  .horizontalTick {
    position: absolute;
    left: $leftOffset;
    width: calc(100% - #{$leftOffset});
    border-bottom: 1px solid $white450;
    &:first-child {
      border-bottom: 1px solid $white500;
    }
  }

  .averageBar {
    position: absolute;
    left: $leftOffset;
    width: calc(100% - #{$leftOffset});
    height: 3px;
    border-bottom: 3px solid $teal500;
    z-index: 5;
  }

  .tickNumber {
    position: absolute;
    left: -60px;
    bottom: -11px;
    font-size: $td-font-size;
    text-align: right;
    width: 50px;
  }

  .barArea {
    display: flex;
    align-items: flex-end;
    position: absolute;
    top: 0;
    left: $leftOffset + 10px;
    right: 0;
    bottom: 0;
  }

  .barGroup {
    display: flex;
    align-items: baseline;
    position: relative;

    &.diagonalLabel .barGroupLabel {
      max-width: 100px;
      font-size: $td-font-size;
      transform: rotate(-20deg);
      transform-origin: right center;
      right: calc(50% - 3px);
    }
  }

  .barGroupLabel {
    position: absolute;
    right: 50%;
    transform: translate(50%);
    bottom: -24px;
    font-size: $td-font-size;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.pandyWrapper {
  position: relative;
  background: $white;
}
