@import '../../../scss/base/variables';
@import '../../../scss/base/colors';
@import '../../../scss/base/mixins';

.subEmployeeBox {
  list-style: none;
  margin: -$baseline_half 0 $baseline_half 0;
  padding: 0;

  li:last-child {
    border-bottom: none;
  }
}
