// -------------------------------------
//   Colors
// -------------------------------------

// White
$white: #fff;
$white200: #fcfcfd;
$white300: #f4f4f8;
$white400: #e8eaf0;
$white500: #d9dce6;

$white150: rgba($white200, 0.5);
$white250: rgba($white300, 0.5); // for shadows and hovers
$white350: rgba($white400, 0.5); // for shadows and hovers
$white450: rgba($white500, 0.5); // for shadows and hovers

// Black
$black: #000;
$black400: #555555;

// Gray
$gray: #c2c8d0;
$gray150: rgba($gray, 0.5);
$gray200: #b3bbc4;
$gray250: rgba($gray200, 0.5);
$gray300: #aab3bf;
$gray400: #9da7b4;
$gray500: #8290a1; // for shadows and hovers
$gray600: #2d3b4e; // for shadows and hovers
$gray650: rgba($gray600, 0.5);
$gray700: #3b454f;
$gray800: #2d353e;
$gray850: #3b444e;
$gray900: rgba($gray850, 0.5); // dropdown box-shadow

// Dark - Dark Purple
$dark: #321f7d;
$dark150: rgba($dark, 0.5);
$dark200: #2d1c70;
$dark250: rgba($dark200, 0.5);
$dark300: #2b1b6b;
$dark500: #20144f; // for shadows and hovers
$dark550: rgba($dark500, 0.5); // for shadows and hovers
$dark600: #492db8;
$dark700: darken($dark, 11);
$dark800: darken($dark, 17);

// Light - Light Purple
$light: #886af7;
$light200: #c8bef2;
$light250: rgba($light200, 0.5);
$light300: #efeef4;
$light400: #c86dd7;
$light500: #b2a4f9;

// Primary - Teal
$primary: #0096b8;
$primary150: rgba($primary, 0.5);
$primary200: #5e3beb;
$primary500: #245d8a; // for shadows and hovers
$primary550: rgba($primary500, 0.5); // for shadows and hovers
$primary600: #6447f2;
$primary700: #2f2078;

// Secondary - Blue
$secondary: #044a82;
$secondary200: #0a0a0a;
$secondary500: #2a66d4; // for shadows and hovers
$secondary550: rgba($secondary500, 0.5); // for shadows and hovers
$secondary700: #abbaf4;
$secondary750: rgba($secondary700, 0.5);
$secondary800: #7e97ee;
$secondary900: #54b2e3;
$secondary1000: #3d7ef4;

// Success - Green
$success: #26bf5c;
$success100: #dbedd7; // Bluesight
$success300: #79d186; // Bluesight
$success500: #16b434; // Bluesight
$thing: #D8D6E3;

// Warning - Orange
$warning: #ff9c40;
$warning100: #fff8e6;
$warning300: #faeeb3; // Bluesight

// Danger - Red
$danger: #f54949;
$danger300: #f78e86; // Bluesight
$danger500: #f24335; // Bluesight
$danger700: #fbd9d9;
$danger750: rgba($danger700, 0.5);
$danger800: #ef6969;

// Teal
$teal: #74c5d8;
$teal150: #e7f4f8;
$teal350: rgba($teal, 0.4);
$teal500: #69d5e4;

// Navy
$navy100: #dce4f4;
$navy300: #7097bb;
$navy500: #044a82;

// Pink
$pink500: #eb4699;

// Utility D
$utilityD500: #6c7bb1;
