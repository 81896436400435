@import '../../../../scss/base/colors';
@import '../../../../scss/base/variables';

.cancelButton,
.cancelButton:hover {
  width: auto;
  border: 1px solid $primary;
  margin-right: $baseline_half;
  color: $primary;
  background: $white;
}

.registerButton {
  width: auto;
  background: $warning;
}

.registeringButton {
  top: 0 !important;
}

.buttonLink {
  font-size: $xSmall;
}

.actionNav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .title {
    height: auto;
    margin-left: 0;
    padding-left: $baseline * 0.25;
  }
}
