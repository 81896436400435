// -------------------------------------
//   Breadcrumbs
//   -----------
//
//   Styles for breadcrumb navigation elements, typically used in a horizontal
//   layout (like a page subnav)
//
// -------------------------------------

.breadcrumbs__separator {
  color: $gray;
}
