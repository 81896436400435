ul,
ol {
  margin: 0;
  padding: 0 0 0 $baseline;

  > li {
    margin-bottom: $baseline * 0.5;

    > ul {
      margin: $baseline * 0.5 0;
    }
  }

  &.list-unstyled {
    list-style: none;
    margin: 0;
    padding-left: 0;

    > li {
      margin-bottom: $baseline;
    }
  }

  &.list-padded {
    padding: $baseline;
  }

  &.list-inline {
    margin: 0;
    padding-left: 0;

    > li {
      display: inline-block;
      margin-bottom: 0;
      padding-right: $baseline;

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &.list-inline_block {
    margin: 0;
    padding-left: 0;
    display: inline-block;

    > li {
      display: inline-block;
      margin-bottom: 0;
      padding-right: $baseline;

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.fadeIn {
  opacity: 0;
  animation: faderIn 500ms ease-in both;
}
