@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.compositeFieldGroup {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  min-width: 284px;
  color: $dark;
  h3 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .text {
    line-height: 1.125rem;
    font-size: $xSmall;
    margin: 0;
  }
  .fieldName {
    font-weight: 600;
    opacity: 0.6;
    width: 80px;
    min-width: 80px;
    margin-right: 12px;
  }
  .fieldRow {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
  }
  .tieredValueContainer {
    margin-bottom: $baseline_half;
  }
  .fieldCol {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
  }
  .tieredFieldName {
    opacity: 0.5;
    width: 80px;
    min-width: 80px;
    margin-right: 12px;
  }
  .rowOffset {
    // space between each row (separate from line height)
    margin-bottom: $baseline_sm;
  }
  // highligting filtered text
  strong {
    font-weight: 800;
    text-decoration: underline;
  }
}
