@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';

.metadataDetail {
  display: flex;
  padding: $baseline_sm;
}

.metadataDetailText {
  padding-left: $baseline_sm;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
}

.metadataDetailDoubleColumnMode {
  display: flex;

  > .metadataDetailColumn {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  > .metadataDetailColumn:last-of-type {
    border-left: 1px solid #eceaf2;
    padding-left: $baseline * 0.6;
  }
}

.metadataRow {
  display: flex;
  justify-content: space-between;
}

.metadataMultilineTextContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.metadataDetailTextDisabled {
  pointer-events: none;
  opacity: 0.5;
}

.metadataLinkDisabled {
  pointer-events: none;
}

.expiredRevoked {
  color: $danger800 !important;
}
