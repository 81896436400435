// ---------------------
//
// Style overrides for the toast notifications
//
// -----
//
// It's worth noting that these styles make liberal use of the !important
// keyword. This is necessary because many of the styles for the toast
// notifications are done inline, and this is the only way to override them via
// CSS.
//
// ---------------------
$module: 'Toastify__toast';

.#{$module}-container {
  min-width: 33.75rem;
  min-height: 0;
  cursor: auto;

  .#{$module}-body {
    width: 100%;

    .prot-a,
    a {
      color: $white;
      text-decoration: underline;
    }

    .material-icons {
      margin-right: $baseline_half;
      vertical-align: top;
    }

    .spinner {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: $baseline_half;

      .spinner-inner {
        width: 24px;
        height: 24px;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .toastify__close {
    display: block;
    position: absolute;
    top: 50%;
    right: calc($baseline / 4);
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    background: transparent;
    color: $white;
    font-size: 1em;
    font-weight: normal;
    line-height: 0;
    opacity: 0.7;

    i {
      top: -0.1em;
      right: -0.05em;
    }

    .material-icons {
      color: $white;
      font-size: 1.25rem;
    }
  }

  &.#{$module}--top-right,
  &.#{$module}--bottom-right {
    right: $baseline_half;
  }

  &.#{$module}--top-left,
  &.#{$module}--top-right {
    top: 0;
    bottom: auto;

    .#{$module}-content {
      margin-top: $baseline_half;
      margin-bottom: 0;
    }
  }

  &.#{$module}--bottom-left,
  &.#{$module}--bottom-right {
    top: auto;
    bottom: $baseline_half;

    .#{$module}-content {
      margin-top: $baseline_half;
      margin-bottom: 0;
    }
  }

  .#{$module} {
    min-height: 0;
    padding: $baseline_half $baseline calc($baseline / 3) $baseline_half;
    border: 0 none;
    border-radius: $border-radius;
    color: $white;
    font-size: 1rem;
    box-shadow: 0 1px 3px rgba($dark300, 0.5);
  }

  .#{$module}--default {
    background: $gray500;

    .toastify__close {
      color: $white;
    }
  }

  .#{$module}--success {
    background: $success;
  }

  .#{$module}--info {
    background: $secondary;
  }

  .#{$module}--warning {
    background: $warning;
  }

  .#{$module}--error {
    background: $danger;
  }
}

.toast-container {
  display: none !important;
}
