@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.compositeFieldValue {
  min-width: 0;
  display: flex;
  flex-wrap: wrap;
  .compositeFieldValueText {
    line-height: 1.125rem;
    font-size: $xSmall;
    margin: 0;
  }
  .hideMultiPersonLinkText {
    display: none;
    margin: 0;
  }
  .multiPersonLinkViewMore {
    font-size: $xSmall;
    color: $primary;
    cursor: pointer;
  }
  .multiPersonLinkContainer {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.125rem;
  }
  // highligting filtered text
  strong {
    font-weight: 800;
  }
}

.compositeTieredValue {
  .tieredFieldRow {
    display: flex;
    flex-direction: row;
  }

  .padBottom {
    padding-bottom: $baseline_sm;
  }

  .tieredField {
    display: flex;
    border-left: 2px solid #eceaf2;
    padding-left: $baseline_half;
    align-items: flex-start;
    > .tieredFieldValue {
      opacity: 0.5;
      width: 80px;
      min-width: 80px;
      margin-right: 12px;
    }
  }
}

.text {
  line-height: 1.125rem;
  font-size: $xSmall;
  margin: 0;
}

.compositeFieldSource {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  flex-basis: 70px;
  opacity: 0.5;
}

.tooltipOverrides :global(.tippy-content) {
  text-align: left;
}

.sourceText,
.tooltipText {
  white-space: nowrap;
}

.sourceText {
  margin-bottom: 0;
}

.tooltipBold {
  font-weight: 700;
}

.tooltipItalic {
  font-style: italic;
}

.tooltipText {
  margin-bottom: 0;
  font-size: $xSmall;
}

.tooltipText:not(:last-child) {
  margin-bottom: $baseline_sm * 0.5;
}

.tieredFieldName {
  opacity: 0.5;
  width: 80px;
  min-width: 80px;
  margin-right: 12px;
}
