@import '../../../scss/base/variables';
@import '../../../scss/base/colors';
@import '../../../scss/base/zindex';

.export {
  position: absolute;
  right: $baseline_sm;
  width: 200px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  background: $white;
  font-size: $xSmall;
  list-style: none;
  cursor: pointer;
  z-index: index($z_layout, navbar);

  &.collapsed {
    height: 0;
    transition: height 333ms ease-in-out, opacity 1ms ease-in-out 330ms;
    opacity: 0;
    overflow: hidden;

    ul {
      height: 0;
      transition: height 333ms ease-in-out;
    }
  }

  &.expanded {
    height: $subnav-height * 3;
    transition: height 333ms ease-in-out;
    overflow: hidden;

    ul {
      height: 100%;
      transition: height 333ms ease-in-out;
    }
  }

  ul {
    height: 100%;
    padding-left: 0;
    list-style-type: none;
    transition: height 333ms ease-in-out;

    li {
      margin-bottom: 0;

      span {
        display: inline-block;
        width: 100%;
        padding: $baseline_sm 0 $baseline_sm $baseline_half;
        color: inherit;

        &:hover {
          background: $secondary;
          color: $white;
        }
      }
    }
  }
}

.button {
  padding-right: $baseline_sm;
  color: $primary;
  font-size: $small;
  line-height: inherit;
  cursor: pointer;
}

.disabled {
  color: $gray300;

  a:hover {
    background: inherit;
    color: inherit;
  }
}
