.subnav {
  display: flex;
  position: static;
  top: 0;
  left: 0;
  flex: 0 0 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: calc(#{$subnav-height});
  background-color: white;
  border-bottom: 1px solid $white400;

  &.filterbar {
    position: absolute;
    top: 0;
    width: 100%;
    animation: showSysAlert 666ms 100ms both;
    overflow: visible;
    z-index: index($z_layout, subnav);

    .selectMenu {
      position: absolute;
      top: $baseline_half;
      right: $baseline;
    }

    .expando {
      position: absolute;
      top: 55px;
      left: 0;
      width: 100%;
    }
  }

  &.actionbar {
    @include boxShadow($white450);

    position: absolute;
    top: calc(#{$subnav-height});
    flex-basis: auto;
    width: 100%;
    height: calc(#{$actionnav-height});
    animation: showSysAlert 666ms 100ms both;
    background: $white;
    overflow: visible;
    z-index: index($z_layout, actionnav);

    > ul {
      li {
        a,
        .icon {
          height: auto;
          padding: 0;
          color: $primary;
          cursor: pointer;

          &.advFilters--clear {
            position: relative;
            top: 2px;
            left: $baseline_sm;
          }

          &:hover {
            background: none;
            color: inherit;
          }
        }

        &.icon {
          height: auto;
          margin-right: $baseline_half;
          margin-bottom: 0;

          a {
            color: $primary;
          }
        }
      }
    }

    &.secondary {
      position: relative;
      top: 0;

      ul {
        padding-right: $baseline_half;
      }
    }
  }

  > ul {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    margin: 0 calc($baseline_half / 2);
    padding: $baseline_half;
    list-style: none;

    li {
      flex: 0 1 auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: $baseline * 1.45;
      white-space: nowrap;

      a,
      .prot-a {
        display: flex;
        align-items: center;
        height: $baseline * 1.45;
        padding: 0 $baseline * 0.8 0;
        color: $primary;
        font-size: $base-font-size;
        transition: background-color 250ms;

        &:hover {
          background-color: $teal150;
        }

        &[disabled],
        &.disabled {
          opacity: 0.5;

          &,
          i {
            cursor: not-allowed;
          }
        }
      }

      &.float-right {
        a {
          padding: 0 $baseline_half 0 0;
        }
      }

      &.float-right {
        a {
          padding: 0 $baseline_half 0 0;
        }
      }

      &.float-right {
        a {
          padding: 0 $baseline_half 0 0;
        }
      }

      strong {
        margin-left: $baseline_sm;
      }

      h3 {
        display: flex;
        align-items: center;
        height: $baseline * 1.5;
        margin: 0;
        padding: 2px $baseline_half 0;
        border-radius: 2px;
        font-weight: bold;
        text-transform: uppercase;
      }

      &.active {
        a {
          border-radius: $border-radius;
          background-color: $primary;
          color: $white;
        }
      }

      &.icon {
        a {
          background: transparent;
          color: $white;
          box-shadow: none;
        }
      }

      &.pull-right {
        flex: 1 1 auto;

        ul {
          display: flex;
          flex: 1 1 auto;
          justify-content: flex-end;
          margin-top: 0;
          padding: 0 $baseline_half 0 0;

          > li {
            list-style: none;

            &.icon {
              margin-right: $baseline_half;

              a {
                height: auto;
                padding: 0;
              }
            }
          }
        }
      }
    }

    .subnav__spacer {
      flex: 1 1 auto;
    }
  }
}

.basicFilters__owner,
.basicFilters__type {
  display: flex;
  box-sizing: content-box;
  height: $baseline * 1.45;
  background-color: white;
  border: 1px solid $primary;
  border-radius: $border-radius * 2;
  overflow: hidden;

  li {
    float: left;

  }
}

.basicFilters__owner {
  margin-right: $baseline * 0.8;
}

.filterbar--adv-ctrls {
  display: block;
  position: absolute;
  top: 0;
  width: calc(100%);
  margin: 0;
  padding: ($subnav-height + $baseline_half) $baseline $baseline_half;
  border-bottom: 1px solid $white400;
  background: $white450;
  z-index: index($z_layout, filterbar);
  animation: showSysAlert 666ms 100ms both;

  a {
    display: inline;
  }

  .filterbar--bulk {
    $buttonMargin: calc($baseline / -6);

    margin-top: $buttonMargin;
    margin-bottom: $buttonMargin;

    i {
      display: inline-block;
      position: relative;
      top: -2px;
      right: auto;
      margin-left: calc($baseline / 6);
      color: $white;
      font-size: 1rem;
    }
  }
}

.with-adv-filters {
  .filterbar--adv-ctrls {
    left: $adv-filter-width;
    width: calc(100% - #{$adv-filter-width});
  }
}
