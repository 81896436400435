@import '../../../scss/base/variables';

.listing {
  width: 100%;
  // Allow space for the whole screen except for the main nav, subnav and action navs
  height: calc(100vh - ((#{$subnav-height} * 2) + #{$actionnav-height}));
  margin-top: $subnav-height + $actionnav-height;
  padding: $baseline 8.3333% 0 8.3333%; // mirrors case listing layout
  overflow: auto;

  ul {
    padding: 0;
    max-width: 920px;
    margin: 0 auto;

    li {
      list-style: none;
    }
  }
  .loadingPandy {
    position: relative;
    margin-top: calc((#{$subnav-height} * 2));
    top: 50%;
    z-index: 6;
  }
}
