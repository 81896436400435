.pane-grid {
  background: none;
  border: 0;
  flex-basis: auto;
  height: auto;
  left: 0;
  margin: 0;
  margin-bottom: $baseline;
  overflow: visible;
  padding: 0;
  width: 100%;
  border-bottom: 1px solid $white400;

  .pane-grid__row {
    background: none;
    display: flex;
    flex-direction: row;
    height: auto;
    left: 0;
    margin: 0;
    min-height: 0 !important;
    overflow: visible;
    padding: 0;
    width: 100%;
    padding: $baseline;

    &:first-child {
      border-top: 0;
    }

    &.bottom-collapse {
      border-bottom: 0;
      padding-bottom: 0;
    }

    .item {
      flex: 1 0 0;
      margin-right: $baseline_half;

      h4 {
        text-transform: uppercase;
        margin-bottom: $baseline_half * 0.5;
      }
    }
  }
}

form + .pane-grid {
  border-top: 1px solid $white400;
}
