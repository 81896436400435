@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-grow: 1;
  height: calc(100vh - #{$subnav_height});

  .body {
    position: relative;
    flex-grow: 1;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: calc(#{$baseline} + #{$subnav-height});
  }
}

@media print {
  .container {
    height: auto; // Overrides the height calculation, which allow vertical overflow to be naturally visible and render on multiple pages
    color-adjust: exact; // Allows colors to be shown in the charts
    background-color: $white; // White background for IE11
    flex-direction: column; // Adjusts layout so charts are below summary
  }
}
