$module: 'bundles';

.#{$module} {
  @extend %page-layout;

  #routeHandler > div .view-content {
    height: calc(100vh - #{$subnav-height * 2 + $actionnav-height});
    padding-top: $baseline-sm;
    padding-bottom: 0;
  }
}

.bundles--view {
  flex: 1;
}
