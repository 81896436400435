@import '../../../../scss/base/colors';
@import '../../../../scss/base/variables';

.section {
  position: relative;
  flex: 0 0 60%;
  height: 100%;
  clear: both;
  background: #fff;
  overflow: auto;
}

.aside {
  display: flex;
  position: relative;
  flex: 0 0 40%;
  flex-direction: column;
  padding: 0;
}

.pickers {
  width: 100%;
  padding: 0 $baseline * 2.5 0 $baseline * 3.1;

  > div {
    position: relative;
    margin-bottom: 1rem;
  }
}

.buttonDiv {
  display: flex;
  justify-content: space-between;

  .button {
    flex: 1;
    margin: 0 $baseline_half;
  }
}
