@import '../../../../../scss/base/colors';
@import '../../../../../scss/base/variables';

.incidentRect {
  transition: all 250ms;
  cursor: pointer;
  stroke: $dark500;
  fill: $gray;
  opacity: 0.3;

  &:hover {
    fill: $primary600;
    stroke: $primary600;
    opacity: 1;
  }
}

@keyframes scale {
  0% {
    transform: scale(1.67);
  }
  5% {
    transform: scale(0.67);
  }
  50% {
    transform: scale(2);
  }
  60% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.67);
  }
}

.selectDot {
  transform: scale(1.67);
  cursor: pointer;
  fill: $primary;
  animation: scale 777ms cubic-bezier(0.38, -0.25, 0.46, 1.43);
}

.tooltip {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.insideSelectedStat {
  fill: $white;
  stroke: $gray400;
  opacity: 0.7;

  &:hover {
    fill: $white;
    stroke: $white;
  }
}
