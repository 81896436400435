@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.fullWidth {
  width: 100%;
}

.date {
  position: relative;
  margin: $baseline_half 0;
  background: $white;
  font-size: $base-font-size;
}
