@import '../../../scss/base/colors';
@import '../../../scss/base/variables';

.button {
  max-width: fit-content;
  min-width: 222px;
  height: $baseline * 1.4;
  border-radius: $border-radius * 0.7;
  background: $primary;
  color: white;
  cursor: pointer;
  margin-right: $baseline;

  .buttonInner {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 $baseline_half 0 $baseline_sm * 1.2;
    white-space: nowrap;

    p {
      background: inherit;
      padding-left: $baseline_sm;
      padding-right: $baseline_lg;
      font-size: $base-font-size;
      margin: 0;
    }

    i {
      font-size: 17.5px;
      color: inherit;
    }
  }

  :hover {
    color: $white250;
  }
}

.buttonForUserDetailsFilter {
  background: $gray250;
  color: $primary;
  margin: $baseline_sm $baseline;

  :hover {
    color: $primary;
  }
}

.rememberContainer {
  position: absolute;
  bottom: 0;
  background: white;
  min-width: 350px;
  width: 99%;
}

.rememberButton {
  display: flex;
  cursor: pointer;
  border-radius: $border-radius * 0.7;
  background: $primary;
  color: white;
  font-weight: bold;
  margin: $baseline_half;
  padding: $baseline_sm;
  justify-content: center;
  transition: all ease-in-out 150ms;
  min-width: 350px;

  &:hover {
    background: $light;
  }
}

.border {
  display: flex;
  margin: 0 $baseline_half;
  border-top: 0.5px solid $light200;
  opacity: 0.5;
}

.dropdownRow {
  position: absolute;
  min-width: 400px;
  max-width: fit-content;
  display: flex;
  flex-direction: column;
  list-style: none;
  background: white;
  border-radius: $border-radius * 0.7;
  color: black;
  padding: 0;
  z-index: 5;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);

  :global(.rah-static) {
    overflow-y: auto;
    max-height: calc(100vh - #{$subnav-height * 2});
    width: 100%;
  }

  .rememberFiltersOpen > div[aria-hidden='false'] {
    margin-bottom: 65px;
    max-height: calc(100vh - 65px - #{$subnav-height * 2});
  }

  li {
    display: flex;
    margin: 0;
    padding: $baseline_half;

    .thisSystemOnly {
      visibility: hidden;
      color: $light250;

      &:hover {
        color: $light200;
      }
    }

    &:hover {
      .thisSystemOnly {
        visibility: visible;
      }
    }
  }

  .spacer {
    flex: 1;
  }
}

.dropdownRowForUserDetailsFilter {
  margin-left: $baseline;

  :global(.rah-static) {
    overflow-y: auto;
    max-height: calc(100vh - #{$subnav-height * 2});
    width: 100%;
  }
}

.checkboxLabel {
  margin-left: $baseline_sm;
}

.checkboxWrapper {
  padding: $baseline_half 0 $baseline_half $baseline_half;
}

.allSystemsLabel {
  margin-left: $baseline_sm;
  color: $primary700;
  font-weight: 700;
}

// prevents from global styles overrides
.wrapper {
  flex: 0 1 auto;
  width: fit-content;

  ul li {
    flex-direction: row;
    height: inherit;
    justify-content: flex-start;
    border-left: 4px solid #efeef4;
    margin-left: $baseline_sm * 1.5;
  }

  li:last-child {
    padding-bottom: 0;
  }

  ul li a {
    height: inherit;
  }
}
