@import '../../../../scss/base/colors';
@import '../../../../scss/base/variables';

.table {
  i:global(.icon-check) {
    color: $success;
  }

  i:global(.icon-close) {
    color: $danger;
  }
  i:global(.icon-lock) {
    color: $danger;
  }
  i:global(.icon-people) {
    color: $warning;
  }
}

.isActive {
  background-color: $secondary;
  color: $white !important;
}
