// *************************************
//
//   Mixins
//
// *************************************

// ----- Fontface ----- //
// -> A mixin for writing @font-face rules in SASS.
// -> https://gist.github.com/jonathantneal/d0460e5c2d5d7f9bc5e6
// $name - Name of the font
// $path - Path to the font
// $weight - Font-weight
// @style - Font-style
// @exts - Font formats -

// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face(
  $name,
  $path,
  $weight: null,
  $style: null,
  $exts: eot woff2 woff ttf svg
) {
  $src: null;

  $extmods: (
    eot: '?',
    svg: '#' + str-replace($name, ' ', '_'),
  );

  $formats: (
    otf: 'opentype',
    ttf: 'truetype',
  );

  @each $ext in $exts {
    $extmod: if(
      map-has-key($extmods, $ext),
      $ext + map-get($extmods, $ext),
      $ext
    );
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append(
      $src,
      url(quote($path + '.' + $extmod)) format(quote($format)),
      comma
    );
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

// =============================================================================
// MouseHover
// =============================================================================

@mixin mouse-hover(
  $time,
  $timing,
  $delayTime,
  $transform1: null,
  $transform2: null,
  $transform3: null
) {
  transform: $transform1 $transform2 $transform3 translateZ(0);
  transition: all $time $timing $delayTime;
}

// Negative Margins
@mixin neg_margin($amt) {
  margin-left: -$baseline * $amt;
  margin-right: -$baseline * $amt;
}

// Hide Flex
@mixin hideflex() {
  display: none;
  flex: 0.00001 !important;
  padding: 0;
  border: none;
  opacity: 0 !important;
  order: 0 !important;
  overflow: hidden;
}

@mixin component-container() {
  position: relative;
  width: 100%;
  height: calc(100vh - #{$subnav-height});
  padding: $subnav-height 0;
  overflow-y: scroll;
}

// =============================================================================
// Charts
// =============================================================================

@mixin chart-rectangle($opacity: 0.3) {
  stroke-width: 1px;
  opacity: $opacity;
  transform: scale(1);
  transition: all 100ms ease-in;

  &:hover {
    transition: all 100ms ease-in;
  }
}

// =============================================================================
// Misc. Functions
// =============================================================================
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function rem-to-px($remVal, $pxBase: 14.4) {
  $pxSize: strip-unit($remVal) * strip-unit($pxBase);
  @return $pxSize + 0px; // prevent the return value from turning into a string by using math
}

// =============================================================================
// Exclusive Filters
// =============================================================================

@mixin margin-left($inc, $exc, $maxInc, $maxExc) {
  ul.rw-multiselect-taglist {
    li:first-child {
      margin-left: $inc;
      max-width: calc(100% - #{$inc + 2});

      span {
        max-width: $maxInc;
      }
    }
  }

  &.exclusive {
    ul.rw-multiselect-taglist {
      li:first-child {
        margin-left: $exc;
        max-width: calc(100% - #{$exc + 3});

        span {
          max-width: $maxExc;
        }
      }
    }
  }
}

// =============================================================================
// Ellipsis Sources
// =============================================================================

@mixin ellipsis-source($max-width: 50%) {
  @media screen {
    max-width: $max-width;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

// selectively set the visibility of the placeholder
@mixin placeholder-visible($shown: true) {
  @if $shown {
    opacity: 1;
  } @else {
    opacity: 0;
  }
}

// Have to specify each placeholder rule independently of one another or the
// browsers won't recognize them (and thus won't render them properly). This
// mixin makes it easier to define placeholder rules without duplication (at
// least in the code that's written).
// Example:
//   @include placeholder {
//     color: $gray400;
//   }
//
// Note that placeholder doesn't accept all rules (it's a pseudo-element with
// some pretty serious restrictions). See the MDN docs for more information:
// https://developer.mozilla.org/en-US/docs/Web/CSS/::placeholder
@mixin placeholder {
  ::placeholder {
    @content;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    @content;
  }
}
