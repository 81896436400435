.activity_view__body {
  main {
    header {
      display: flex;
      background: $white200;
      padding: $baseline_half;
      height: 62px;
      position: relative;
      border-bottom: 1px solid $white500;

      .button {
        padding: $baseline * 0.25;
        flex-grow: unset;
        i {
          color: white;
          position: static;
          font-size: 1.25rem;
        }
      }

      svg {
        span {
          left: $baseline_half;
          opacity: 0.8;
          padding: 5px 5px 0;
          position: absolute;
          transform-origin: left;
          transform: scale(1);
          transition: all ease-in-out 100ms;

          &:hover {
            opacity: 1;
            transform: scale(1.1);
          }

          &:nth-of-type(2) {
            left: $baseline * 2;
            transform-origin: right;
          }
        }
      }

      form {
        &.form__from-date {
          margin-right: $baseline * 0.6;
        }

        &.form__to-date {
          margin: 0 $baseline_half 0 $baseline;

          .rw-calendar-popup.rw-popup-container {
            right: -5px;
            left: auto;
          }
        }

        .rw-datetime-picker.rw-widget .rw-input {
          padding: 0 0 0 10px;
        }

        ul {
          li {
            margin-right: 7px;
            width: 120px;

            input {
              height: 40px;
            }
          }
        }

        .input-container {
          margin-bottom: 0;
        }
      }
      // form

      form ~ div {
        position: relative;
        flex-grow: 10;
        svg {
          overflow: visible;
          position: absolute;
        }
      }
    }
  }
}
