@import '../../../scss/base/colors';
@import '../../../scss/base/variables';

.wrapper {
  flex: inherit;

  header {
    position: static !important; // when navigated here from user-tags, position property gets overriden
  }
}

.error {
  margin-left: 10px;
  padding: 0 10px;
  border-radius: 50%;
  text-align: center;
  background: $danger800;
  font-size: 12px;
  font-weight: bold;
  color: white;
}
