@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';

.aside {
  display: flex;
  position: relative;
  flex: 1 1 35%;
  flex-direction: column;
  background: $light300;
  border-left: 1px solid $white350;
  overflow-x: hidden;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $baseline;
    height: $subnav-height;
    background-color: darken($white200, 3%);
    font-size: $small;
    border-bottom: 1px solid $white450;
    text-transform: uppercase;
    color: $primary;

    i {
      color: $gray200;
      font-size: $baseline * 0.8;
    }
  }
}
