@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';

.section {
  width: 100%;
  background: $white200;
  padding: $baseline * 0.4;
  border-bottom: 1px solid $white500;
  i {
    color: $primary600;
    padding-right: 0;
  }
}

.spacer {
  flex: 1;
}

.expandText {
  color: $primary600;
  text-transform: uppercase;
  font-size: 14.4px;
  font-weight: 600;
  cursor: pointer;
}

.iconWrapper {
  display: flex;
  flex-wrap: wrap;
  color: $primary600;
  align-items: center;
  i {
    font-size: 1rem;
    margin-right: $baseline_sm * 0.5;
  }
  div {
    border-right: 1px solid $white500;
    padding: 0 $baseline * 0.4;
  }
}

.detailSectionWrapper {
  color: $primary600;
  padding: 0 $xxSmall;
  i {
    font-size: 1.25rem;
    line-height: 1.5625rem;
  }
}
