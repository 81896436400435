.container {
  position: relative;
  flex-grow: 1;
  margin-left: 0;
}

.content {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

.hidden {
  visibility: hidden;
  pointer-events: none;
}

@media print {
  .hidden {
    visibility: visible;
    padding: 0;
  }

  .content {
    position: static;
    overflow: visible;
  }

  .container {
    padding: 0;
    height: 100%;
  }
}
