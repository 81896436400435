@import '../../../../scss/base/variables';

.container {
  height: auto;
  margin-top: $baseline * 1.5;
  padding: 0 $baseline;

  > textarea {
    transition: none;
  }
}

@media print {
  .container {
    padding: 0;
    display: block;
    margin: 0;
  }
}
