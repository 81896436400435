.radio-btn-container label {
  border-radius: 2px;
  display: block;
  float: none;
  font-size: 0.8rem;
  height: 31px;
  line-height: 31px;
  letter-spacing: 0.5px;
  left: auto;
  position: relative;
  text-transform: uppercase;
  top: auto;
  transition: none;
}

.radio-btn-container .btn-group label {
  display: inline-block;
  margin-right: $baseline;
  padding: 0 $baseline;
}

.radio-btn-container .btn-group label.active {
  background: $primary;
  color: $white;
}

.radio-btn-container .btn-group label.active:hover {
  background: $primary500;
  color: $white;
}

.radio-btn-container .btn-group label:hover {
  background: none;
  color: $primary500;
  cursor: pointer;
}

.radio-btn-container .btn-group label:last-of-type {
  margin-right: 0;
}
