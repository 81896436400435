.scrollable {
  height: 100%;
  background: $white300;
  overflow: auto;
}

.axis {
  path {
    display: none;
  }

  line {
    shape-rendering: crispEdges;
    stroke: #000;
  }

  .minor line {
    stroke: #777;
    stroke-dasharray: 2, 2;
  }
}

.chart {
  //
  // Inverted styles
  //
  .chart__container {
    background: $dark800;

    &.gradient {
      background: linear-gradient(to bottom, #24b2d3 0%, #0590b2 100%);
    }
  }

  .axis line {
    stroke: $gray600;
  }

  .x.axis {
    text {
      fill: $black;
    }
  }

  .y.axis {
    .tick {
      fill: $white;
    }
  }

  &.accesses_chart {
    display: block;
    min-height: 100%;
    overflow: hidden;
    z-index: index($z_activity, chart);

    .encounters {
      clip-path: url(#clip);

      rect {
        fill: $secondary !important;
        stroke: $secondary !important;

        &.drawer {
          fill: $primary !important;
          stroke: $primary !important;
        }
      }
    }
  }

  .smart-reference-row:not(.selected):not(.multi-incident-row) {
    rect {
      fill: $teal500;
      opacity: 0.2;
    }
  }

  .rule {
    line {
      stroke: rgba($primary, 0.4);
      stroke-dasharray: 3;
    }
  }

  .row {
    .accesses,
    .metalogs,
    .cases,
    .authorized-accesses {
      clip-path: url(#clip);
    }

    > .clickBox {
      fill: $white300;
      stroke: transparent;
      opacity: 0;
    }

    &.selected {
      > .clickBox {
        fill: $white150;
        stroke: $white400;
        transition: fill 500ms, stroke 500ms;
        opacity: 1;
      }
    }

    &.user {
      g.access,
      g.metalog {
        &.drawer,
        &.drawer:hover {
          circle,
          rect {
            fill: $primary !important;
          }
        }
      }
    }

    text {
      fill: $secondary;
      font-size: $small;

      &.material-icons {
        fill: $primary;

        &.smart-reference:not(.multi-incident) {
          font-size: $base-font-size * 1.5;
          fill: transparent;
          stroke: rgba($teal500, 0.8);
          stroke-width: 2px;
        }

        &.smart-reference.multi-incident {
          font-size: $base-font-size * 1.2;
        }
      }
    }

    g.past-future-access {
      display: none; // hidden by default

      text {
        font-size: $base-font-size;
      }
    }

    g.access,
    g.metalog {
      rect {
        @include chart-rectangle;

        fill: $gray;
        rx: 4px;
        stroke: $dark500;
      }

      circle {
        fill: $dark150;
        transform: translate(1px, 0);
        transition: all 100ms ease-in;
        opacity: 0.8;

        &:hover {
          transform: scale(1.67);
          transition: all 100ms ease-in;
        }
      }

      &.flagged,
      &.flagged.drawer,
      &.flagged:hover,
      &.flagged.drawer:hover {
        circle,
        rect {
          fill: $pink500 !important;
          opacity: 1;
        }

        circle {
          transform: scale(1.67);
        }

        rect {
          stroke: $pink500 !important;
          transition: all 100ms ease-in;
        }
      }

      &.drawer,
      &.drawer:hover {
        circle,
        rect {
          fill: $primary !important;
          opacity: 1;
        }

        circle {
          transform: scale(1.67);
          animation: scale 777ms cubic-bezier(0.38, -0.25, 0.46, 1.43);
        }

        rect {
          stroke: $primary !important;
          transform-origin: center;
          transition: all 100ms ease-in;
        }
      }

      &.aliased {
        rect {
          stroke-dasharray: 2;
          opacity: 0.2;
        }
      }
    }

    g.case {
      path {
        height: 4px;
        stroke-width: 1px;
        fill: rgba($warning, 0.4);
        stroke: rgba($warning, 0.8);
        transition: all 100ms ease-in;
        opacity: 0.8;

        &.violation {
          fill: rgba($danger, 0.4) !important;
          stroke: rgba($danger, 0.8);
        }

        &.not-violation {
          fill: rgba($success, 0.4) !important;
          stroke: rgba($success, 0.8);
        }

        &:hover {
          opacity: 1 !important;
        }
      }
    }
    // g.case

    g.authorized-access {
      rect {
        @include chart-rectangle($opacity: 0.4);

        fill: $success;
        stroke: $success;

        &:hover {
          opacity: 1;
        }
      }
    }
    // g.authorized-access

    // .row:hover
    &:hover {
      cursor: pointer;

      &.even {
        > rect {
          fill: $white350;
          opacity: 0.7;
        }
      }

      &.odd {
        > rect {
          fill: $white350;
          opacity: 0.7;
        }
      }

      text {
        fill: $primary;
      }

      .access,
      .metalog {
        rect {
          &:hover {
            fill: $primary !important;
            stroke: $primary !important;
            transition: all 100ms ease-in;
            opacity: 1 !important;
          }

          &.drawer:hover {
            fill: $secondary !important;
            stroke: $secondary !important;
            transition: all 100ms ease-in;
          }
        }
      }

      circle {
        &:hover {
          fill: $primary !important;
          opacity: 1 !important;
        }

        &.drawer:hover {
          fill: $secondary !important;
        }
      }
    }

    &.selected g.past-future-access {
      &.past-future-access--visible {
        display: block;
      }

      text {
        fill: $dark;
      }
    }
  }
}

// X Axis
.x_axis_chart {
  overflow: visible;

  .vector_icons {
    .icon_wrapper {
      transition: r ease-in-out 100ms;
      stroke-width: 1px;
      stroke: transparent;

      path {
        shape-rendering: auto;
      }

      &.drawer {
        fill: $warning !important;
      }
    }
  }
}

.x_axis_element {
  position: relative;
  height: 50px;
  z-index: 1;
}

.x_axis {
  width: 100%;
  height: 50px;
  padding-top: 10px;
  border-top: 1px solid $white400;
  border-bottom: 1px solid $white400;
  background: $white200;

  path,
  rect,
  line,
  text,
  .tick,
  .brush .selection {
    shape-rendering: crispEdges;
  }

  text {
    stroke: none;
    font-size: $xSmall;
  }

  g.tick {
    text {
      text-anchor: end !important;
    }
  }

  line {
    stroke: $gray400 !important;
  }

  .domain {
    stroke: $gray400;
  }
}

.brush_chart {
  height: 40px;
  padding-top: 0;
  background: transparent;

  path,
  rect,
  text,
  .brush .selection {
    shape-rendering: crispEdges;
  }

  circle,
  .material-icons {
    cursor: pointer;
  }

  text {
    stroke: none;
    font-size: 62%;
  }

  line {
    stroke: $gray400;
  }

  .brush {
    transform: translateY(3px);

    rect {
      fill: $white450;
    }

    .overlay,
    .selection {
      height: 10px;
    }

    // Hide the default handle
    rect.handle {
      display: none;
    }

    rect.handle--custom {
      shape-rendering: auto !important;
      fill-opacity: 1;
      fill: $white;
      stroke: $navy300;
      cursor: ew-resize;

      .active &.handle--w {
        stroke: rgba($secondary, 0.5);
      }

      .active &.handle--e {
        stroke: rgba($primary, 0.5);
      }
    }
  }

  .selection {
    height: 10px;
    shape-rendering: auto !important;
    fill: $white !important;
    fill-opacity: 1;
    stroke: $navy100;
    stroke-width: 1px;
  }

  &.active {
    .selection {
      fill: url(#gradientBar) !important;
      stroke: transparent;
    }
  }
}
// .x_axis

// Donut Charts

.chart__donut-segment {
  margin: 3px;
  cursor: pointer;
}

.chart__donut-segment,
.data li {
  transition: opacity 100ms ease-in-out;
}

.chart__donut-total-value {
  fill: $white;
  text-anchor: middle;
  font-size: 4rem;

  .chart__donut--with-label & {
    font-size: 2.5rem;
  }
}

.chart__donut-total-title {
  fill: $white;
  text-anchor: middle;
}

.chart__donut-total-label {
  fill: $white;
  text-anchor: middle;
  font-size: 0.8rem;
}

// Stupid D3 thing
.domain {
  fill: transparent;
}

.activity_pandy {
  z-index: index($z_activity, pandy);
}
