@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

$border: 1px solid $white400;

.main {
  display: flex;
  width: 100%;
  overflow-y: hidden;
  height: 100vh;
  min-width: 100vw;
}

.wrapper {
  flex: 1 1;
  padding: 0 $baseline;
  overflow-y: auto;

  &.tableLayout {
    display: flex;
    flex-direction: column;
  }

  h1 {
    font-size: 16px;
    font-weight: bolder;
    margin: 0;
  }

  .groups > div {
    border-bottom: $border;

    &:last-of-type {
      border-bottom: none;
    }
  }
}
