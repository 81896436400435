.mfa_view {
  min-width: 100%;
  background-attachment: fixed;
  background-image: url(/assets/img/circles.svg); // fallback
  background-image: url(/assets/img/circles.svg),
    linear-gradient(
      134.72deg,
      $secondary1000 0%,
      $primary600 35.81%,
      $light400 100%
    );
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;

  .navbar {
    visibility: hidden;
  }

  .mfa-buttons {
    display: flex;
    justify-content: space-between;
    height: $baseline * 1.6;
    margin-top: $baseline * 1.1;
    box-sizing: border-box;

    input {
      width: 48%;
      border: 2px solid $primary;
      text-transform: none;
      box-sizing: border-box;

      &.button-back {
        background: $white;
        color: $primary;
      }
    }
  }
}

.qrCode {
  align-self: center;
  margin-bottom: 0;
}

div.intro {
  margin: $baseline * 1.5 0;
}

.mfa-forced {
  small {
    text-align: center;
  }
}

.mfa-links {
  margin-top: $baseline;
  margin-right: -$baseline * 4;
  margin-left: -$baseline * 3.5;
}

footer {
  text-align: center;
}
