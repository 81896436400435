@import '../../../scss/base/variables';

.aside,
.form {
  width: $adv-filter-width;
  // IE bug workaround.
  flex: 0 0 $adv-filter-width;
}

.horizontalRule {
  border-bottom: 3px solid #eae8f0;
  width: 100%;
  display: block;
  margin-bottom: $baseline;
}

.form {
  padding: $baseline $baseline $baseline $baseline_sm;
  height: calc(100vh - #{$subnav-height * 2} - #{$actionnav-height});
  overflow: auto;

  :global(.caseDetailFilters),
  .reportFilters {
    display: flex;
    flex-direction: column;
  }

  :global(.expando.date-range) {
    > div {
      &:last-child {
        :global(.rw-popup.rw-calendar.rw-widget-container.rw-widget) {
          left: -6.2rem;
        }
      }
    }
  }

  :global(.input-container) {
    margin-top: $baseline_half;
  }
}
