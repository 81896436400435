@import '../../../scss/base/variables';

.flexHeader {
  display: flex;
}

.clear {
  clear: both;
}

.syncNowLink {
  float: right !important; //override prot-a class to right-align it
  margin-left: $baseline_half;
}
