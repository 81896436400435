@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.viaSource {
  display: block;
  color: $secondary;
  font-size: $h5-font-size;

  &,
  & * {
    margin-top: 3px;
    line-height: normal;
  }
}
