@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.caseEvent {
  &:nth-of-type(n + 2) {
    padding-top: $baseline * 0.6;
  }

  &.origin > div {
    padding-top: $baseline_half;
  }

  &:not(.pending) > div::before {
    top: $baseline * 1.3;
  }

  &.not-violation,
  &.violation {
    border-color: transparent !important;

    > div {
      &::before {
        top: $baseline * 0.75;
      }

      // Use the ::after pseudo element to extend the border down beyond the resolution dot.
      &::after {
        display: block;
        position: absolute;
        top: $baseline * 1.8;
        bottom: 0;
        left: -2px;
        width: 0;
        border-left: 2px solid $light300;
        content: ' ';
      }
    }
  }

  &.origin > div {
    &::before {
      top: $baseline * 1.2;
    }

    // Use the ::after pseudo element to extend the border down to the origin dot.
    // Otherwise there would be a blank space above it because we don't show the
    // border to the left of the origin event.
    &::after {
      display: block;
      position: absolute;
      top: -$baseline;
      left: 0;
      width: 0;
      height: $baseline * 2.2;
      border-left: 2px solid $light300;
      content: ' ';
    }
  }
}

.fileListing {
  margin-bottom: $baseline_half;
  padding: 0;

  li {
    margin-left: -18px;
    margin-right: -18px;
    padding: $baseline_sm $baseline_half;
    background: $white300;
    list-style: none;

    &:first-of-type {
      margin-top: -18px;
    }

    &:before {
      margin-right: $baseline_half;
      font-family: 'Material Icons';
      content: '\e226';
      vertical-align: bottom;
    }
  }
}

@media print {
  .origin > div {
    &::after {
      display: none !important;
    }
  }
  .caseEvent {
    padding-top: $baseline * 0.6;
  }
}
