@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.link {
  align-self: center;
  padding-right: $baseline_sm;
  color: $primary;
  font-size: $xSmall;
  line-height: inherit;
  cursor: pointer;

  .default {
    transform: rotate(90deg);
  }

  .rotate270 {
    transform: rotate(270deg);
  }
}

@media print {
  .link {
    display: none;
  }
}
