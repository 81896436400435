@import '../../../scss/base/variables';

.header {
  margin: 0 $baseline $baseline_sm;
}

.icon {
  margin: -2px $baseline_half 0 0;
  font-size: $h2-font-size;
}
