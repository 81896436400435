$module: authorizations;

.#{$module} {
  @extend %page-layout;

  .subnav {
    span.icon {
      margin-left: $baseline_half;
      color: $primary;
      cursor: pointer;

      :hover {
        color: $primary500;
      }
    }
  }

  #routeHandler > div .view-content.registry {
    height: calc(100vh - #{$subnav-height * 2} - #{$actionnav-height});
    margin-top: calc(#{$subnav-height} + #{$actionnav-height});
    padding-bottom: 0;
    transition: margin-top 666ms 100ms, height 666ms 100ms;
  }

  #routeHandler > div .view-content__body {
    padding-bottom: 0;
  }

  .rw-datetime-picker {
    width: 100%;

    .rw-widget-input.rw-input {
      padding: $input-inline-label-padding;

      &[disabled] {
        border: none;
        background: $white200;
        color: $gray500 !important;
      }
    }
  }
}
