@import '../../../scss/base/variables';

.withoutExclusion {
  margin-bottom: $baseline_half;

  > div {
    width: 92%;
    margin-left: $baseline !important; // Needed while we still have .advanced-filters global styling.
  }

  :global(.input-container) {
    margin-bottom: 0;
  }
}
