@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';

.doubleLayout {
  display: flex;
  width: 100%;
}

.patientBox {
  flex: 1;
  height: calc(100vh - #{$subnav-height * 2});
  border: 1px solid $white500;
}

.employeeBox {
  flex: 1;
  height: calc(100vh - #{$subnav-height * 2});
  border: 1px solid $white500;
}

.activityChartBox {
  width: 50vw;
  height: 100vh;
  border: 1px solid $white500;
}

.column {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  background-color: $white;
}

.doubleColumn {
  display: flex;
  flex-direction: row;
  .column {
    border-right: 1px solid $white500;
    border-collapse: collapse;
    &:nth-child(2) {
      border-right: none;
      background-color: $white200;
    }
  }
}
