@import '../../../scss/base/variables';

.wholeLayout {
  display: flex;
  width: 100%;
  padding-top: $subnav-height;
}

.resultsLayout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  // Allow space for the whole screen except for the main nav, search type nav.
  max-height: calc(100vh - #{$subnav-height * 2});

  > * {
    // Work around assumptions made elsewhere about absolute positioning for
    // subnavs and such.
    position: static !important;
    overflow: auto;
  }
}

.listWrapper {
  margin-top: $baseline_half;
  transition: margin 666ms 100ms, max-width 666ms 100ms;

  &.skinnier {
    margin-left: $baseline_half;
    margin-right: $baseline_half;
    max-width: 100%;
  }
}
