.card {
  @include boxShadow($white450);

  margin-bottom: $baseline;
  padding: $baseline;
  background: $white;

  .card-header {
    @extend %group; // clearfix

    h2 {
      max-width: 96.5% !important;
      overflow: visible;

      span {
        display: inline-block;
        max-width: 100% !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        > span {
          display: inline;
        }
      }

      .material-icons {
        display: inline-block;
        margin-left: calc($baseline_half/2);

        &.icon-bundle:before {
          top: 10.5%;
          left: 22%;
        }
      }
    }

    .source {
      vertical-align: middle;
    }
  }

  // Stacked card style
  &.bundle {
    position: relative;
    margin-top: $baseline;

    &,
    &:before,
    &:after {
      border-top: 1px solid $white450;
    }

    &:before,
    &:after {
      @include boxShadow($white450);

      display: block;
      position: absolute;
      height: 30px;
      background: $white;
      content: ' ';
      z-index: -10;
    }

    &:before {
      top: -11px;
      right: 10px;
      left: 10px;
    }

    &:after {
      top: -6px;
      right: 5px;
      left: 5px;
    }
  }
}

.card_holder {
  // the percentage method insists on multiplying the provided value by 100, so
  // divide it again to bring it back down to the proper dimension
  $col-width: percentage(calc(calc(80 / 5) / 100));

  margin: 0 auto;
  padding: 0;
  list-style: none;

  &.adv_filters {
    padding-top: $baseline * 4.5;
  }

  &.margin_collapse {
    padding-top: 0;
  }

  li {
    position: relative;
    z-index: 1;
  }

  .card {
    position: relative;
    width: 100%;
    margin-bottom: $baseline_half;

    .card__subtitle {
      font-size: $h5-font-size;
    }

    > ul:last-of-type {
      display: flex;
      margin: -$baseline;
      margin-top: 0;
      padding: $baseline_half $baseline;
      border-top: 1px solid $white300;
      background: $white200;
      list-style: none;

      li {
        display: block;
        margin: 0;
        padding: 0;
        background: transparent;
      }
    }

    p {
      margin-bottom: 0;
      text-overflow: ellipsis;
      overflow: hidden;

      &.card__meta-item__type {
        width: calc(100% - 8px);
        white-space: nowrap;
      }
    }
  }

  .card.case {
    padding-top: $baseline_half;

    .card-header h2 {
      margin-bottom: $baseline_half;
    }

    &.omitDetails {
      padding-bottom: 0;
    }

    > ul:last-of-type {
      li {
        width: 40%;
      }

      .card__comment {
        width: 100%;
        white-space: nowrap;
      }
    }

    ul.omitDetails {
      display: none;
    }
  }

  .card.bundle,
  .card.auth {
    > ul:last-of-type {
      li {
        width: 40%;
      }
    }
  }

  //
  // List View: Data Table
  //

  .#{$module}__table {
    overflow: hidden;

    .#{$module}__table-header {
      .material-icons {
        position: relative;
        top: -1px;
        left: 0.5em;
        font-size: 1.1rem;
      }
    }

    .fade-in {
      @extend %fade-in;
    }

    .no-wrap {
      overflow: hidden;

      div {
        display: block;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .public_fixedDataTableRow_main {
    background-color: $white;
  }

  .public_fixedDataTable_header,
  .public_fixedDataTable_header .public_fixedDataTableCell_main {
    background-color: $white350;

    .#{$module}__table-header {
      width: 100%;
    }

    .#{$module}__table-header--active {
      background-color: $white500;
    }
  }

  .public_fixedDataTableRow_even {
    background-color: $white200;
  }

  .pandy-container {
    position: relative;
    height: calc($contained-list-height / 1.5);
  }
}
