@import '../../../../../scss/base/variables';
@import '../../../../../scss/base/colors';

.bottomSpacing {
  margin-bottom: $baseline_sm;
}

.dropdown {
  margin-top: $baseline;
  width: 50%;
  p {
    margin-bottom: $baseline * 0.25;
  }
}
