@import '../../../../scss/base/mixins';
@import '../../../../scss/base/colors';
@import '../../../../scss/base/variables';

.container {
  display: flex;
  flex-direction: column;
  flex: 1 !important;
  order: 0;
  background: white;
  border-right: 1px solid $white400;

  &.maxWidthHalf {
    max-width: 50%;
  }

  &.orderLast {
    order: 2;
  }

  &.fixedWidth {
    flex: 0 0 310px !important;
    width: 310px;
    border-left: 1px solid $white400;
  }

  @media print {
    border: none;
    display: block;
    width: calc(50% - #{$baseline});

    &:nth-of-type(1) {
      float: left;
    }
    &:nth-of-type(2) {
      float: right;
    }
  }
}
