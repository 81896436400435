@import '../../../scss/base/colors';
@import '../../../scss/base/mixins';
@import '../../../scss/base/variables';

.form {
  // set to full height of aside body minus padding to prevent datepicker cutoff when scrolling
  height: calc(100vh - #{$subnav-height * 2} - #{$baseline * 2});
  padding: $baseline;
  overflow: auto;

  h3 {
    display: flex;
    align-items: center;
  }

  i {
    margin-right: $baseline_half;
    font-size: $h2-font-size;
  }

  input {
    margin-bottom: $baseline_half;
    padding: $input-small-form-padding;
    font-size: $small;
    font-family: $primaryFont;
    background: $white;
    border: 1px solid $white500;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: none;

    &[disabled] input {
      cursor: not-allowed;
      background: transparent;
    }
  }

  // Styles to override react-widgets date picker.
  :global(.rw-input) {
    padding: $input-small-form-padding !important;
    background: $white !important;
  }
  fieldset[disabled] :global(.rw-input) {
    background: transparent !important;
  }
  :global(.rw-i-calendar) {
    line-height: 1;
  }
}

.inlineInputs {
  display: flex;

  input {
    width: 0;
  }

  input:nth-of-type(n + 2) {
    margin-left: $baseline_half;
  }
}

.cityInput {
  flex-grow: 2;
}

.stateInput,
.zipInput {
  flex-grow: 1;
}
